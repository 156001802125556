import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
/* Ant Design */
import { Select, Spin, ConfigProvider } from "antd";
/* Custom hooks */
import { useCancellablePromise } from "hooks";
/* Styles */
if (typeof window === "object") {
  // @ts-ignore
  require("./style.scss");
}

/**
 *
 * @param {{
 *   style?: React.CSSProperties;
 *   value?: any;
 *   onSelect?: (value: any) => void;
 *   dataKey: string;
 *   getRequest: (query: string) => Promise | import("axios").AxiosPromise;
 *   configProvider?: Object
 *   selectProps?: import("antd/lib/select").SelectProps;
 *   showDropDownRender?: boolean;
 *   iconHidden?: boolean;
 *   optionalOptions?: any[];
 *   deps?: any[];
 *   disabled?: boolean;
 * }} props
 */
function SearchSelect({
  onSelect,
  dataKey,
  getRequest,
  configProvider,
  selectProps,
  showDropDownRender = true,
  iconHidden = false,
  value,
  optionalOptions,
  style,
  deps = [],
  disabled,
}) {
  const authenticated = useSelector((state) => state.user.authenticated);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [showDropDownContent, setPresentDropDownContent] = useState(true);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  const timeOutForSearchRef = useRef(null);

  useEffect(() => {
    if (!authenticated) return;

    if (optionalOptions && Array.isArray(optionalOptions)) {
      setOptions(optionalOptions);
      setPresentDropDownContent(true);
    } else {
      handleSearch("");
    }
  }, [authenticated, ...deps]);

  useEffect(() => {
    showDropDownRender && setPresentDropDownContent(false);
  }, [showDropDownRender]);

  return (
    <ConfigProvider {...configProvider}>
      <Select
        disabled={disabled}
        showSearch
        style={style}
        value={value}
        autoClearSearchValue={false}
        loading={loading}
        optionFilterProp="children"
        defaultActiveFirstOption={false}
        suffixIcon={iconHidden ? <i /> : <i className="fas fa-caret-down" />}
        dropdownRender={(menuNode) =>
          loading ? (
            <div className="search-select-spin">
              <Spin size="small" />
            </div>
          ) : (
            (showDropDownContent && menuNode) || <div />
          )
        }
        {...selectProps}
        onSearch={handleSearch}
        onSelect={(value, optionNode) =>
          typeof onSelect == "function" && onSelect(optionNode.props.data)
        }
      >
        {options.map((data) => (
          <Select.Option key={data.uuid} data={data}>
            {data[dataKey]}
          </Select.Option>
        ))}
      </Select>
    </ConfigProvider>
  );

  /**
   * Adds a timeout to the query request
   * @param {string} query
   */
  function handleSearch(query) {
    setQuery(query);
    if (!loading) setLoading(true);
    if (options.length > 0) setOptions([]);
    const timeOut = timeOutForSearchRef.current;

    if (timeOut) {
      cancelPromises();
      clearTimeout(timeOut);
    }

    timeOutForSearchRef.current = setTimeout(() => {
      timeOutForSearchRef.current = null;
      handleRequest(query);
    }, 750);
  }

  /**
   * Handles the request when the search input changes
   * @param {String} query
   */
  function handleRequest(query) {
    cancellablePromise(getRequest(query))
      .then((response) => {
        setLoading(false);
        setOptions(response.data.results);
        setPresentDropDownContent(true);
      })
      .catch((err) => {
        if (!err.isCanceled) {
          setLoading(false);
        }
      });
  }
}

export default SearchSelect;
