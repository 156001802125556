import axios from "axios";
import { serverConfig } from "../config";
import { getFiltersString } from "utils/helpers";

class SchedulerService {
  constructor() {
    this.http = axios.create({
      baseURL: `${serverConfig.apiUrl}/api`,
    });
  }

  /**
   * Gets the employees in a range
   * @param {string} token
   * @param {string} from start date on format DD-MM-YYYY
   * @param {string} to end date on format DD-MM-YYYY
   * @param {{
   *  page?: number;
   *  size?: number;
   *  sortBy?: string;
   *  filters?: {
   *    [key: string]: { key: string; prefix?: string; suffix?: string; value: string };
   *  }
   * }} options
   */
  getEmployeesBetween(token, from, to, { filters, page, size, sortBy } = {}) {
    const queryFilters = getFiltersString(filters);

    return this.http
      .get(
        `/users/scheduler/?sort=${sortBy ||
          "-contract_hours_difference"}&from_date=${from}&to_date=${to}${
          page ? `&page=${page}` : ""
        }${size ? `&page_size=${size}` : ""}${queryFilters}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => response.data);
  }
}

export default new SchedulerService();
