import axios from "axios";
import { correctResults, getFiltersString } from "utils/helpers";
import { serverConfig } from "../config";
import moment from "moment";

const apiUrl = serverConfig.apiUrl;

const employeeService = {
  getProfile: (token, uuid) => {
    return axios.get(`${apiUrl}/api/users/profile/${uuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getBasicProfile: (token, uuid) => {
    return axios.get(`${apiUrl}/api/users/employees/basic/${uuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getPersonalProfile: (token, uuid) => {
    return axios.get(`${apiUrl}/api/users/employees/personal/${uuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  /**
   *
   * @param {string} token
   * @param {string} tenantuuid
   * @param {string} key
   * @param {number} limit
   * @param {number} page
   * @param {string|object} filtersString
   * @param {string} isArchived
   * @param {string} sortType
   * @param {string} isExport Accept only "True" and "False"
   * @param {string} columns Eg field1,field2, ... field[n]
   * @param {string} columnHeaders Eg name1,name2, ... name[n]
   */
  getEmployees: (
    token,
    tenantUuid,
    key,
    limit,
    page,
    filtersString,
    isArchived = "False",
    sortType = "sort",
    isExport = "",
    columnsStr = "",
    columnsHeaderStr = ""
  ) => {
    let headers = { headers: { Authorization: `JWT ${token}` } };
    if (isExport && (isExport == "True" || isExport == "False"))
      headers.responseType = "blob";

    return axios.get(
      `${apiUrl}/api/users/employees/${tenantUuid}/?${key ? `${sortType}=${key}&` : ""
      }is_archived=${isArchived}&is_reg_complete=True&user_extra_info_user__is_verified=True${limit ? `&page_size=${limit}` : ""
      }${page ? `&page=${page}` : ""}${filtersString
        ? typeof filtersString === "object"
          ? getFiltersString(filtersString)
          : filtersString
        : ""
      }` +
      `${isExport ? `&is_excel_export=${isExport}` : ""}` +
      `${columnsStr ? `&excel_properties=${columnsStr}` : ""}` +
      `${columnsHeaderStr ? `&excel_column_headers=${columnsHeaderStr}` : ""
      }`,
      headers
    );
  },
  /**
   *
   * @param {string} token
   * @param {string} tenantuuid
   * @param {string} key
   * @param {number} limit
   * @param {number} page
   * @param {string|object} filtersString
   * @param {string} isArchived
   * @param {string} sortType
   * @param {string} isExport Accept only "True" and "False"
   * @param {string} columns Eg field1,field2, ... field[n]
   * @param {string} columnHeaders Eg name1,name2, ... name[n]
   */
  getAmbassadors: (
    token,
    tenantUuid,
    key,
    limit,
    page,
    filtersString,
    isArchived = "False",
    sortType = "sort",
    isExport = "",
    columnsStr = "",
    columnsHeaderStr = ""
  ) => {
    let headers = { headers: { Authorization: `JWT ${token}` } };
    if (isExport && (isExport == "True" || isExport == "False"))
      headers.responseType = "blob";

    return axios.get(
      `${apiUrl}/api/users/ambassadors/${tenantUuid}/?${key ? `${sortType}=${key}&` : ""
      }is_archived=${isArchived}&is_reg_complete=True&user_extra_info_user__is_verified=True${limit ? `&page_size=${limit}` : ""
      }${page ? `&page=${page}` : ""}${filtersString
        ? typeof filtersString === "object"
          ? getFiltersString(filtersString)
          : filtersString
        : ""
      }` +
      `${isExport ? `&is_excel_export=${isExport}` : ""}` +
      `${columnsStr ? `&excel_properties=${columnsStr}` : ""}` +
      `${columnsHeaderStr ? `&excel_column_headers=${columnsHeaderStr}` : ""
      }`,
      headers
    );
  },
  exportAllEmployees: (
    token,
    key,
    filtersString = {},
    isArchive = "False",
    sortType = "sort",
    payload,
    exportType = "employees"
  ) => {
    filtersString =
      typeof filtersString === "object"
        ? getFiltersString(filtersString)
        : filtersString;

    return axios.post(
      `${apiUrl}/api/users/employees/export/?${key ? `${sortType}=${key}` : ""
      }&is_archived=${isArchive}&is_reg_complete=${exportType === "employees" ? "True" : "False"
      }${filtersString ? filtersString : ""}`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getAllRegistration: (
    token,
    tenantUuid,
    page = 1,
    size = 1,
    filtersString
  ) => {
    return axios.get(
      `${apiUrl}/api/users/ambassadors/${tenantUuid}/?is_archived=False&page=${page}&page_size=${size}${filtersString ? filtersString : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createEmployee: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/users/${tenantUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  createEmployeeList: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/users/list/${tenantUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  /*   updateEmployee: (token, employeeUuid, payload) => {
    return axios.put(`${apiUrl}/api/users/profile/${employeeUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` }
    });
  }, */
  verifiedEmployee: (token, employeeUuid, regComplete = true) => {
    return axios
      .put(
        `${apiUrl}/api/users/profile/${employeeUuid}/is_verified/`,
        {},
        { headers: { Authorization: `JWT ${token}` } }
      )
      .then((response) => {
        if (regComplete) {
          return axios.put(
            `${apiUrl}/api/users/profile/${employeeUuid}/is_reg_complete/`,
            {},
            { headers: { Authorization: `JWT ${token}` } }
          );
        }

        return response;
      });
  },
  setArchiveStatusEmployees: (token, data, action) => {
    return Promise.all(
      data.map((item) => {
        return axios.put(
          `${apiUrl}/api/users/profile/${item.uuid}/`,
          {
            is_archived: action === "archive" ? "True" : "False",
          },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );
      })
    ).then((results) => correctResults(results));
  },
  getApplicants: (
    token,
    tenantUuid,
    key,
    limit = 25,
    page = 1,
    filtersString,
    isArchive = "False",
    accepted = "True",
    sortType = "sort"
  ) => {
    return axios.get(
      `${apiUrl}/api/users/employees/${tenantUuid}/?${sortType}=${key}&is_archived=${isArchive}&is_reg_complete=False&user_extra_info_user__is_verified=${accepted}&page_size=${limit}&page=${page}${filtersString
        ? typeof filtersString === "object"
          ? getFiltersString(filtersString)
          : filtersString
        : ""
      }`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  setArchiveStatusApplicants: (token, data, action) => {
    return Promise.all(
      data.map((item) => {
        return axios.put(
          `${apiUrl}/api/users/profile/${item.uuid}/`,
          {
            is_archived: action === "archive" ? "True" : "False",
          },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );
      })
    ).then((results) => correctResults(results));
  },
  getMediaEmployee: (token, employeeUuid, page = 1, size = 5, filters = {}) => {
    const filtersString =
      typeof filters === "object" ? getFiltersString(filters) : filters;

    return axios.get(
      `${apiUrl}/api/jobs/user-application/${employeeUuid}/?page=${page}&page_size=${size}${filtersString}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  downloadAllMedia: (
    token,
    tenantUuid,
    employeeUuid,
    filters = {},
    payload
  ) => {
    const filtersString =
      typeof filters === "object" ? getFiltersString(filters) : filters;

    return axios.post(
      `${apiUrl}/api/jobs/images/download/${tenantUuid}/` +
      `?employee__uuid=${employeeUuid}${filtersString}`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getContracts: (token, employee_uuid) => {
    return axios.get(
      `${apiUrl}/api/contracts/employee-contracts/?sort=end_date&employee__uuid=${employee_uuid}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  setContract: (token, employeeContractUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/contracts/employee-contracts/${employeeContractUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  newContract: (token, payload) => {
    return axios.post(`${apiUrl}/api/contracts/employee-contracts/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getAllContracts: (token, key, limit = 25, page = 1, filtersString) => {
    return axios.get(
      `${apiUrl}/api/contracts/employee-contracts/?desc_sort=${key}&page_size=${limit}&page=${page}${filtersString ? filtersString : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getJobsOfTheMonth: (token, employee_uuid) => {
    return axios.get(
      `${apiUrl}/api/jobs/user-application/${employee_uuid}/?sort=job__start_date&is_archived=False&job__start_date__gte=${moment()
        .startOf("month")
        .format("DD-MM-YYYY")}&job__start_date__lte=${moment()
          .endOf("month")
          .format("DD-MM-YYYY")}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getAvailabilities: (token, employee_uuid) => {
    return axios.get(
      `${apiUrl}/api/availabilities/${employee_uuid}/${moment()
        .startOf("month")
        .format("DD-MM-YYYY")}/${moment()
          .endOf("month")
          .format("DD-MM-YYYY")}/`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  /**
   * @param {String} token
   * @param {String} employeeUuid
   * @param {Object} payload
   */
  createDaySetRange: (token, employeeUuid, payload) => {
    return axios.post(
      `${apiUrl}/api/availabilities/daysets/`,
      { ...payload, employee: employeeUuid },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  setAvailability: (token, employee_uuid, data) => {
    return axios.put(`${apiUrl}/api/availabilities/${employee_uuid}/`, data, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getHoursOfTheDay: (token, employee_uuid, date, startEnd) => {
    if (startEnd.start_date && startEnd.end_date) {
      return axios.get(
        `${apiUrl}/api/jobs/user-application/${employee_uuid}/?sort=start_date&is_archived=False&start_date__gte=${startEnd.start_date}&start_date__lte=${startEnd.end_date} 23:59`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );
    }
    if (date) {
      const formatedDate = date.format("DD-MM-YYYY");
      return axios.get(
        `${apiUrl}/api/jobs/user-application/${employee_uuid}/?sort=start_date&is_archived=False&start_date__gte=${formatedDate}&start_date__lte=${formatedDate} 23:59`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );
    }
  },
  getAgendaHoursOfTheDay: (token, employee_uuid, date, startEnd) => {
    if (startEnd.start_date && startEnd.end_date) {
      return axios.get(
        `${apiUrl}/api/jobs/agenda/user-applications/${employee_uuid}/?sort=start_date&is_archived=False&start_date__gte=${startEnd.start_date}&start_date__lte=${startEnd.end_date} 23:59`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );
    }
    if (date) {
      const formatedDate = date.format("DD-MM-YYYY");
      return axios.get(
        `${apiUrl}/api/jobs/agenda/user-applications/${employee_uuid}/?sort=start_date&is_archived=False&start_date__gte=${formatedDate}&start_date__lte=${formatedDate} 23:59`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );
    }
  },
  getAvailabilitiesOfTheDay: (token, employee_uuid, date) => {
    const formatedDate = date.format("DD-MM-YYYY");
    return axios.get(
      `${apiUrl}/api/availabilities/${employee_uuid}/${formatedDate}/${formatedDate}/`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getAvailabilitiesRange: (token, employeeUuid, fromDate, toDate) => {
    return axios.get(
      `${apiUrl}/api/availabilities/daterange/${employeeUuid}/${fromDate}/${toDate}/?time_zone=${moment().format(
        "Z"
      )}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getNewAvailability: (token, employeeUuid) => {
    return axios.get(
      `${apiUrl}/api/availabilities/availability/${employeeUuid}/`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateNewAvailability: (token, employeeUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/availabilities/availability/${employeeUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getSpecificAvailability: (token, employee_uuid, from, to) => {
    return axios.get(
      `${apiUrl}/api/availabilities/${employee_uuid}/${from}/${to}/`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  /**
   * Update only one employee
   * @param {string} token
   * @param {string} employeeUuid
   * @param {object|FormData} data
   */
  updateEmployee: (token, employeeUuid, data) => {
    return axios.put(`${apiUrl}/api/users/profile/${employeeUuid}/`, data, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  /**
   * Updates the basic profile of an employee
   * @param {string} token
   * @param {string} employeeUuid
   * @param {object|FormData} data
   */
  updateBasicProfile: (token, employeeUuid, data) => {
    return axios.put(
      `${apiUrl}/api/users/employees/basic/${employeeUuid}/`,
      data,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type":
            data instanceof FormData
              ? "multipart/form-data"
              : "application/json",
        },
      }
    );
  },
  /**
   * Updates the basic profile of an employee
   * @param {string} token
   * @param {string} employeeUuid
   * @param {object|FormData} payload
   */
  updateEmployeeJobCategory: (token, employeeUuid, payload) => {
    return axios.post(
      `${apiUrl}/api/users/employees/job_categories/${employeeUuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type":
            payload instanceof FormData
              ? "multipart/form-data"
              : "application/json",
        },
      }
    );
  },
  /**
   * Updates the basic profile of an employee
   * @param {string} token
   * @param {string} employeeUuid
   */ getEmployeeFieldsOfInterest: (token, employeeUuid) => {
    return axios.get(
      `${apiUrl}/api/users/user-fields-of-interest/${employeeUuid}/?sort=job_category__name`,
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  },
  /**
   * Updates the basic profile of an employee
   * @param {string} token
   * @param {string} fieldUuid
   */ deleteEmployeeFieldsOfInterest: (token, fieldUuid) => {
    return axios.delete(
      `${apiUrl}/api/users/field-of-interest/${fieldUuid}/`,
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  },
  /**
   * Updates the personal profile of an employee
   * @param {string} token
   * @param {string} employeeUuid
   * @param {object|FormData} data
   */
  updatePersonalProfile: (token, employeeUuid, data) => {
    return axios.put(
      `${apiUrl}/api/users/employees/personal/${employeeUuid}/`,
      data,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  /**
   * Returns the workers closest to a job.
   */
  nearestEmployees: (
    token,
    tenantUuid,
    jobUuid,
    maxDistance = 100,
    key,
    limit = 25,
    page = 1,
    filtersString
  ) => {
    return axios.get(
      `${apiUrl}/api/users/nearest/ambassador/${tenantUuid}/${jobUuid}/${maxDistance ? `${maxDistance}/` : ""
      }?sort=${key}&is_archived=False&is_reg_complete=True&user_extra_info_user__is_verified=True&page_size=${limit}&page=${page}${filtersString ? filtersString : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getEmployeeJobs: (token, tenantUuid, employeeUuid, startDate) => {
    return axios.get(
      `${apiUrl}/api/jobs/application-list/all/${tenantUuid}/?invalid_state__isnull=True&active_status__status_type__in=-job_applied&start_date=${startDate}&is_archived=False&employee__uuid=${employeeUuid}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getApplicationsJobs: (
    token,
    tenantUuid,
    jobUuid,
    { filters = {}, sortBy = "full_name" } = {}
  ) => {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/users/employees/${tenantUuid}/${jobUuid}/?sort=${sortBy}&${filtersString}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getIncomingJobApplications(token, jobUUid, options = {}) {
    const { sort = "full_name", pagination = {}, filters = {} } = options;
    const filterString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/users/scheduled-shared-employees/${jobUUid}/?sort=${sort}${pagination.page ? "&page=" + pagination.page : ""
      }${pagination.size ? "&page_size=" + pagination.size : ""
      }${filterString}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getFreelancerJobApplications: (
    token,
    jobUuid,
    jobApplicationStatus = null
  ) => {
    return axios.get(
      `${apiUrl}/api/jobs/marketplace/job-application/${jobUuid}/${jobApplicationStatus &&
      `?active_status__status_type=${jobApplicationStatus}`}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getAvailabilitiesResume(
    token,
    startDate,
    endDate,
    availabilityStatus = null,
    page = null,
    limit = null
  ) {
    return axios
      .get(
        `${apiUrl}/api/availabilities/employee/${startDate}/${endDate}/?${availabilityStatus ? `wants_work=${availabilityStatus}` : ""
        }${limit ? `&page_size=${limit}` : ""}${page ? `&page=${page}` : ""}`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      )
      .then((response) => ({ response, resume: response.data.results }));
  },
  getNewAvailabilitiesResume(
    token,
    date,
    availabilityStatus = null,
    page = null,
    limit = null
  ) {
    return axios
      .get(
        `${apiUrl}/api/availabilities/availability/?day=${date}${availabilityStatus ? `&status=${availabilityStatus}` : ""
        }${limit ? `&page_size=${limit}` : ""}${page ? `&page=${page}` : ""}`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      )
      .then((response) => ({ response, resume: response.data.results }));
  },
  getTableEmployees(
    token,
    {
      sortKey = "title",
      pagination = { page: 1, size: 25 },
      filters = {},
      exportOptions = null,
      isArchive = false,
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/users/employees/?sort=${sortKey}${pagination
        ? `&page=${pagination.page}&page_size=${pagination.size}`
        : ""
      }&is_archived=${isArchive ? "True" : "False"
      }&is_reg_complete=True&user_extra_info_user__is_verified=True${filtersString}${exportOptions
        ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
        : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
  setEmployee: (token, employeeUuid, payload) => {
    return axios.put(`${apiUrl}/api/users/profile/${employeeUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getTableApplicants(
    token,
    {
      sortKey = "date_created",
      isArchive = false,
      pagination = { page: 1, size: 25 },
      filters = {},
      exportOptions = null,
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/users/applicants/?sort=${sortKey}${pagination
        ? `&page=${pagination.page}&page_size=${pagination.size}`
        : ""
      }&is_archived=${isArchive ? "True" : "False"
      }&is_reg_complete=False${filtersString}${exportOptions
        ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
        : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
  sendScheduledJobsMessage: (token, startDate, endDate, payload) => {
    return axios.post(
      `${apiUrl}/api/emails/scheduling/${startDate}/${endDate}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getFreeFields(token, employeeUuid) {
    return axios.get(`${apiUrl}/api/users/extra-fields/${employeeUuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateEmployeesBulk(token, payload) {
    return axios.put(`${apiUrl}/api/users/employees/update/bulk/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  uploadImagesForSetCards(token, payload, employee_uuid) {
    return axios.post(`${apiUrl}/api/users/employee-images/${employee_uuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getImagesforSetCardsByEmployee(token, employee_uuid) {
    return axios.get(`${apiUrl}/api/users/employee-images/${employee_uuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    })
  },
  getSetCardsPDF(token, payload) {
    return axios.post(`${apiUrl}/api/external/connections/setcard/export/`, payload, {
      headers: {Authorization: `JWT ${token}`},
    })
  },
  deleteImagesForSetCards(token, payload) {
    return axios.delete(`${apiUrl}/api/users/employee/image/delete/${payload}/`,{
      headers: {Authorization: `JWT ${token}`},
    })
  },
  getEmployeesConceptPlanning(token, projectuuid, date) {
    return axios.get(`${apiUrl}/api/jobs/job-applications/list/?job__job__uuid=${projectuuid}&start_date__gte=${date}`, {
      headers: {Authorization: `JWT ${token}`},
    })
  }
};

export default employeeService;
