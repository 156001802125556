import axios from "axios";
import { getFiltersString } from "utils/helpers";
import { serverConfig } from "../../config";

const apiUrl = serverConfig.apiUrl;
const marketplaceApiUrl = serverConfig.marketplaceApiUrl;

const marketplaceService = {
  getJobApplicationInvalidStates: (token, tenantUuid) => {
    return axios.get(
      `${marketplaceApiUrl}/api/jobs/job-application/invalid-state/?tenant_uuid=${tenantUuid}&token=${token}`
    );
  },
  getTenantJobPostingFee: (token, tenantUuid, tenantAdminUuid) => {
    return axios.get(
      `${marketplaceApiUrl}/api/users/tenants/tenant-admin-data-by-tenant/?tenant_uuid=${tenantUuid}&token=${token}&tenant_admin_uuid=${tenantAdminUuid}`
    );
  },
};

export default marketplaceService;
