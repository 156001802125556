import axios from "axios";
import { serverConfig } from "../config";
import { getFiltersString } from "utils/helpers";
const apiUrl = serverConfig.apiUrl + "/api/notifications";

const notificationService = {
  /**
   * Send a custom notification to the app mobile
   * @param {string} token
   * @param {string[]} users uuids
   * @param {string} title
   * @param {string} message
   */
  sendNotification: (token, users, title, message) => {
    return axios.post(
      `${apiUrl}/custom/`,
      { users, title, message },
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  getJobNotifications: (token, jobUuid, employeeUuid) => {
    return axios.get(
      `${apiUrl}/all/?desc_sort=date_created&user__uuid=${employeeUuid}&notification_type__key=invite_notificacion&extra_data__icontains=${jobUuid}&status__in=unread,read,deleted`,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  getEmployeeNotifications: (
    token,
    employeeUuid,
    {
      filters = "",
      sort = "date_created",
      sortType = "desc_sort",
      page,
      limit,
      invitationType = "incite_notificacion"
    } = {}
  ) => {
    const stringFilters =
      typeof filters === "object" ? getFiltersString(filters) : filters;

    return axios.get(
      `${apiUrl}/all/?${sortType || "desc_sort"}=${sort || "date_created"}${
        page ? `&page=${page}` : ""
      }${limit ? `&page_size=${limit}` : ""}&user__uuid=${employeeUuid}${
        invitationType ? `&notification_type__key=${invitationType}` : ""
      }&status__in=unread,read,deleted${stringFilters || ""}`,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  sendOnboardingEmail: (token, tenantUuid, users) => {
    return axios.post(
      `${apiUrl}/send/invitation/${tenantUuid}/`,
      { users },
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  getDashboardNotifications: (
    token,
    dashboardUuid,
    limit,
    page,
    { status = ["unread", "read"] } = {}
  ) => {
    // return axios.get(
    //   `${apiUrl}/all/?user__uuid=${dashboardUuid}&desc_sort=sent_time&status__in=${status.join(
    //     ","
    //   )}&sent_time__isnull=False${limit ? `&page_size=${limit}` : ""}${
    //     page ? `&page=${page}` : ""
    //   }`,
    //   {
    //     headers: { Authorization: `JWT ${token}` }
    //   }
    // );
    return axios.get(
      `${apiUrl}/all/?user__uuid=${dashboardUuid}&desc_sort=date_created&status__in=${status.join(
        ","
      )}${limit ? `&page_size=${limit}` : ""}${
        page ? `&page=${page}` : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  setDashboardNotification: (token, notificationUuid, payload) => {
    return axios.put(`${apiUrl}/${notificationUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` }
    });
  },
  clearDashboardNotifications: (token) => {
    return axios.put(`${apiUrl}/clear-notifications/`,{}, {
      headers: { Authorization: `JWT ${token}` }
    });
  },
  getNotificationExport: (token, link) => {
    return axios.get(`${link}`, {
      headers: { Authorization: `JWT ${token}` },
      responseType: "blob"
    });
  }
};

export default notificationService;
