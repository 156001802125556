import {
  SET_SELECTED_NOTIFICATION,
  SET_NOTIFICATION_LOADING,
  SET_NOTIFICATION_UNREAD
} from "../types";
import { combineReducers } from "redux";

const selected = (state = null, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_NOTIFICATION:
      return action.data;

    default:
      return state;
  }
};

const loading = (state = false, action = {}) => {
  switch (action.type) {
    case SET_NOTIFICATION_LOADING:
      return action.data;

    default:
      return state;
  }
};

const unread = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_NOTIFICATION_UNREAD:
      return action.data;

    default:
      return state;
  }
};

export default combineReducers({
  selected,
  loading,
  unread
});
