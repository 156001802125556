import axios from "axios";
import { serverConfig } from "../config";

const apiUrl = serverConfig.apiUrl;

class SubscriptionService {
  static axiosInstance = axios.create({
    baseURL: `${apiUrl}/api`
  });

  /**
   * @param {string} userUuid
   * @param {("daily_report" | "weekly_report")[]} subscriptionKeys
   */
  static unsubscribe(userUuid, subscriptionKeys) {
    return Promise.all(
      subscriptionKeys.map(key =>
        this.axiosInstance
          .post(`/notifications/unsubscribe/${userUuid}/`, {
            setting_key: key,
            value: false
          })
          .then(() => ({ setting_key: key, success: true }))
          .catch(() => ({ setting_key: key, success: false }))
      )
    ).then(responses => {
      return responses;
    });
  }
}

export default SubscriptionService;
