import {
  LOGIN_EMPLOYEE_USER,
  LOAD_EMPLOYEE_USER_DATA,
  TOGGLE_EMPLOYEE_LOADING,
  COMPLETE_REGISTRY,
  LOGOUT_EMPLOYEE_USER,
  ADD_TO_DOCUMENTS_LIST,
  SET_UPLOAD_DOCUMENTS_LIST,
} from "../types";
import { combineReducers } from "redux";

const data = (state = null, action = {}) => {
  switch (action.type) {
    case LOAD_EMPLOYEE_USER_DATA:
      return action.data;

    case LOGOUT_EMPLOYEE_USER:
      return null;

    default:
      return state;
  }
};

const token = (state = "", action = {}) => {
  switch (action.type) {
    case LOGIN_EMPLOYEE_USER:
      return action.data.token;

    case LOGOUT_EMPLOYEE_USER:
      return "";

    default:
      return state;
  }
};

const tenant = (state = null, action = {}) => {
  switch (action.type) {
    case LOGIN_EMPLOYEE_USER:
      return action.data.tenant;

    case LOGOUT_EMPLOYEE_USER:
      return null;

    default:
      return state;
  }
};

const authenticated = (state = false, action = {}) => {
  switch (action.type) {
    case LOGIN_EMPLOYEE_USER:
      return !!action.data;

    case LOGOUT_EMPLOYEE_USER:
      return false;

    default:
      return state;
  }
};

const loading = (state = false, action = {}) => {
  switch (action.type) {
    case TOGGLE_EMPLOYEE_LOADING:
      return action.toggle;

    case LOGOUT_EMPLOYEE_USER:
      return false;

    default:
      return state;
  }
};

const completedRegister = (state = false, action = {}) => {
  switch (action.type) {
    case LOAD_EMPLOYEE_USER_DATA:
      return !!action.data.basic_reg_completed_at;

    case COMPLETE_REGISTRY:
      return action.completed;

    case LOGOUT_EMPLOYEE_USER:
      return false;

    default:
      return state;
  }
};

const uploadDocumentsList = (state = [], action = {}) => {
  switch (action.type) {
    case SET_UPLOAD_DOCUMENTS_LIST:
      return action.data;

    case ADD_TO_DOCUMENTS_LIST:
      action.data.map((file) => state.push(file))
      let documentObjectsArray = [...new Set(state)];
      return documentObjectsArray
    default:
      return state;
  }
};

export default combineReducers({
  data,
  token,
  authenticated,
  loading,
  completedRegister,
  tenant,
  uploadDocumentsList,
});
