import { combineReducers } from "redux";
/* Reducers */
import projects from "./projects";
import jobs from "./jobs";
import jobRequests from "./jobRequests";
import shifts from "./shifts";
import newemployers from "./newemployers";
import employers from "./employers";
import employees from "./employees";
import incomingEmployees from "./incomingEmployees";
import applicants from "./applicants";
import clients from "./clients";
import customizationModal from "./customizationModal";
import newFreelancers from "./newFreelancers";
import freelancers from "./freelancers";
import singles from "./singles";
import totals from "./totals";
import allHours from "./allHours";
import invoices from "./invoices";
import externals from "./externals";
import planningProgress from "./planningProgress";

export const database = combineReducers({
  projects,
  jobs,
  jobRequests,
  shifts,
  newemployers,
  employers,
  employees,
  incomingEmployees,
  applicants,
  clients,
  newFreelancers,
  freelancers,
  singles,
  totals,
  allHours,
  invoices,
  externals,
  customizationModal,
  planningProgress,
});

export default database;
