import { combineReducers } from "redux";
/* Reducers */
import projects from "./projects";
import employees from "./employees";

export const media = combineReducers({
  projects,
  employees,
});

export default media;
