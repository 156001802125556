import axios from "axios";
import { getFiltersString } from "utils/helpers";
import { serverConfig } from "../../config";

const apiUrl = serverConfig.apiUrl;
const marketplaceApiUrl = serverConfig.marketplaceApiUrl;

const invoiceService = {
  getTableInvoices(
    token,
    {
      sortKey = "start_date",
      pagination = { page: 1, size: 25 },
      filters = {},
      exportOptions = null,
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/marketplace-declarations/?sort=${sortKey}${
        pagination
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
  getInvoiceData(token, invoiceUuid, pdf = false) {
    return axios.get(
      `${apiUrl}/api/jobs/marketplace/declarations/${invoiceUuid}/${
        pdf ? `?view=pdf` : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateInvoiceStatus(token, invoiceUuid, payload) {
    return axios.put(
      `${apiUrl}/api/jobs/marketplace/declarations/verify/${invoiceUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createNewInvoiceProposal(token, tenantUuid, payload) {
    return axios.post(
      `${marketplaceApiUrl}/api/declarations/tenant/?tenant_uuid=${tenantUuid}&token=${token}`,
      payload
    );
  },
  exportAllInvoices: (token, key, filtersString = {}, sortType = "sort") => {
    filtersString =
      typeof filtersString === "object"
        ? getFiltersString(filtersString)
        : filtersString;

    return axios.post(
      `${apiUrl}/api/jobs/marketplace/declarations/export/?${
        key ? `${sortType}=${key}` : ""
      }${filtersString ? filtersString : ""}`,
      {},
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
};

export default invoiceService;
