import { SET_SETTING_TENANT } from "../types";
import { combineReducers } from "redux";

const timeInterval = (state = "1", action = {}) => {
  switch (action.type) {
    case SET_SETTING_TENANT:
      return action.data.time_interval_for_declaration;
    default:
      return state;
  }
};

export default combineReducers({
  timeInterval,
});
