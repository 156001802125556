import axios from "axios";
import { serverConfig } from "../config";
/* Utils */
import { getFiltersString } from "utils/helpers";

const apiUrl = serverConfig.apiUrl;

const contractService = {
  getContracts: (token, tenantUuid, options = {}) => {
    const { filters = "", sort = "name", is_archived = false } = options;

    return axios.get(
      `${apiUrl}/api/contracts/${tenantUuid}/?sort=${sort}${
        typeof filters === "object" ? getFiltersString(filters) : filters
      }${is_archived ? "" : "&is_archived=False"}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createContract: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/contracts/${tenantUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateContract: (token, contractUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/contracts/${contractUuid}/update/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createHourlyRateContract: (token, contractUuid, hourlyRateList) => {
    return axios.post(
      `${apiUrl}/api/contracts/ages-custom-rate/${contractUuid}/`,
      hourlyRateList,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateHourlyRateContract: (token, contractHourlyRateUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/contracts/ages-custom-rate/update/${contractHourlyRateUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },

  getHourlyRateContract: (token, contractUuid, options = {}) => {
    const { filters = "", sort = "age" } = options;

    return axios.get(
      `${apiUrl}/api/contracts/ages-custom-rate/${contractUuid}/?sort=${sort}${
        typeof filters === "object" ? getFiltersString(filters) : filters
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
};

export default contractService;
