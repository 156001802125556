import { CHANGE_LANGUAGE, LOAD_CATEGORIES, LOAD_LANGUAGES } from "redux/types";

/**
 * Change the current language
 * @param {String} language ISO 639-1:2002, Codes for the representation of names of languages
 */
export function changeLanguage(language) {
  if (typeof window === "object" && window.localStorage) {
    localStorage.alhubLang = language; //The selected language is saved in localStorange
  }
  return { type: CHANGE_LANGUAGE, language };
}

/**
 * Load the categories in the store
 * @param {Array} categories to save
 */
export function loadCategories(categories) {
  return { type: LOAD_CATEGORIES, categories };
}

/**
 * Load the languages in the store
 * @param {Array} languages to save
 */
 export function loadLanguages(languages) {
  return { type: LOAD_LANGUAGES, languages };
}
