import axios from "axios";
import { serverConfig } from "../config";
/* Utils */
import { correctResults, getFiltersString } from "utils/helpers";
const apiUrl = serverConfig.apiUrl;
const marketplaceApiUrl = serverConfig.marketplaceApiUrl;

const tenantService = {
  get(token) {
    return axios.get(`${apiUrl}/api/commons/tenant/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getUsersDashboard: (token, tenantUuid, excludeArchive = true) => {
    return axios.get(
      `${apiUrl}/api/users/dashboard-users/${tenantUuid}/${
        excludeArchive ? "?is_archived=False" : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getBrandUsers: (token, tenantUuid, excludeArchive = true) => {
    return axios.get(
      `${apiUrl}/api/users/app-brand-users/${tenantUuid}/${
        excludeArchive ? "?is_archived=False" : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getWorkflowTemplates: (token, tenantUuid) => {
    return axios.get(
      `${apiUrl}/api/jobs/job-type/${tenantUuid}/?is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getAllWorkflowTemplates: (token, tenantUuid, workflowTemplateName) => {
    return axios.get(
      `${apiUrl}/api/jobs/job-type/${tenantUuid}/${
        workflowTemplateName ? `?spot_type=${workflowTemplateName}` : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createWorkflowTemplate: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/jobs/job-type/${tenantUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateWorkflowTemplate: (token, workflowTemplateUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/job-type/update/${workflowTemplateUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTenantContracts: (token, tenantUuid) => {
    return axios.get(
      `${apiUrl}/api/contracts/${tenantUuid}/?sort=name&is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTenantFieldsOfInterest: (
    token,
    tenantUuid,
    filterString,
    page_size,
    page,
    sort
  ) => {
    return axios.get(
      `${apiUrl}/api/users/field-of-interest/?user__current_tenant__uuid=${tenantUuid}${filterString}&is_archived=False${
        page_size ? `&page_size=${page_size}` : ""
      }${page ? `&page=${page}` : ""}${sort ? `&sort=${sort}` : ""}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getHourlyRates: (token, tenantUuid) => {
    return axios.get(`${apiUrl}/api/jobs/hourly-rate/${tenantUuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  createHourlyRates: (token, tenantUuid, hourlyRateList) => {
    return Promise.all(
      hourlyRateList.map((hourlyRateItem) => {
        let { age, rate, start_date, end_date } = hourlyRateItem;

        return axios.post(
          `${apiUrl}/api/jobs/hourly-rate/create/${tenantUuid}/`,
          {
            age,
            rate,
            end_date,
            start_date,
          },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );
      })
    ).then((responses) => correctResults(responses));
  },
  updateHourlyRates: (token, hourlyRateList) => {
    return Promise.all(
      hourlyRateList.map((hourlyRateItem) => {
        let { uuid, age, rate, start_date, end_date } = hourlyRateItem;

        return axios.put(
          `${apiUrl}/api/jobs/hourly-rate/update/${uuid}/`,
          {
            age,
            rate,
            end_date,
            start_date,
          },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );
      })
    ).then((responses) => correctResults(responses));
  },
  getSalaryProfiles: (token) => {
    return axios.get(
      `${apiUrl}/api/commons/tenant/salary-profile/?is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createSalaryProfile: (token, payload) => {
    const { name, custom_id, rate_periods } = payload;
    return axios.post(
      `${apiUrl}/api/commons/tenant/salary-profile/`,
      { name, custom_id, rate_periods },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateSalaryProfile: (token, salaryProfileUuid, payload) => {
    const { name, custom_id, rate_periods, is_archived } = payload;
    return axios.put(
      `${apiUrl}/api/commons/tenant/salary-profile/${salaryProfileUuid}/`,
      { name, custom_id, rate_periods, is_archived },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  update: (token, tenantUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/commons/tenant/update/${tenantUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTemplatePools: (token, tenantUuid, options = {}) => {
    let { filters = {} } = options;
    filters = typeof filters == "string" ? filters : getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/filters/${tenantUuid}/?is_archived=False${
        filters !== "" ? filters : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createCustomFilter: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/jobs/filters/${tenantUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  editCustomFilter: (token, tenantUuid, customFilterUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/filters-update-delete/${tenantUuid}/${customFilterUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getCustomFilterEmployees: (token, customFilterUuid, options = {}) => {
    let { filters = {}, page, limit } = options;
    filters = typeof filters == "string" ? filters : getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/filters/employees/${customFilterUuid}/?is_archived=False${
        filters !== "" ? filters : ""
      }${page ? `&page=${page}` : ""}${limit ? `&page_size=${limit}` : ""}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getCustomFilterWhitelist: (token, tenantUuid, options = {}) => {
    let { filters = {} } = options;
    filters = typeof filters == "string" ? filters : getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/filters/whitelist/${tenantUuid}/?is_archived=False${
        filters !== "" ? filters : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  addCustomFilterWhitelist: (token, tenantUuid, employeeList) => {
    return axios.post(
      `${apiUrl}/api/jobs/filters/whitelist/${tenantUuid}/`,
      employeeList,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  setCustomFilterWhitelist: (token, tenantUuid, employeeList) => {
    return axios.put(
      `${apiUrl}/api/jobs/filters/whitelist/${tenantUuid}/`,
      employeeList,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  forceImportAfas: (token) => {
    const afasId = "84805";
    return axios.post(`${apiUrl}/api/afas/${afasId}/force`, [], {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getAfastTaskClientIDs: (token) => {
    return axios.get(`${apiUrl}/api/afas/afastask/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateAfastTaskClientIDs: (token, client_id = "") => {
    const afasId = "84805";
    return axios.put(
      `${apiUrl}/api/afas/${afasId}/`,
      { client_id },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTemplateFunctions: (token, functionUuid = null, isArchived = false) => {
    return axios.get(
      `${apiUrl}/api/functions/${
        functionUuid ? functionUuid : ""
      }/?is_archived=${isArchived ? "True" : "False"}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createFunction: (token, payload) => {
    return axios.post(`${apiUrl}/api/functions/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  editFunction: (token, functionUuid, payload) => {
    return axios.put(`${apiUrl}/api/functions/${functionUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getDashboardStatistics: (token) => {
    return axios.get(`${apiUrl}/api/commons/tenant/statistics/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getMarketplaceDashboardStatistics: (token, tenantUuid) => {
    return axios.get(
      `${marketplaceApiUrl}/api/jobs/report-jap-and-declaration/?tenant_uuid=${tenantUuid}&token=${token}`
    );
  },
  getSharedCategories: (token, connectionUuid) => {
    return axios.get(
      `${apiUrl}/api/jobs/category/connection/${connectionUuid}/?is_shareable=True`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTenantList(options = {}) {
    const { filters } = options;
    const filtersString = getFiltersString(filters);

    return axios
      .get(`${apiUrl}/api/commons/tenant/list/?sort=name${filtersString}`)
      .then((response) => response.data.results);
  },
  getTenantPayment: (token) => {
    return axios.get(`${apiUrl}/api/commons/tenant/payment/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getTenantCommonInfo: (token) => {
    return axios.get(`${apiUrl}/api/commons/common-information/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateTenantPayment: (token, paymentUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/commons/tenant/payment/${paymentUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTenantTemplatesReports: (token, template) => {
    return axios.get(`${apiUrl}/api/commons/template/${template}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getTenantSurcharges: (token) => {
    return axios.get(`${apiUrl}/api/ratecards/surcharge/?is_archived=False`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateTenantSurcharge: (token, surchargeUuid, payload = {}) => {
    return axios.put(
      `${apiUrl}/api/ratecards/surcharge/${surchargeUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createTenantSurcharge: (token, payload = []) => {
    return axios.post(`${apiUrl}/api/ratecards/surcharge/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getTenantHolidays: (token) => {
    return axios.get(`${apiUrl}/api/ratecards/holiday/?is_archived=False`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateTenantHoliday: (token, holidayUuid, payload = {}) => {
    return axios.put(
      `${apiUrl}/api/ratecards/holiday/${holidayUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createTenantHoliday: (token, payload = []) => {
    return axios.post(`${apiUrl}/api/ratecards/holiday/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getTenantRatecards: (token, { requestConfig } = { requestConfig: {} }) => {
    return axios.get(`${apiUrl}/api/ratecards/rate-card/?is_archived=False`, {
      ...requestConfig,
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateTenantRatecard: (token, rateCardUuid, payload = {}) => {
    return axios.put(
      `${apiUrl}/api/ratecards/rate-card/${rateCardUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createTenantRatecard: (token, payload = []) => {
    return axios.post(`${apiUrl}/api/ratecards/rate-card/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getFreeFields: (token) => {
    return axios.get(
      `${apiUrl}/api/commons/tenant/extra-fields/?is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getJobFreeFields: (token, uuid) => {
    return axios.get(
      `${apiUrl}/api/commons/tenant/job-extra-fields/${
        uuid ? uuid + "/" : ""
      }?is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createFreeField: (token, payload = {}) => {
    return axios.post(`${apiUrl}/api/commons/tenant/extra-fields/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  createJobFreeField: (token, payload = {}) => {
    return axios.post(
      `${apiUrl}/api/commons/tenant/job-extra-fields/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateFreeField: (token, fieldUuid, payload = {}) => {
    return axios.put(
      `${apiUrl}/api/commons/tenant/extra-fields/${fieldUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateJobFreeField: (token, fieldUuid, payload = {}) => {
    return axios.put(
      `${apiUrl}/api/commons/tenant/job-extra-fields/${fieldUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getExceptionStates: (token) => {
    return axios.get(
      `${apiUrl}/api/commons/exception-states/?is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createExceptionState: (token, payload = {}) => {
    return axios.post(`${apiUrl}/api/commons/exception-states/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateExceptionState: (token, fieldUuid, payload = {}) => {
    return axios.put(
      `${apiUrl}/api/commons/exception-states/${fieldUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTenantSettings: (token, { requestConfig } = { requestConfig: {} }) => {
    return axios.get(`${apiUrl}/api/commons/tenant/settings/`, {
      ...requestConfig,
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateTenantSettings: (token, settingUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/commons/tenant/settings/${settingUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  checkCompanyName: (name) => {
    return axios.post(`${apiUrl}/api/commons/tenant/check/name/`, { name });
  },
  createTenant: (payload) => {
    return axios.post(
      `${apiUrl}/api/commons/tenant/create_with_connection/`,
      payload
    );
  },
  getFeatureFlags: ({ token }) => {
    return axios.get(`${apiUrl}/api/commons/tenant/feature-flags/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getTenantPlan: ({ token }) => {
    return axios.get(`${apiUrl}/api/commons/tenant/plan-package/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getTenantExternalConnections: ({ token }) => {
    return axios.get(`${apiUrl}/api/external/connections/tenant/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
};

export default tenantService;
