import ReactDOM from "react-dom";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { setTenant } from "redux/actions/user";
/* Services */
import { sharedJobServices, jobService, tenantService } from "services";
/* Utils */
import { makeCancelable } from "utils/helpers";
/* Custom Hooks */
import { useCancellablePromise, useActions } from "hooks";

function getFiltersByType(type) {
  switch (type) {
    case "requests":
      return {
        /* startDate: {
          suffix: "__gte",
          key: "start_date",
          value: moment().format("DD-MM-YYYY"),
        }, */
      };

    default:
      return {};
  }
}

export function useGetPin(pin) {
  const user = useSelector((state) => {
    return state.user;
  });
  const [pinData, setPinData] = useState(null);
  const [loading, setLoading] = useState(false);
  let getPin = null;

  function cancelRequest() {
    if (getPin) {
      getPin.cancel();
    }
  }

  useEffect(() => {
    //if (!user.authenticated) return;

    cancelRequest();

    getPin = makeCancelable(
      sharedJobServices.getPinData(user.data?.token || "", pin)
    );

    setLoading(true);

    getPin.promise
      .then((response) => {
        setLoading(false);
        setPinData(response.data.results);
      })
      .catch((err) => {
        if (err.isCanceled) return;

        setLoading(false);
      });

    return () => {
      cancelRequest();
    };
  }, [user.authenticated, pin]);

  return { pinData, loading };
}

export function useGetTenantByPin(pin) {
  const user = useSelector((state) => {
    return state.user;
  });
  const [tenantData, setTenantData] = useState(null);
  const [loading, setLoading] = useState(false);
  let getTenant = null;

  function cancelRequest() {
    if (getTenant) {
      getTenant.cancel();
    }
  }

  useEffect(() => {
    if (!user.authenticated) return;

    cancelRequest();

    getTenant = makeCancelable(
      sharedJobServices.getTenantByPin(user.data.token, pin)
    );

    setLoading(true);

    getTenant.promise
      .then((response) => {
        setLoading(false);
        setTenantData(response.data.results[0]);
      })
      .catch((err) => {
        if (err.isCanceled) return;

        setLoading(false);
      });

    return () => {
      cancelRequest();
    };
  }, [user.authenticated, pin]);

  return { tenantData, loading };
}

export function useCreateTenantConnectionPin() {
  const user = useSelector((state) => {
    return state.user;
  });
  const [pin, setPin] = useState(null);
  const [loading, setLoading] = useState(false);
  let getPin = null;

  function cancelRequest() {
    if (getPin) {
      getPin.cancel();
    }
  }

  useEffect(() => {
    if (!user.authenticated) return;

    cancelRequest();

    getPin = makeCancelable(
      sharedJobServices.createTenantConnectionPin(user.data.token)
    );

    setLoading(true);

    getPin.promise
      .then((response) => {
        setLoading(false);
        setPin(response.data.results.pin);
      })
      .catch((err) => {
        if (err.isCanceled) return;

        setLoading(false);
      });

    return () => {
      cancelRequest();
    };
  }, [user.authenticated, pin]);

  return { pin, loading };
}

export function useSolveConnectionRequest() {
  const user = useSelector((state) => {
    return state.user;
  });
  const [action, setAction] = useState(null);
  const [pin, setPin] = useState(null);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  let acceptConnection = null;
  let rejectConnection = null;

  function setSolveAction(action, currentPin) {
    setAction(action);
    setPin(pin);
  }

  function cancelAcceptRequest() {
    if (acceptConnection) {
      acceptConnection.cancel();
    }
  }

  function cancelRejectRequest() {
    if (rejectConnection) {
      rejectConnection.cancel();
    }
  }

  useEffect(() => {
    if (!user.authenticated || !pin || !action) return;

    setLoading(true);

    switch (action) {
      case "accept":
        cancelAcceptRequest();

        acceptConnection = makeCancelable(
          sharedJobServices.acceptTenantConnection(user.data.token, {
            pin: pin,
          })
        );

        acceptConnection.promise
          .then((response) => {
            setLoading(false);
            setStatus(response.data.status_code === 200);
          })
          .catch((err) => {
            if (err.isCanceled) return;

            setLoading(false);
          });
        return () => {
          cancelAcceptRequest();
        };

      case "reject":
        cancelRejectRequest();
        rejectConnection = makeCancelable(
          sharedJobServices.rejectTenantConnection(user.data.token, pin, {
            rejected: true,
          })
        );

        rejectConnection.promise
          .then((response) => {
            setLoading(false);
            setStatus(response.data.status_code === 200);
          })
          .catch((err) => {
            if (err.isCanceled) return;

            setLoading(false);
          });
        return () => {
          cancelRejectRequest();
        };

      default:
        break;
    }
  }, [user.authenticated, pin, action]);

  return { status, action, loading, setPin, setSolveAction };
}

export function useFindConnection(pinOwnerUuid) {
  const user = useSelector((state) => {
    return state.user;
  });
  const [connectionData, setConnectionData] = useState(false);
  const [loading, setLoading] = useState(false);
  let findConnection = null;

  function cancelRequest() {
    if (findConnection) {
      findConnection.cancel();
    }
  }

  useEffect(() => {
    if (!user.authenticated || !pinOwnerUuid) return;

    cancelRequest();

    findConnection = makeCancelable(
      sharedJobServices.getConnectionBetweenTenants(
        user.data.token,
        pinOwnerUuid,
        user.data.tenant.uuid
      )
    );

    setLoading(true);

    findConnection.promise
      .then((response) => {
        setLoading(false);
        setConnectionData(
          response.data.results.length > 0 ? response.data.results : null
        );
      })
      .catch((err) => {
        if (err.isCanceled) return;

        setLoading(false);
      });

    return () => {
      cancelRequest();
    };
  }, [user.authenticated, pinOwnerUuid]);

  return { connectionData, loading };
}

export function useFindConnections() {
  const user = useSelector((state) => {
    return state.user;
  });
  const [setTenantAction] = useActions([setTenant]);
  const [connectionsData, setConnectionsData] = useState([]);
  const [externalConnections, setExternalConnections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExternals, setLoadingExternals] = useState(false);
  const tenant = user && user.data && user.data.tenant;

  let findConnection = null;
  let findExternalConnections = null;
  let findConnections = null;


  function cancelRequest() {
    if (findConnection) {
      findConnection.cancel();
    }
    if (findExternalConnections) {
      findExternalConnections.cancel();
    }
  }

  const loadConnections = useCallback((connections) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setConnectionsData(connections);
      setLoading(false);
    });
  }, []);

  const loadExternalConnections = useCallback((connections) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setExternalConnections(connections);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!user.authenticated) return;
    cancelRequest();
    
    if (!tenant.connections && !loading) {
      setLoading(true);
      setLoadingExternals(true);
      findConnections = makeCancelable(Promise.all([sharedJobServices.getTenantConnections(user.data.token)
        , tenantService.getTenantExternalConnections({ token: user.data.token })
      ]))
    } else {
      setConnectionsData(tenant.connections)
    }

    if (tenant.external_connections) {
      setExternalConnections(tenant.external_connections)
    }



    findConnections && findConnections.promise
      .then((responses) => {
        if (responses[0].data.results && responses[1].data.results) {
          setTenantAction({ ...tenant, connections: responses[0].data.results, external_connections: responses[1].data.results }, user, false)
          loadConnections(
            responses[0].data.results
          );
          loadExternalConnections(
            responses[1].data.results
          )
        }
        setLoading(false)
        setLoadingExternals(false)

      })
      .catch((err) => {
        if (err.isCanceled) return;
        setLoading(false);
      });



    return () => {
      cancelRequest();
    };
  }, [user, tenant]);

  return { connectionsData, loading, externalConnections, loadingExternals };
}

export function useShareJob() {
  const user = useSelector((state) => {
    return state.user;
  });
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const { cancellablePromise } = useCancellablePromise();

  const loadResponse = useCallback((responseStatus) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setStatus(responseStatus);
      setLoading(false);
    });
  }, []);

  const shareJob = useCallback(
    (job, connection, selectedEmployeeRequest, cb) => {
      if (!user.authenticated || !job || !connection) return;

      setLoading(true);

      cancellablePromise(
        sharedJobServices.shareJobWithTenant(user.data.token, {
          job: job,
          connection: connection,
          description:
            selectedEmployeeRequest?.description &&
              selectedEmployeeRequest?.description !== ""
              ? selectedEmployeeRequest.description
              : undefined,
          number_of_employees_requested:
            selectedEmployeeRequest?.number_of_employees_requested &&
              selectedEmployeeRequest?.number_of_employees_requested !== null
              ? selectedEmployeeRequest.number_of_employees_requested
              : undefined,
        })
      )
        .then((response) => {
          loadResponse(response.data.status_code === 200);

          cb && cb(job);
        })
        .catch((err) => {
          if (err.isCanceled) return;

          setLoading(false);
        });
    },
    [user.authenticated]
  );

  const updateEmployeeRequest = useCallback(
    (sharedJob, selectedEmployeeRequest, cb) => {
      if (!user.authenticated || !sharedJob) return;

      setLoading(true);

      cancellablePromise(
        sharedJobServices.updateSharedJob(user.data.token, sharedJob, {
          description:
            selectedEmployeeRequest?.description !== undefined
              ? selectedEmployeeRequest.description !== ""
                ? selectedEmployeeRequest.description
                : ""
              : undefined,
          number_of_employees_requested:
            selectedEmployeeRequest?.number_of_employees_requested !== undefined
              ? selectedEmployeeRequest.number_of_employees_requested !== null
                ? selectedEmployeeRequest.number_of_employees_requested
                : null
              : undefined,
        })
      )
        .then((response) => {
          loadResponse(response.data.status_code === 200);
          cb && cb();
        })
        .catch((err) => {
          if (err.isCanceled) return;

          setLoading(false);
        });
    },
    [user.authenticated]
  );

  return { status, loading, shareJob, updateEmployeeRequest };
}

export function useArchiveSharedJob() {
  const user = useSelector((state) => {
    return state.user;
  });
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const { cancellablePromise } = useCancellablePromise();

  const archiveSharedJob = useCallback(
    (sharedJob, cb) => {
      if (!user.authenticated || !sharedJob) return;

      setLoading(true);

      cancellablePromise(
        sharedJobServices.updateSharedJob(user.data.token, sharedJob, {
          is_archived: true,
        })
      )
        .then((response) => {
          setStatus(response.data.status_code === 200);
          setLoading(false);
          cb && cb(sharedJob);
        })
        .catch((err) => {
          if (err.isCanceled) return;

          setLoading(false);
        });
    },
    [user.authenticated]
  );

  return { status, loading, archiveSharedJob };
}

export function useFindSharedJob(
  jobUuid,
  isArchived = null,
  connectionUuid = false
) {
  const user = useSelector((state) => {
    return state.user;
  });
  const [search, setSearch] = useState(true);
  const [sharedJob, setSharedJob] = useState([]);
  const [loading, setLoading] = useState(false);
  let shareJob = null;

  function cancelRequest() {
    if (shareJob) {
      shareJob.cancel();
    }
  }

  const loadSharedJobs = useCallback((sharedJob) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSharedJob(sharedJob ? sharedJob : []);
      setLoading(false);
      setSearch(false);
    });
  }, []);

  useEffect(() => {
    if (!user.authenticated || !jobUuid || !search) return;

    cancelRequest();

    shareJob = makeCancelable(
      sharedJobServices.getSharedJobs(
        user.data.token,
        jobUuid,
        isArchived,
        connectionUuid
      )
    );

    setLoading(true);

    shareJob.promise
      .then((response) => {
        loadSharedJobs(
          response.data.results.length > 0 ? response.data.results : null
        );
      })
      .catch((err) => {
        if (err.isCanceled) return;

        setLoading(false);
      });

    return () => {
      cancelRequest();
    };
  }, [user.authenticated, jobUuid, search, connectionUuid, isArchived]);

  return { sharedJob, loading, setSearch };
}

export function useJobRequestsForTable({
  sortKey = "start_date",
  type = "requests",
  pagination = { page: 1, size: 25 },
  filters = {},
  callEffects = true,
}) {
  const [jobRequests, setJobRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalJobRequests, setTotalJobRequests] = useState(0);
  const user = useSelector((state) => state.user);
  const reset = useSelector((state) => state.database.jobs.reset);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  const getJobRequests = useCallback(
    (page, size, { filters = {}, version = "" } = {}) => {
      if (!user.authenticated) return;
      cancelPromises();

      ReactDOM.unstable_batchedUpdates(() => {
        setLoading(true);
      });
      return cancellablePromise(
        sharedJobServices.getTableJobRequests(
          user.data.token,
          user.data.tenant.uuid,
          {
            sortKey,
            pagination: {
              page,
              size,
            },
            filters: {
              ...getFiltersByType(type),
              ...filters,
            },
            version,
          }
        )
      )
        .then((response) => ({
          jobRequests: response.data.results,
          totalJobRequests: response.data.total_count,
        }))
        .then(({ jobRequests, totalJobRequests }) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setLoading(false);
            setJobRequests(jobRequests);
            setTotalJobRequests(totalJobRequests);
          });

          return jobRequests;
        })
        .catch((err) => {
          if (err.isCanceled) return;

          setLoading(false);
        });
    },
    [type, sortKey, user.authenticated]
  );

  useEffect(() => {
    callEffects &&
      getJobRequests(pagination.page, pagination.size, { filters });
  }, [pagination.page, sortKey, type, filters, reset, callEffects]);

  return { jobRequests, loading, totalJobRequests, getJobRequests };
}

export function useHandleJobRequest() {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  const acceptRequest = useCallback(
    (sharedJob, cb = null) => {
      if (!user.authenticated) return;
      cancelPromises();

      ReactDOM.unstable_batchedUpdates(() => {
        setLoading(true);
      });

      return cancellablePromise(
        sharedJobServices
          .updateSharedJob(user.data.token, sharedJob, {
            status: "accepted",
          })
          .then((response) => {
            setLoading(false);
            cb && cb({ status: "accepted" });
            return response.data.results;
          })
          .catch((err) => {
            if (err.isCanceled) return;
            cb && cb({ status: "error" });
            setLoading(false);
          })
      );
    },
    [user.authenticated]
  );

  const rejectRequest = useCallback(
    (sharedJob, cb = null) => {
      if (!user.authenticated) return;
      cancelPromises();

      ReactDOM.unstable_batchedUpdates(() => {
        setLoading(true);
      });

      return cancellablePromise(
        sharedJobServices
          .updateSharedJob(user.data.token, sharedJob, {
            status: "rejected",
          })
          .then((response) => {
            setLoading(false);
            cb && cb({ status: "rejected" });
            return response.data.results;
          })
          .catch((err) => {
            if (err.isCanceled) return;
            cb && cb({ status: "error" });
            setLoading(false);
          })
      );
    },
    [user.authenticated]
  );

  const resolveRequestGroup = useCallback(
    (jobRequests, status, cb = null) => {
      if (!user.authenticated) return;
      cancelPromises();

      ReactDOM.unstable_batchedUpdates(() => {
        setLoading(true);
      });

      return cancellablePromise(
        sharedJobServices
          .bulkUpdateSharedJob(user.data.token, {
            status: status,
            job_requests_uuid: jobRequests.map((req) => req.request.uuid),
          })
          .then((response) => {
            setLoading(false);
            cb && cb({ status });
            return response.data.results;
          })
          .catch((err) => {
            if (err.isCanceled) return;
            cb && cb({ status: "error" });
            setLoading(false);
          })
      );
    },
    [user.authenticated]
  );

  return { acceptRequest, rejectRequest, resolveRequestGroup, loading };
}

export function useHandleCombinations() {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  const saveCombination = useCallback(
    (payload, cb = null) => {
      if (!user.authenticated) return;
      cancelPromises();

      ReactDOM.unstable_batchedUpdates(() => {
        setLoading(true);
      });

      return cancellablePromise(
        sharedJobServices
          .saveShareCombination(user.data.token, payload)
          .then((response) => {
            setLoading(false);
            cb && cb({ status: "accepted" });
            return response.data.results;
          })
          .catch((err) => {
            if (err.isCanceled) return;
            cb && cb({ status: "error" });
            setLoading(false);
          })
      );
    },
    [user.authenticated]
  );

  return { saveCombination, loading };
}
