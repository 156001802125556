import { combineReducers } from "redux";
/* Reducers */
import projects from "./projects";
import jobs from "./jobs";

export const pools = combineReducers({
  projects,
  jobs,
});

export default pools;
