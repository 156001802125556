import axios from "axios";
import { serverConfig } from "../config";
import { getFiltersString } from "utils/helpers";
const apiUrl = serverConfig.apiUrl;

const planningProgressService = {
  getTablePlanningProgress(
    token,
    {
      sortKey = "name",
      isArchive = false,
      pagination = { page: 1, size: 25 },
      filters = {},
      exportOptions = null,
      listConnections = false
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/projects/progress/${
        listConnections ? "connections/" : ""
      }?sort=${sortKey}${
        pagination
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }&is_archived=${isArchive ? "True" : "False"}${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json"
      }
    );
  }
};

export default planningProgressService;
