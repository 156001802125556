import { combineReducers } from "redux";
import { CHANGE_PRESETS, USER_LOGIN } from "redux/types";

const presets = (
  state = ["contracts", "AS", "employeeCategories", "HR-TNA"],
  action = {}
) => {
  switch (action.type) {
    case CHANGE_PRESETS:
      return action.data;

    case USER_LOGIN:
      // action.data.preferences.dashboard.presets.push("employeeCategories")
      return action.data.preferences.dashboard &&
        Array.isArray(action.data.preferences.dashboard.presets)
        ? action.data.preferences.dashboard.presets
        : state;

    default:
      return state;
  }
};

const preferences = combineReducers({
  presets,
});

export default preferences;
