import axios from "axios";
import { serverConfig } from "../config";
import { correctResults, getFiltersString } from "utils/helpers";
import FileSaver from "file-saver";
const apiUrl = serverConfig.apiUrl;

const projectService = {
  getAllProjects: (
    token,
    tenantUuid,
    key = "title",
    limit = 25,
    page = 1,
    filtersString = {},
    isArchive = "False",
    sortType = "sort",
    { isExport = "", columns = "", columnHeaders = "" } = {},
    version = 0
  ) => {
    filtersString =
      typeof filtersString === "object"
        ? getFiltersString(filtersString)
        : filtersString;
    let headers = { headers: { Authorization: `JWT ${token}`, version } };
    if (isExport && (isExport == "True" || isExport == "False"))
      headers.responseType = "blob";

    return axios.get(
      `${apiUrl}/api/jobs/job-with-archived/${tenantUuid}/?` +
      `${sortType}=${key}&is_archived=${isArchive}${limit != 0 ? `&page_size=${limit}` : ""
      }&page=${page}${filtersString}` +
      `${isExport ? `&is_excel_export=${isExport}` : ""}` +
      `${columns ? `&excel_properties=${columns}` : ""}` +
      `${columnHeaders ? `&excel_column_headers=${columnHeaders}` : ""}`,
      headers
    );
  },
  getProjectsSingle: (token, tenantUuid, filtersString) => {
    return axios.get(
      `${apiUrl}/api/jobs/job-with-archived/${tenantUuid}/?&is_archived=False${filtersString ? filtersString : ""
      }`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  getOnlyProject: (token, tenantUuid, projectUuid) => {
    return axios.get(`${apiUrl}/api/jobs/job/${tenantUuid}/${projectUuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getJobsPerProject: (
    token,
    tenantUuid,
    projectUuid,
    date,
    isFull,
    page,
    limit
  ) => {
    const formatedDate = date.format("DD-MM-YYYY");
    return axios.get(
      `${apiUrl}/api/jobs/sub-job/${tenantUuid}/${projectUuid}/?sort=start_date&is_archived=False&start_date=${formatedDate}${isFull ? `&custom_filter__is_full=${isFull}` : ""
      }&page=${page}&page_size=${limit}`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  createProject: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/jobs/job-json/${tenantUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getProject: (token, tenantUuid, projectUuid) => {
    return axios
      .get(`${apiUrl}/api/jobs/job/${tenantUuid}/${projectUuid}/`, {
        headers: { Authorization: `JWT ${token}` },
      })
      .then((response) => {
        if (Array.isArray(response.data.results)) {
          response.data.results = response.data.results[0];
        }

        return response;
      });
  },
  setProject: (token, tenantUuid, projectUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/job/${tenantUuid}/${projectUuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  setCoordinatorProject: (token, managerUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/users/coordinator/${managerUuid}/projects/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  getQuiz: (token, projectUuid) => {
    return axios.get(`${apiUrl}/api/jobs/${projectUuid}/quiz/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  setBriefingDoc: (token, projectUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/${projectUuid}/briefing-doc/`,
      payload,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  setArchiveStatusProjects: (token, tenantUuid, data, action) => {
    return Promise.all(
      data.map((item) => {
        return axios.put(
          `${apiUrl}/api/jobs/job/${tenantUuid}/${item.uuid}/`,
          {
            is_archived: action === "archive" ? "True" : "False",
          },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );
      })
    ).then((results) => correctResults(results));
  },
  createNewQuiz: (token, projectUuid, questions) => {
    return axios.post(
      `${apiUrl}/api/jobs/${projectUuid}/quiz-json/`,
      questions,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getProjectJobs: (token, tenantUuid, projectUuid, filters) => {
    return axios.get(
      `${apiUrl}/api/jobs/sub-job/${tenantUuid}/${projectUuid}/${filters ? filters : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getMediaProject: (
    token,
    tenantUuid,
    projectUuid,
    page = 1,
    size = 5,
    filters = {}
  ) => {
    const filtersString =
      typeof filters === "object" ? getFiltersString(filters) : filters;

    return axios.get(
      `${apiUrl}/api/jobs/project-shifts-images/${projectUuid}/?desc_sort=start_date&is_archived=False&page=${page}&page_size=${size}${filtersString}`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  updateProjectImages: (token, projectUuid, images) => {
    return axios.post(`${apiUrl}/api/jobs/${projectUuid}/image/`, images, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateProjectImage: (token, payload, imageUuid) => {
    return axios.put(`${apiUrl}/api/jobs/image/${imageUuid}/update/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  deleteProjectImage: (token, imageUuid) => {
    return axios.delete(`${apiUrl}/api/jobs/image/${imageUuid}/delete/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getCustomFilters: (token, tenant, projectUuid) => {
    return axios.get(`${apiUrl}/api/jobs/filters/${tenant}/${projectUuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  createCustomFilter: (token, tenant, projectUuid, payload) => {
    return axios.post(
      `${apiUrl}/api/jobs/filters/${tenant}/${projectUuid}/`,
      payload,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  updateCustomFilter: (token, tenant, poolUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/filters-update-delete/${tenant}/${poolUuid}/`,
      payload,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  getFilteredUsers: (
    token,
    tenant,
    projectUuid,
    page = null,
    size = null,
    isArchived = "False",
    filtersString
  ) => {
    return axios.get(
      `${apiUrl}/api/jobs/filtered-users/${tenant}/${projectUuid}/?is_archived=${isArchived}&sort=first_name&is_reg_complete=True&user_extra_info_user__is_verified=True${page ? `&page=${page}` : ""
      }${size ? `&page_size=${size}` : ""}${filtersString ? filtersString : ""
      }`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  getMultipleCandidates: (token, tenant, jobsUuids = []) => {
    return axios.post(
      `${apiUrl}/api/jobs/filtered-users/${tenant}/`,
      {
        job_uuids: jobsUuids,
      },
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  getInfoProject: (token, projectUuid) => {
    return axios.get(`${apiUrl}/api/jobs/project/${projectUuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getDatesWithJobsForProject: (token, projectUuid) => {
    return axios.get(`${apiUrl}/api/jobs/project/dates/${projectUuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getJobsInfoPerDate: (token, projectUuid, date) => {
    return axios.get(`${apiUrl}/api/jobs/jobs/${projectUuid}/${date}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  downloadImages: (
    token,
    tenant,
    projectUuid,
    filename = "project-images",
    filters = {}
  ) => {
    const filtersString =
      typeof filters === "object" ? getFiltersString(filters) : filters;

    return axios
      .get(
        `${apiUrl}/api/jobs/bulk-download/images/${tenant}/?desc_sort=start_date&is_archived=False&invalid_state__in=-auto_canceled,-cancelled,-rejected,-expired&job__job__uuid=${projectUuid}&check_in_check_out_job_application__jobs_check_in_check_out_image_check_in_check_out__isnull=False${filtersString}`,
        { headers: { Authorization: `JWT ${token}` }, responseType: "blob" }
      )
      .then(({ data }) => {
        return FileSaver.saveAs(
          data instanceof Blob ? data : new Blob([data]),
          filename
        );
      });
  },
  downloadAllMedia: (token, tenantUuid, projectUuid, filters, payload) => {
    const filtersString = getFiltersString(filters);
    return axios.post(
      `${apiUrl}/api/jobs/images/download/${tenantUuid}/` +
      `?desc_sort=start_date&is_archived=False` +
      `&invalid_state__in=-auto_canceled,-cancelled,-rejected,-expired` +
      `&job__job__uuid=${projectUuid}` +
      `&check_in_check_out_job_application__jobs_check_in_check_out_image_check_in_check_out__isnull=False${filtersString}`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTableProjects(
    token,
    {
      sortKey = "title",
      pagination = { page: 1, size: 25 },
      isArchive = false,
      filters = {},
      exportOptions = null,
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/projects/?sort=${sortKey}${pagination
        ? `&page=${pagination.page}&page_size=${pagination.size}`
        : ""
      }&is_archived=${isArchive ? "True" : "False"}${filtersString}${exportOptions
        ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
        : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
  exportAllProjects: (
    token,
    key,
    filtersString = {},
    isArchive = "False",
    sortType = "sort",
    payload
  ) => {
    filtersString =
      typeof filtersString === "object"
        ? getFiltersString(filtersString)
        : filtersString;

    return axios.post(
      `${apiUrl}/api/jobs/projects/export/?${key ? `${sortType}=${key}` : ""
      }&is_archived=${isArchive}${filtersString ? filtersString : ""}`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
};

export default projectService;
