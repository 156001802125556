import { SET_SUMMARY } from "redux/types";
import { combineReducers } from "redux";

const totals = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_SUMMARY:
      return action.data.totals || 0;

    default:
      return state;
  }
};

const declarations = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_SUMMARY:
      return action.data.declarations || 0;

    default:
      return state;
  }
};

const noDeclarations = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_SUMMARY:
      return action.data.no_declarations || 0;

    default:
      return state;
  }
};

const corrections = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_SUMMARY:
      return action.data.corrections || 0;

    default:
      return state;
  }
};

const summary = combineReducers({
  totals,
  declarations,
  noDeclarations,
  corrections
});

export default summary;
