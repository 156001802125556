import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
/* Ant Design */
import { Form } from "antd";

/* Style */
if (typeof window === "object") {
  require("./style.scss");
}

const { Item } = Form;

const FormItem = ({
  children = null,
  className = "",
  type = "",
  floatingLabel = false,
  isSelect = false,
  isMultiple = false,
  isNumber = false,
  ...itemProps
}) => {
  const inputRef = useRef(null);
  const [isFloatingLabel, setIsFloatingLabel] = useState(false);
  const onlyNumberReg = /^(0+[1-9][0-9]*|[1-9][0-9]*)$/;

  useEffect(() => {
    showFloatingLabel(children?.props?.value);
  }, []);

  useEffect(() => {
    if (!inputRef.current || !floatingLabel) return;

    const formItemNode = ReactDOM.findDOMNode(inputRef.current);
    const labelNode = formItemNode.querySelector(".ant-form-item-label");
    const selectNode = formItemNode.querySelector(
      ".ant-select-selection-selected-value"
    );
    const inputNode = selectNode ? null : formItemNode.querySelector("input");
    const textAreaNode = selectNode
      ? null
      : formItemNode.querySelector("textarea");

    if (!labelNode) return;

    if (
      (inputNode && inputNode.value !== "" && !isFloatingLabel) ||
      (textAreaNode && textAreaNode.value !== "" && !isFloatingLabel) ||
      (selectNode &&
        !inputNode &&
        !textAreaNode &&
        selectNode.innerText !== "" &&
        !isFloatingLabel)
    ) {
      setIsFloatingLabel(true);
    } else if (
      (inputNode && inputNode.value === "" && isFloatingLabel) ||
      (textAreaNode && textAreaNode.value === "" && isFloatingLabel) ||
      (selectNode &&
        !inputNode &&
        !textAreaNode &&
        selectNode.innerText === "" &&
        isFloatingLabel)
    ) {
      setIsFloatingLabel(false);
    }
  });

  return (
    <Item
      ref={inputRef}
      colon={false}
      className={`form-item-fleks-ui ${floatingLabel ? "floating-label" : ""} ${
        isFloatingLabel ? "is-floating" : ""
      } ${isMultiple ? "is-multiple" : ""} ${className || ""} ${type}`}
      {...itemProps}
    >
      {children}
    </Item>
  );

  /**
   * Updates the value to show or not the floating label taking the children value into account
   * @param {string} value of the input
   */
  function showFloatingLabel(value) {
    if (
      typeof value === "undefined" ||
      value === "" ||
      (Array.isArray(value) && value.length === 0) ||
      (isNumber && !onlyNumberReg.test(value))
    ) {
      setIsFloatingLabel(false);
    } else {
      // Activates the floating label when typing
      setIsFloatingLabel(true);
    }
  }
};

FormItem.propTypes = Item.propTypes;

export default FormItem;
