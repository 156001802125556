import { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
/* Custom Hooks */
import useActions, { useCancellablePromise } from "hooks";
/* Redux Actions */
import {
  setPlannerFilters,
  setShowFilters,
  resetFilters,
  setTemporalPreferences,
  setPreferencesFromTemp,
  setShowSidebar,
  setSelectedJob,
  setSelectedCluster,
  setClusters,
  setShowNewJob,
  setStepOneCompleted,
  setNewJobData,
  setJobOnPlanner,
  setPlannerSidebarReload,
  checkifData,
} from "redux/actions/planner";
/* Services */
import { plannerService } from "services";
/* Utils */
import { makeCancelable } from "utils/helpers";
import { changeTzTo } from "utils/helpers";
import getTypeOfUser from "./getTypeOfUser/getTypeOfUser";

export function useCurrentDate() {
  const userLangCode = useSelector((state) => {
    return state.user.authenticated &&
      state.user.data &&
      state.user.data.current_language
      ? state.user.data.current_language.code
      : "nl";
  });
  const currentDay = useSelector((state) => {
    return state.planner.filters.week;
  });
  const setPlannerFiltersAction = useActions(setPlannerFilters);

  return {
    currentDay: moment(currentDay).locale(userLangCode),
    currentWeek: moment(currentDay).isoWeek(),
    setCurrentDay: (day) => setPlannerFiltersAction({ week: day }),
    isToday: moment().isoWeek() === moment(currentDay).isoWeek(),
  };
}

export function useSearchFilters() {
  const { client, project, city, projectManager } = useSelector((state) => {
    return state.planner.filters;
  });
  const [setPlannerFiltersAction, setResetFiltersAction] = useActions([
    setPlannerFilters,
    resetFilters,
  ]);

  return {
    jobsFilters: {
      client: {
        key: "brand__name",
        value: client,
        prefix: "job__",
        suffix: "__icontains",
        intlName: "Planner.filters.client",
      },
      project: {
        key: "title",
        value: project,
        prefix: "job__",
        suffix: "__icontains",
        intlName: "Planner.filters.project",
      },
      projectManager: {
        key: "project_manager",
        value: projectManager,
        prefix: "job__",
        suffix: "__icontains",
        intlName: "Planner.filters.projectManager",
      },
      city: {
        key: "full_address",
        value: city,
        prefix: "",
        suffix: "__icontains",
        intlName: "Planner.filters.city",
      },
    },
    setSearchFilters: ({ client, project, projectManager, city, week }) =>
      setPlannerFiltersAction({ client, project, projectManager, city, week }),
    resetFilters: (partialFilters) => setResetFiltersAction(partialFilters),
  };
}

export function useShowFilters() {
  const showFilters = useSelector((state) => {
    return state.planner.showFilters;
  });
  const setShowFiltersAction = useActions(setShowFilters);

  return {
    showFilters,
    setShowFilters: (showFilters) => setShowFiltersAction(showFilters),
  };
}

export function useJobsClusters() {
  const user = useSelector((state) => {
    return state.user;
  });
  const new_type_of_user = user.data && getTypeOfUser(user.data.roles[0]);
  const showClusterView = useSelector((state) => {
    return state.planner.preferences.showClusterView;
  });
  const includeFullJobs = useSelector((state) => {
    return state.planner.preferences.includeFullJobs;
  });
  const clusterJobs = useSelector((state) => {
    return state.planner.clusters;
  });
  const setClusterJobs = useActions(setClusters);
  const { currentDay } = useCurrentDate();
  const { jobsFilters } = useSearchFilters();
  const [loading, setLoading] = useState(false);
  let getJobWithRange = null;

  function cancelRequest() {
    if (getJobWithRange) {
      getJobWithRange.cancel();
    }
  }

  useEffect(() => {
    if (!user.authenticated) return;
    const currentDayClone = currentDay.clone();

    cancelRequest();

    getJobWithRange = makeCancelable(
      plannerService.getJobWithRange(
        user.data.token,
        user.data.tenant.uuid,
        false,
        currentDayClone.startOf("isoWeek").format("DD-MM-YYYY"),
        currentDayClone.endOf("isoWeek").format("DD-MM-YYYY"),
        {
          clusters: showClusterView,
          filters: {
            ...jobsFilters,
            fullJobs: {
              key: "is_full",
              value: includeFullJobs ? "" : "False",
            },
          },
          grouped: true,
          includeShared: !new_type_of_user ? true : false,
        }
      )
    );

    setClusterJobs([]);
    setLoading(true);

    getJobWithRange.promise
      .then((clusters) => {
        setLoading(false);
        setClusterJobs(clusters);
      })
      .catch((err) => {
        if (err.isCanceled) return;

        setLoading(false);
      });

    return () => {
      cancelRequest();
    };
  }, [
    currentDay.isoWeek(),
    user.authenticated,
    jobsFilters.client.value,
    jobsFilters.project.value,
    jobsFilters.projectManager.value,
    jobsFilters.city.value,
  ]);

  return { currentDay, loading, clusterJobs };
}

export function useGetJobsClusters({
  grouped = true,
  includeShared = true,
  includeFullJobs = false,
  initialRequest = true,
  initialClusterJobs = null,
  startDate = null,
  endDate = null,
  isDashboard = false
} = {}) {
  const user = useSelector((state) => {
    return state.user;
  });

  const { currentDay } = useCurrentDate();
  const { jobsFilters } = useSearchFilters();
  const [loading, setLoading] = useState(false);
  const [clusterJobs, setClusterJobs] = useState(initialClusterJobs || []);
  const firstRender = useRef(true);
  let getJobWithRange = null;
  const dispatch = useDispatch();

  function cancelRequest() {
    if (getJobWithRange) {
      getJobWithRange.cancel();
    }
  }

  useEffect(() => {
    if (
      !user.authenticated ||
      !initialRequest ||
      (firstRender.current && !!initialClusterJobs)
    )
      return;

    const currentDayClone = currentDay.clone();

    cancelRequest();

    getJobWithRange = makeCancelable(
      plannerService.getJobWithRange(
        user.data.token,
        user.data.tenant.uuid,
        isDashboard,
        startDate
          ? startDate
          : currentDayClone.startOf("isoWeek").format("DD-MM-YYYY"),
        endDate
          ? endDate
          : currentDayClone.endOf("isoWeek").format("DD-MM-YYYY"),
        {
          grouped: false,
          includeShared,
          clusters: grouped,
          filters: {
            ...jobsFilters,
            fullJobs: {
              key: "is_full",
              value: includeFullJobs ? "" : "False",
            },
          },
        }
      )
    );

    setClusterJobs([]);
    setLoading(true);

    getJobWithRange.promise
      .then((clusters) => {
        if(clusters.length === 0 ){
          dispatch(checkifData(true))
        }else {
          dispatch(checkifData(false))
        }
        setLoading(false);
        setClusterJobs(clusters);
      })
      .catch((err) => {
        if (err.isCanceled) return;

        setLoading(false);
      });
    return () => {
      cancelRequest();
    };
  }, [
    currentDay.isoWeek(),
    user.authenticated,
    jobsFilters.client.value,
    jobsFilters.project.value,
    jobsFilters.city.value,
    grouped,
    includeShared,
  ]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return { currentDay, loading, clusterJobs };
}

export function usePlannerPreferences() {
  const plannerPreferences = useSelector((state) => {
    return state.planner.preferences;
  });

  const [temporalPreferencesAction, setPreferencesAction] = useActions([
    setTemporalPreferences,
    setPreferencesFromTemp,
  ]);

  return {
    plannerPreferences,
    setTemporalPreferences: (preferences) =>
      temporalPreferencesAction(preferences),
    setPreferences: () => setPreferencesAction(),
  };
}

export function useSidebar() {
  const show = useSelector((state) => state.planner.sidebar.show);
  const selectedJob = useSelector((state) => state.planner.sidebar.selectedJob);
  const selectedCluster = useSelector(
    (state) => state.planner.sidebar.selectedCluster
  );
  const reload = useSelector((state) => state.planner.sidebar.reload);
  const [
    setShowSidebarAction,
    setSelectedJobAction,
    setSelectedClusterAction,
    setReload,
  ] = useActions([
    setShowSidebar,
    setSelectedJob,
    setSelectedCluster,
    setPlannerSidebarReload,
  ]);

  return {
    show,
    selectedJob,
    selectedCluster,
    reload,
    setReload,
    hideSidebar: () => setShowSidebarAction(false),
    setSelectedJob: (job, options) => setSelectedJobAction(job, options),
    setSelectedCluster: (cluster) => setSelectedClusterAction(cluster),
  };
}

export function useNewJobFromPlanner({ step = 0, currentDay = null } = {}) {
  const show = useSelector((state) => state.planner.newJob.show);
  const jobInfo = useSelector((state) => state.planner.newJob.jobInfo);
  const stepOneCompleted = useSelector(
    (state) => state.planner.newJob.stepOneCompleted
  );
  const [disableCreateButton, setDisableCreateButton] = useState(true);
  const [
    setShowNewJobAction,
    setStepOneCompletedAction,
    setNewJobDataAction,
  ] = useActions([setShowNewJob, setStepOneCompleted, setNewJobData]);

  useEffect(() => {
    if (!jobInfo.date && currentDay) {
      jobInfo.date = currentDay;
    }
  }, []);

  useEffect(() => {
    setDisableCreateButton(
      !(
        jobInfo.jobCategory &&
        jobInfo.startTime &&
        jobInfo.endTime &&
        jobInfo.available &&
        jobInfo.workflow
      )
    );
  }, [jobInfo]);

  /**
   * Sets the data to be stored in redux
   * @param {string} field
   * @param {any} value
   */
  function setNewJobDataField(field, value) {
    if (step === 1) {
      switch (field) {
        /**
         * Handles the onSelectClient event
         */
        case "client":
          const clearProject =
            jobInfo.client && jobInfo.client.uuid !== value.uuid
              ? { project: null }
              : {};
          setNewJobDataAction({
            client: value,
            ...clearProject,
          });
          break;

        /**
         * Handles the onSelectProject event
         */
        case "project":
          const clearStepTwoData =
            jobInfo.project && jobInfo.project.uuid !== value.uuid
              ? {
                  standardJob: null,
                  jobCategory: null,
                  pools: null,
                  street: null,
                  houseNumber: null,
                  additionalInfo: null,
                  postalCode: null,
                  city: null,
                  startTime: null,
                  endTime: null,
                  available: null,
                  workflow: null,
                }
              : {};
          setNewJobDataAction({
            project: value,
            ...clearStepTwoData,
          });
          break;

        default:
          setNewJobDataAction({
            [field]: value,
          });
          break;
      }
    } else if (step === 2) {
      switch (field) {
        default:
          setNewJobDataAction({
            [field]: value,
          });
          break;
      }
    }
  }

  /**
   * Marks the step n as completed
   */
  function setStepAsCompleted() {
    if (step === 1) {
      setStepOneCompletedAction(true);
    } else if (step === 2) {
    }
  }

  /**
   * Sets everything to their initial values
   */
  function setDefaultData() {
    setStepOneCompletedAction(false);
    setNewJobDataAction({
      date: null,
      client: null,
      project: null,
      standardJob: null,
      jobCategory: null,
      pools: null,
      street: null,
      houseNumber: null,
      additionalInfo: null,
      postalCode: null,
      city: null,
      startTime: null,
      endTime: null,
      available: null,
      workflow: null,
    });
    setDisableCreateButton(true);
  }

  function minutesOfDay(moment) {
    return moment.minutes() + moment.hours() * 60;
  }

  /**
   * Sets the payload of the job creation request
   * @param {any} item
   */
  function setBulkData(item) {
    let bulkData = [];

    const { date, startTime, endTime, pools } = item;

    const startEndDate = date.format("DD-MM-YYYY");
    const time = [startTime.format("HH:mm"), endTime.format("HH:mm")];

    const startDateWithTime = changeTzTo(
      "server",
      `${startEndDate} ${time[0]}`,
      "date"
    );

    let endDateWithTime;
    if (startTime && endTime) {
      if (minutesOfDay(endTime) < minutesOfDay(startTime)) {
        let formatStartDate = moment(startTime).format("DD-MM-YYYY");
        let formatEndDate = moment(endTime).format("DD-MM-YYYY");
        if (formatStartDate === formatEndDate) {
          endDateWithTime = changeTzTo(
            "server",
            `${moment(date)
              .add(1, "days")
              .format("DD-MM-YYYY")} ${time[1]}`,
            "date"
          );
        }
      } else {
        endDateWithTime = changeTzTo(
          "server",
          `${startEndDate} ${time[1]}`,
          "date"
        );
      }
    }

    const poolsJoined =
      (item.pools && Array.isArray(item.pools) && item.pools.join()) || "";

    const newItem = {
      pools: poolsJoined,
      job_category: item.jobCategory,
      address: item.street,
      house_number: item.houseNumber,
      house_number_extension: item.additionalInfo,
      zipcode: item.postalCode,
      city: item.city,
      start_date: startDateWithTime,
      end_date: endDateWithTime,
      number_of_employees: parseInt(item.available, 10) || 1,
      sub_job_type: item.workflow,
      applicants: parseInt(item.available, 10) || 1,
      branch_number: "",
      host_number: "",
      place: item.city,
      title: "",
    };

    bulkData.push(newItem);

    return bulkData;
  }

  return {
    show,
    jobInfo,
    stepOneCompleted,
    disableCreateButton,
    setShowNewJob: (show) => setShowNewJobAction(show),
    setDefaultData: () => setDefaultData(),
    setNewJobData: (field, value) => setNewJobDataField(field, value),
    setStepAsCompleted: () => setStepAsCompleted(),
    adjustInfo: () => setStepOneCompletedAction(false),
    getRequestPayload: (item) => setBulkData(item),
  };
}

export function useSetJobOnPlanner() {
  const setClusterJobsAction = useActions(setJobOnPlanner);

  return { setJobOnPlanner: setClusterJobsAction };
}

export function useGetStats(weekDays) {
  const [loading, setLoading] = useState(false);
  const [weekStats, setWeekStats] = useState([]);
  const user = useSelector((state) => state.user.data);

  const getStats = useCallback((weekDays) => {
    if (!(weekDays.length > 0)) return;

    const fromDate = weekDays[0].format("DD-MM-YYYY");
    const toDate = weekDays[weekDays.length - 1].format("DD-MM-YYYY");
    setLoading(true);

    plannerService
      .getStats(user.token, fromDate, toDate)
      .then((weekStats) => {
        setLoading(false);
        setWeekStats(
          weekStats.map((stats) => ({
            day: stats.day,
            filledJobs: stats.total_filled_jobs,
            totalJobs: stats.total_jobs,
            filledPositions: stats.total_filled_spots,
            totalPositions: stats.total_spots,
          }))
        );
        return;
      })
      .catch((err) => {
        if (err.isCanceled) return;

        setLoading(false);

        throw err;
      });
  }, []);

  useEffect(() => {
    getStats(weekDays);
  }, [weekDays]);

  return { weekStats, loading };
}
