import { SET_NEW_JOB } from "redux/types";
import { combineReducers } from "redux";

const date = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.date !== "undefined" ? action.data.date : state;

    default:
      return state;
  }
};

const client = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.client !== "undefined"
        ? action.data.client
        : state;

    default:
      return state;
  }
};

const project = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.project !== "undefined"
        ? action.data.project
        : state;

    default:
      return state;
  }
};

const standardJob = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.standardJob !== "undefined"
        ? action.data.standardJob
        : state;

    default:
      return state;
  }
};

const jobCategory = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.jobCategory !== "undefined"
        ? action.data.jobCategory
        : state;

    default:
      return state;
  }
};

const pools = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.pools !== "undefined"
        ? action.data.pools
        : state;

    default:
      return state;
  }
};

const street = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.street !== "undefined"
        ? action.data.street
        : state;

    default:
      return state;
  }
};

const houseNumber = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.houseNumber !== "undefined"
        ? action.data.houseNumber
        : state;

    default:
      return state;
  }
};

const additionalInfo = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.additionalInfo !== "undefined"
        ? action.data.additionalInfo
        : state;

    default:
      return state;
  }
};

const postalCode = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.postalCode !== "undefined"
        ? action.data.postalCode
        : state;

    default:
      return state;
  }
};

const city = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.city !== "undefined" ? action.data.city : state;

    default:
      return state;
  }
};

const startTime = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.startTime !== "undefined"
        ? action.data.startTime
        : state;

    default:
      return state;
  }
};

const endTime = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.endTime !== "undefined"
        ? action.data.endTime
        : state;

    default:
      return state;
  }
};

const available = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.available !== "undefined"
        ? action.data.available
        : state;

    default:
      return state;
  }
};

const workflow = (state = null, action = {}) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return typeof action.data.workflow !== "undefined"
        ? action.data.workflow
        : state;

    default:
      return state;
  }
};

const jobInfo = combineReducers({
  date,
  client,
  project,
  standardJob,
  jobCategory,
  pools,
  street,
  houseNumber,
  additionalInfo,
  postalCode,
  city,
  startTime,
  endTime,
  available,
  workflow
});

export default jobInfo;
