import {
  SET_PERIOD,
  SET_INCLUDE_CORRECTIONS,
  SET_RATECARD,
  SET_EXPORT_PAYLOAD,
} from "redux/types";
import { combineReducers } from "redux";
import summary from "./summary";

const startDate = (state = null, action = {}) => {
  switch (action.type) {
    case SET_PERIOD:
      return action.data.start_date;

    default:
      return state;
  }
};

const endDate = (state = null, action = {}) => {
  switch (action.type) {
    case SET_PERIOD:
      return action.data.end_date;

    default:
      return state;
  }
};

const filters = (state = null, action = {}) => {
  switch (action.type) {
    case SET_PERIOD:
      return action.data.filters;

    default:
      return state;
  }
};

const includeCorrections = (state = false, action = {}) => {
  switch (action.type) {
    case SET_INCLUDE_CORRECTIONS:
      return action.data;

    default:
      return state;
  }
};

const ratecard = (state = null, action = {}) => {
  switch (action.type) {
    case SET_RATECARD:
      return action.data;

    default:
      return state;
  }
};

const exportPayload = (state = null, action = {}) => {
  switch (action.type) {
    case SET_EXPORT_PAYLOAD:
      return action.data;
    default:
      return state;
  }
};

const runSalary = combineReducers({
  startDate,
  endDate,
  filters,
  summary,
  includeCorrections,
  ratecard,
  exportPayload
});

export default runSalary;
