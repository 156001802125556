import React from "react";
/* Ant Design */
import { Row } from "antd";
/* Components */
import Layout from "./Layout";
import Page from "utils/pages/Page";
/* Assets */
// @ts-ignore
import cacti404 from "assets/images/404_cacti.svg";

/* Styles */
if (typeof window === "object") {
  require("./style.scss");
}

function Page404({}) {
  return (
    <Page title="Fleks Works | 404">
      <Layout>
        <Row className="page-404-info">
          <p className="inputBorder margin-bottom-10">Error Code: 404</p>
          <h1 className="inputBorder avenir-next-medium margin-bottom-30">
            Oh oh, dat had niet moeten gebeuren.
          </h1>
          <p className="inputBorder margin-bottom-30">
            Helaas kunnen wij niet vinden waar jij naar zocht.
          </p>
          <a href="/dashboard" className="primaryUI">
            Ga terug naar het Fleks dashboard
          </a>
        </Row>
        <Row className="page-404-image" style={{ maxWidth: 370 }}>
          <img
            src={cacti404}
            alt="cacti-404"
            style={{ maxWidth: 370, width: "100%" }}
          />
        </Row>
      </Layout>
    </Page>
  );
}

export default Page404;
