import { useState } from "react";
import { useSelector } from "react-redux";
/* Custom hooks */
import { useActions } from "hooks";
/* Redux Actions */
import { setSchedulerPreferences } from "redux/actions/scheduler";
import { saveUserPreferences } from "redux/actions/user";

export function useSchedulerPreferences() {
  const schedulerPreferences = useSelector((state) => {
    return state.scheduler.preferences;
  });
  const [temporal, setTemporal] = useState(schedulerPreferences);
  const currentCardPreferences = {
    ...schedulerPreferences.card,
    ...temporal.card,
  };
  const cardCheckedValues = Object.keys(currentCardPreferences).reduce(
    (acc, key) => (currentCardPreferences[key] ? acc.concat(key) : acc),
    []
  );

  const [setPreferencesAction, saveUserPreferencesAction] = useActions([
    setSchedulerPreferences,
    saveUserPreferences,
  ]);

  return {
    schedulerPreferences,
    temporal,
    cardCheckedValues,
    handleChangeCardCheckedValues,
    setTemporalPreferences: addToTemporal,
    setPreferences: () => {
      setPreferencesAction(temporal);
      saveUserPreferencesAction();
    },
  };

  /**
   * @param {import("antd/lib/checkbox/Group").CheckboxValueType[]} newValues
   */
  function handleChangeCardCheckedValues(newValues) {
    const possibleValues = Object.keys(schedulerPreferences.card);

    const newCardValues = possibleValues.reduce((acc, key) => {
      acc[key] = newValues.includes(key) ? true : false;

      return acc;
    }, {});

    addToTemporal({ card: newCardValues });
  }

  /**
   *
   * @param {{
   *  card?: {
   *    showHours?: boolean;
   *    showCategory?: boolean;
   *    showCity?: boolean;
   *    showClient?: boolean;
   *    showProject?: boolean;
   *  };
   *  sortBy?: string;
   * }} temporal
   */
  function addToTemporal({ card, ...rest }) {
    setTemporal({
      ...temporal,
      ...rest,
      card: {
        ...temporal.card,
        ...card,
      },
    });
  }
}
