import axios from "axios";
import moment from "moment";
import { serverConfig } from "../config";
/* Utils */
import { correctResults, getFiltersString } from "utils/helpers";
import hourService from "services/hour";
const apiUrl = serverConfig.apiUrl;

const tenantService = {
  getSummary: (token, tenantUuid, startDate, endDate, options = {}) => {
    const { filters = {} } = options;
    const BASE_URL = `${apiUrl}/api/jobs/declaration-info/${tenantUuid}/${startDate}/${endDate}/?correction=True${getFiltersString(
      filters
    )}`;

    return axios
      .get(BASE_URL, {
        headers: { Authorization: `JWT ${token}` },
      })
      .then((response) => {
        const totals = response.data.results.total;
        const declarations = response.data.results.complete;
        const no_declarations = response.data.results.incomplete;
        const corrections = response.data.results.corrections;

        return {
          totals,
          declarations,
          no_declarations,
          corrections,
        };
      });
  },
  exportDeclarations: (
    token,
    tenantUuid,
    startDate,
    endDate,
    columns,
    columnHeaders,
    { filters = {}, corrections = true } = {}
  ) => {
    return axios
      .get(
        `${apiUrl}/api/jobs/application-list/all/${tenantUuid}/?is_archived=False&is_approved=True&is_exported=False${
          typeof filters === "object" ? getFiltersString(filters) : filters
        }&is_excel_export=True&excel_properties=${columns}&excel_column_headers=${columnHeaders}${
          !corrections ? `&start_date__gte=${startDate}` : ""
        }&start_date__lte=${endDate}&tz=${moment().format("Z")}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => response.data);
  },
  exportDeclarationsWithRatecard: (
    token,
    startDate,
    endDate,
    ratecard,
    template,
    { filters = {} } = {}
  ) => {
    return axios
      .post(
        `${apiUrl}/api/jobs/salary-run/export/?sort=start_date&is_archived=False${
          typeof filters === "object" ? getFiltersString(filters) : filters
        }&active_status__status_type__in=-job_applied&start_date__gte=${startDate}&start_date__lte=${endDate}&is_payed_out=True`,
        { ratecard, template },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => response.data);
  },
  exportDeclarationsFromHours: (
    token,
    type,
    payload,
    { filters = {} } = {},
    tableType
  ) => {
    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }
    const date = new Date();
    const endDate = [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
    let url = "";
    if(tableType === "allHours"){
      url =  `${apiUrl}/api/jobs/salary-run/export/all/?sort=start_date&is_archived=False${
        typeof filters === "object" ? getFiltersString(filters) : filters
      }&active_status__status_type__in=-job_applied&is_approved=${
        type === "accepted" ||
        type === "processed" ||
        type === "pending" ||
        type === "invoiced"
          ? "True"
          : "False"
      }&is_payed_out=True&${
        type === "initialCheck" || type === "pending"
          ? "is_invoiced=False"
          : type === "invoiced"
          ? "is_invoiced=True"
          : type === "accepted" || type === "open"
          ? "is_exported=False"
          : "is_exported=True"
      }&end_date__lte=${endDate}`
    }
    if(tableType === "singles"){
      url =  `${apiUrl}/api/jobs/salary-run/export/?sort=start_date&is_archived=False${
        typeof filters === "object" ? getFiltersString(filters) : filters
      }&active_status__status_type__in=-job_applied&is_approved=${
        type === "accepted" ||
        type === "processed" ||
        type === "pending" ||
        type === "invoiced"
          ? "True"
          : "False"
      }&is_payed_out=True&${
        type === "initialCheck" || type === "pending"
          ? "is_invoiced=False"
          : type === "invoiced"
          ? "is_invoiced=True"
          : type === "accepted" || type === "open"
          ? "is_exported=False"
          : "is_exported=True"
      }&end_date__lte=${endDate}`
    }
    return axios
      .post(url,
        payload,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => response.data);
  },
  exportAllDeclarationsWithRatecards: (
    token,
    startDate,
    endDate,
    ratecard,
    template,
    { filters = {} } = {}
  ) => {
    if (filters["invalid_state"]) {
      delete filters["invalid_state"];
    }
    return axios
      .post(
        `${apiUrl}/api/jobs/salary-run/export/all/?sort=start_date&is_archived=False${
          typeof filters === "object" ? getFiltersString(filters) : filters
        }&active_status__status_type__in=-job_applied&start_date__gte=${startDate}&start_date__lte=${endDate}&is_payed_out=True`,
        { ratecard, template },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => response.data);
  },
  exportTotals: (
    token,
    tenantUuid,
    startDate,
    endDate,
    columns,
    columnHeaders,
    { filters = {}, corrections = true, excludedArchived = true } = {}
  ) => {
    return axios
      .get(
        `${apiUrl}/api/jobs/job-application/employee/${tenantUuid}/${
          corrections ? "01-01-1900" : startDate
        }/${endDate}/?${
          excludedArchived ? "is_archived=False" : ""
        }&job_application__is_approved=True&job_application__is_exported=False${
          typeof filters === "object"
            ? getFiltersString(filters, { prefix: "job_application" })
            : filters
        }&is_excel_export=True&excel_properties=${columns}&excel_column_headers=${columnHeaders}&job_application__is_payed_out=True`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => response.data);
  },
  exportEmployees: (
    token,
    tenantUuid,
    startDate,
    endDate,
    columns,
    columnHeaders,
    { filters = {}, newEmployees = true, wages = false } = {}
  ) => {
    return axios
      .get(
        `${apiUrl}/api/users/ambassadors/${tenantUuid}/?is_archived=False${
          typeof filters === "object" ? getFiltersString(filters) : filters
        }${
          newEmployees
            ? `&first_jap_start_date__gte=${startDate}&first_jap_start_date__lte=${endDate}`
            : ""
        }${
          wages
            ? `&user_extra_info_user__wage_tax_rebate_date__gte=${startDate}&user_extra_info_user__wage_tax_rebate_date__lte=${endDate}`
            : ""
        }&is_excel_export=True&excel_properties=${columns}&excel_column_headers=${columnHeaders}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => response.data);
  },
  sendToAccepted: (
    token,
    tenantUuid,
    startDate,
    endDate,
    { filters = {}, corrections = true } = {}
  ) => {
    return axios
      .get(
        `${apiUrl}/api/jobs/declarations/?is_archived=False&is_approved=False&is_exported=False&is_payed_out=True${
          typeof filters === "object" ? getFiltersString(filters) : filters
        }${
          !corrections ? `&start_date__gte=${startDate}` : ""
        }&start_date__lte=${endDate}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        const hourUuidList = response.data.results.map((hour) => hour.uuid);

        return hourService
          .setApproveStatus(token, {
            uuid: hourUuidList,
            is_approved: "True",
            no_response: true,
          })
          .then((response) => {
            return {
              japs: hourUuidList.length,
              changed_japs: response.data.results.changed_japs,
            };
          });
      });
  },
  sendToProcessed: (
    token,
    tenantUuid,
    startDate,
    endDate,
    { filters = {}, corrections = true } = {}
  ) => {
    return axios
      .get(
        `${apiUrl}/api/jobs/application-list/all/${tenantUuid}/?invalid_state__isnull=True&is_archived=False&is_payed_out=True&is_approved=True&is_exported=False${
          typeof filters === "object" ? getFiltersString(filters) : filters
        }${
          !corrections ? `&start_date__gte=${startDate}` : ""
        }&start_date__lte=${endDate}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        const hourUuidList = response.data.results.map((hour) => hour.uuid);

        hourService.setApproveStatus(token, {
          uuid: hourUuidList,
          is_exported: "True",
          no_response: true,
        });
        return hourUuidList.length;
      });
  },
  sendToInvoiced: (
    token,
    sortKey = "start_date",
    isArchive = "False",
    { filters = {}, corrections = true } = {}
  ) => {
    return axios
      .get(
        `${apiUrl}/api/jobs/applications/?sort=${sortKey}&is_archived=${isArchive}${
          typeof filters === "object" ? getFiltersString(filters) : filters
        }`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        const hourUuidList = response.data.results.map((hour) => hour.uuid);

        hourService.setApproveStatus(token, {
          uuid: hourUuidList,
          is_invoiced: "True",
          no_response: true,
        });
        return hourUuidList.length;
      });
  },
};

export default tenantService;
