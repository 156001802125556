/* eslint-disable */ 
const googleMapsService = {
  getDistance: (origins, destinations, mode = "DRIVING") => {
    const DistanceService = new google.maps.DistanceMatrixService();

    return new Promise((resolve, reject) => {
      DistanceService.getDistanceMatrix(
        {
          origins: origins,
          destinations: destinations,
          travelMode: mode
        },
        (response, status) => {
          resolve(
            response.rows.map(
              (row, i) =>
                row.elements[i].status === "OK"
                  ? {
                      status: "200",
                      travel_distance:
                        (row.elements[i].distance.value / 1000) * 2,
                      travel_time: (row.elements[i].duration.value / 3600) * 2
                    }
                  : {
                      status: "400"
                    }
            )
          );
        }
      );
    });
  }
};

export default googleMapsService;
