import axios from "axios";
import { serverConfig } from "../config";
const apiUrl = serverConfig.apiUrl;

const sharedEmployeeServices = {
  getSharedEmployee: (token, employeeUuid) => {
    return axios.get(`${apiUrl}/api/shared/employee/list/${employeeUuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  createSharedEmployee: (token, payload = {}) => {
    return axios.post(`${apiUrl}/api/shared/employee/create/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateSharedEmployee: (token, sharedEmployeeUuid, payload = {}) => {
    return axios.put(
      `${apiUrl}/api/shared/employee/update/${sharedEmployeeUuid}/`,
      payload,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  createSharedEmployees: (token, payload = {}) => {
    return axios.post(`${apiUrl}/api/shared/employee/bulk/create/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
};

export default sharedEmployeeServices;
