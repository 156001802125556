import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { tenantService } from "services";

const TENANT_FEATURE_CONTEXTS = {
  DESIGNS: "designs_feature_flags",
  SHARED_EMPLOYEES: "shared_employee_feature_flags",
};

const FeatureFlagsContext = React.createContext({
  TENANT_FEATURE_CONTEXTS,
  featureFlags: {},
  isEnabled: ({ context = "", featureName = "" }) =>
    Boolean(context || featureName),
  loadingFeatureFlags: true,
});

export const FeatureFlagsProvider = ({ children }) => {
  const user = useSelector((state) => state.user);
  const [featureFlags, setFeatureFLags] = useState({});
  const [loadingFeatureFlags, setLoadingFeatureFlags] = useState(true);

  /**
   * Get the features flags stored in the Django panel
   */
  const getFeatureFlags = useCallback(() => {
    tenantService
      .getFeatureFlags({ token: user.data.token })
      .then((response) => {
        const listedFeatureFlags = response.data.results;
        ReactDOM.unstable_batchedUpdates(() => {
          setFeatureFLags(listedFeatureFlags);
          setLoadingFeatureFlags(false);
        });
      })
      .catch(() => {
        ReactDOM.unstable_batchedUpdates(() => {
          setFeatureFLags(null);
          setLoadingFeatureFlags(false);
        });
      });
  }, [user]);

  /**
   * Returns if a specific feature is or not enabled
   */
  const isEnabled = useCallback(
    ({ context, featureName }) =>
      featureFlags && featureFlags[context]
        ? !!featureFlags[context][featureName]
        : false,
    [JSON.stringify({ ...featureFlags })]
  );

  useEffect(() => {
    user.data && getFeatureFlags();
  }, [user.authenticated]);

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags,
        isEnabled,
        TENANT_FEATURE_CONTEXTS,
        loadingFeatureFlags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsContext;
