import axios from "axios";
import { serverConfig } from "../config";
import { correctResults } from "utils/helpers";

const apiUrl = serverConfig.apiUrl;

const getFormDataDocument = document => {
  const formData = new FormData();

  formData.append("name", document.name);
  formData.append("description", document.description);
  if (document.file) {
    formData.append("doc_file", new Blob([document.file]), document.file.name);
  }

  return formData;
};

const documentService = {
  getTenantDocuments: (token, tenantUuid) => {
    return axios.get(
      `${apiUrl}/api/commons/document/${tenantUuid}/?is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  createDocuments: (token, tenantUuid, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.post(
          `${apiUrl}/api/commons/document/${tenantUuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  updateDocuments: (token, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.put(
          `${apiUrl}/api/commons/document/update/${document.uuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  getClientDocuments: (token, projectUuid) => {
    return axios.get(`${apiUrl}/api/users/company-document/${projectUuid}/`, {
      headers: { Authorization: `JWT ${token}` }
    });
  },
  createClientDocuments: (token, clientUuid, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.post(
          `${apiUrl}/api/users/company-document/${clientUuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  updateClientDocuments: (token, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.put(
          `${apiUrl}/api/users/company-document/update/${document.uuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  deleteClientDocuments: (token, documents) => {
    return Promise.all(
      documents.map(document => {
        return axios.delete(
          `${apiUrl}/api/users/company-document/delete/${document.uuid}/`,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  getJobDocuments: (token, jobUuid) => {
    return axios.get(`${apiUrl}/api/jobs/job-document/${jobUuid}/`, {
      headers: { Authorization: `JWT ${token}` }
    });
  },
  createJobDocuments: (token, jobUuid, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.post(
          `${apiUrl}/api/jobs/job-document/${jobUuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  updateJobDocuments: (token, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.put(
          `${apiUrl}/api/jobs/job-document/update/${document.uuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  deleteJobDocuments: (token, documents) => {
    return Promise.all(
      documents.map(document => {
        return axios.delete(
          `${apiUrl}/api/jobs/job-document/delete/${document.uuid}/`,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  getEmployeeDocuments: (token, employeeUuid) => {
    return axios.get(`${apiUrl}/api/users/employee-document/${employeeUuid}/`, {
      headers: { Authorization: `JWT ${token}` }
    });
  },
  createEmployeeDocuments: (token, employeeUuid, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.post(
          `${apiUrl}/api/users/employee-document/${employeeUuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  updateEmployeeDocuments: (token, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.put(
          `${apiUrl}/api/users/employee-document/update/${document.uuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  deleteEmployeeDocuments: (token, documents) => {
    return Promise.all(
      documents.map(document => {
        return axios.delete(
          `${apiUrl}/api/users/employee-document/delete/${document.uuid}/`,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  getProjectDocuments: (token, projectuuid) => {
    return axios.get(`${apiUrl}/api/jobs/job-document/${projectuuid}/`, {
      headers: { Authorization: `JWT ${token}` }
    });
  },
  createProjectDocuments: (token, projectuuid, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.post(
          `${apiUrl}/api/jobs/job-document/${projectuuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  updateProjectDocuments: (token, documents) => {
    return Promise.all(
      documents.map(document => {
        const formData = getFormDataDocument(document);

        return axios.put(
          `${apiUrl}/api/jobs/job-document/update/${document.uuid}/`,
          formData,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  deleteProjectDocuments: (token, documents) => {
    return Promise.all(
      documents.map(document => {
        return axios.delete(
          `${apiUrl}/api/jobs/job-document/delete/${document.uuid}/`,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
      })
    ).then(responses => correctResults(responses));
  },
  deleteSettingsDocuments: (token, id) => {
   return axios.delete(
          `${apiUrl}/api/commons/document/delete/${id}/`,
          {
            headers: { Authorization: `JWT ${token}` }
          }
        );
  },
};

export default documentService;
