import axios from "axios";
import { serverConfig } from "../config";
const apiUrl = serverConfig.apiUrl;

const categoryService = {
  getJobCategory: (
    token,
    tenantUuid,
    { requestConfig } = { requestConfig: {} }
  ) => {
    return axios.get(`${apiUrl}/api/jobs/category/${tenantUuid}/?is_archived=False`, {
      ...requestConfig,
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getLanguages: (token, tenantUuid, { requestConfig } = { requestConfig: {} }) => {
    return axios.get(`${apiUrl}/api/commons/language/${tenantUuid}/`, {
      ...requestConfig,
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getStringValues: (token, tenantUuid) => {
    return axios.get(`${apiUrl}/api/cms/string-value/${tenantUuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  createCategory: (token, tenantUuid, payload) => {
    return axios.post(
      `${apiUrl}/api/jobs/category-create/${tenantUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateCategory: (token, categoryUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/category-update/${categoryUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
};

export default categoryService;
