import { combineReducers } from "redux";
import { INIT_THEME } from "../types";

function replaceChar(str) {
  return `${str}`.replace("#", "");
}

const primaryColor = (state = null, action = {}) => {
  switch (action.type) {
    case INIT_THEME:
      return replaceChar(action.tenant.dashboard_primary_color);

    default:
      return state;
  }
};

const realPrimaryColor = (state = null, action = {}) => {
  switch (action.type) {
    case INIT_THEME:
      return replaceChar(action.tenant.primary_color);

    default:
      return state;
  }
};

const actionColor = (state = null, action = {}) => {
  switch (action.type) {
    case INIT_THEME:
      return replaceChar(action.tenant.dashboard_action_color);

    default:
      return state;
  }
};

const realActionColor = (state = null, action = {}) => {
  switch (action.type) {
    case INIT_THEME:
      return replaceChar(action.tenant.action_color);

    default:
      return state;
  }
};

const primaryBgColor = (state = null, action = {}) => {
  switch (action.type) {
    case INIT_THEME:
      return replaceChar(action.tenant.dashboard_primary_color);

    default:
      return state;
  }
};

const sectionTitleColor = (state = null, action = {}) => {
  switch (action.type) {
    case INIT_THEME:
      return replaceChar(action.tenant.dashboard_action_color);

    default:
      return state;
  }
};

const labelColor = (state = null, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};

const logo = (state = null, action = {}) => {
  switch (action.type) {
    case INIT_THEME:
      return (
        action.tenant.dashboard_logo_view_link || action.tenant.logo_view_link
      );

    default:
      return state;
  }
};

const smallLogo = (state = null, action = {}) => {
  switch (action.type) {
    case INIT_THEME:
      return action.tenant.small_dashboard_logo_view_link;

    default:
      return state;
  }
};
const tenantSlug = (state = null, action = {}) => {
  switch (action.type) {
    case INIT_THEME:
      return action.tenant.slug;

    default:
      return state;
  }
};

const theme = combineReducers({
  primaryColor,
  realPrimaryColor,
  primaryBgColor,
  sectionTitleColor,
  labelColor,
  actionColor,
  realActionColor,
  logo,
  smallLogo,
  tenantSlug
});

export default theme;
