export default {
  label: "de",
  antdPath: require("antd/lib/locale-provider/de_DE"),
  reactIntlPath: require("react-intl/locale-data/de"),
  messages: {
    "User.profile": "Mein Profil",
    "User.addName": "Namen hinzufügen",
    "Tenant.organization": "Meine Organisation",
    "Tenant.organization.name": "Name der Organisation",
    "NewDashboard.welcomeToFleks": "Willkommen {name}!",
    "NewDashboard.getToKnowThePlatform": "Beginnen Sie sofort mit der Planung.",
    "NewDashboard.newJob": "Neuer<br/>Dienst",
    "NewDashboard.createANewJob": "Neuen Dienst erstellen",
    "NewDashboard.newApplications": "Neuanmeldungen",
    "NewDashboard.newApplicationsDescription":
      "Reagieren Sie auf die Kandidaten!",
    "NewDashboard.newDeclarations": "Neue Zeiterfassung",
    "NewDashboard.newInvoices": "Neue Rechnungen",
    "NewDashboard.newDeclarationsDescription":
      "Überprufen Sie der Arbeitsstunden",
    "NewDashboard.openJobs": "Offene Dienste",
    "NewDashboard.openJobsDescription": "Füllen Sie die offenen Stellen aus!",
    "NewDashboard.goToPlanningProgress": "Planungsfortschritt anschauen",
    "NewDashboard.planningProgress.title": "Der Planungsfortschritt",
    "NewDashboard.planningProgress.totalPositions": "Gesamtzahl der Stellen",
    "NewDashboard.planningProgress.fromDate": "Startdatum",
    "NewDashboard.planningProgress.toDate": "Enddatum",
    "NewDashboard.planningProgress.progress":
      "Intern besetzt / Extern besetzt / Offene Stellen",
    "NewDashboard.planningProgress.startEndDate": "Start- und Enddatum",
    "NewDashboard.toTheOldDashboard": "Zurück zum Aktions-Bord",
    "NewDashboard.toTheNewDashboard": "Zum Widget-Bord",
    "Dashboard.welcome": "Willkommen",
    "Dashboard.hours-today": "Dienste von heute",
    "Dashboard.hours-today.placeholder": "Mitarbeitende bei der Arbeit heute",
    "Dashboard.reserved-hours": "Heutige Reserven",
    "Dashboard.reserved-hours.placeholder":
      "Die Mitarbeitenden, die heute in Reserve sind",
    "Dashboard.projects-progress": "Projektfortschritt",
    "Dashboard.projects-progress.placeholder": "Offene & ausgefüllte Dienste",
    "Dashboard.contracts": "Verträge",
    "Dashboard.contracts.placeholder": "Mitarbeitende ohne Vertrag seit",
    "Dashboard.contracts.placeholder.upcomingToExpire":
      "Mitarbeitende mit auslaufendem Vertrag",
    "Dashboard.contracts.placeholder.noSigned":
      "Mitarbeitende mit Vertragsvorschlag, der noch unterschrieben werden muss",
    "Dashboard.tax-rebates": "Änderungen des Lohnabsetzbetrags",
    "Dashboard.tax-rebates.placeholder":
      "Mitarbeitende mit einer Änderung des Lohnabsetzbetrags",
    "Dashboard.did-not-check-in":
      "Die heutigen Einplanungen: Zu spät angemeldet",
    "Dashboard.did-not-check-in.placeholder":
      "Die Mitarbeitenden, die sich zu spät angemeldet haben",
    "Dashboard.too-late": "Die heutigen Einplanungen: Zu spät vor Ort",
    "Dashboard.too-late.placeholder": "Zu spät am Standort des Dienstes",
    "Dashboard.tdl-tables": "Die heutigen Einplanungen",
    "Dashboard.contracts-table": "Verträge",
    "Dashboard.times": "Start-Endzeit",
    "Dashboard.contractName": "Vertragsname",
    "Dashboard.taxRebate-active": "Lohnabsetzbetrag aktiv",
    "Dashboard.taxRebate-noActive": "Lohnabsetzbetrag nicht aktiv",
    "Dashboard.days": "Enddatum",
    "Dashboard.hr-tna": "Anzahl Mitarbeitende",
    "Dashboard.hr-maa": "Aktive Mitarbeitende",
    "Dashboard.hr-nrtm": "Neue Mitarbeitende",
    "Dashboard.hr-wa": "Bewerber",
    "Dashboard.hr-wap": "Eingestellt",
    "Dashboard.map.next-two-hours": "Dienste in den nächsten zwei Stunden",
    "Dashboard.map.jobs-today": "Heutigen Dienste auf der Karte",
    "Dashboard.map.loaded": "Einplanungen mit Anzahl der Dienste geladen:",
    "Dashboard.map.client": "Auftraggeber",
    "Dashboard.map.info-hours": "Eingeplante Mitarbeitende in dem Dienst",
    "Dashboard.map.info-hours.check_in1": "hat sich angemeldet bei",
    "Dashboard.map.info-hours.check_in2": "vor Ort um",
    "Dashboard.map.info-hours.diff": "Lücke",
    "Dashboard.map.info-hours.late": "Minuten zu spät!",
    "Dashboard.map.info-hours.show": "Planungsdetails anzeigen",
    "Dashboard.map": "Dienste von heute",
    "Dashboard.absent": "Abwesend",
    "Dashboard.absent.noShow": "Nichterscheinen",
    "Dashboard.absent.calledOff": "Krank",
    "Dashboard.taxRebate": "Lohnsteuer",
    "Dashboard.absent.noShow.subtext":
      "Dienste, bei denen der Mitarbeitende zwischendurch nicht erschienen ist",
    "Dashboard.absent.calledOff.subtext":
      "Dienste, bei denen der Mitarbeitende sich zwischendurch krankgemeldet hat",
    "Dashboard.temporaryQualifications": "Tijdelijke kwalificaties",
    "Dashboard.temporaryQualifications.qualification": "Kwalificatie",
    "Dashboard.temporaryQualifications.expired": "Abgelaufen",
    "Dashboard.temporaryQualifications.comingToExpire": "Absteigend",
    "Dashboard.temporaryQualifications.onGoing": "Laufend",
    "Dashboard.temporaryQualifications.endDate": "Endtermin",
    "Dashboard.temporaryQualifications.description": "Bekijk hier de verlopen, aflopende en lopende tijdelijke kwalificaties",
    "Presets.select-presets": "Ihre Module",
    "Presets.select-hr": "Ihre Statistiken",
    "Presets.reset": "Entfernen Sie Ihre Module",
    "LoginPage.emailUnregistered":
      "Diese E-Mail-Adresse stimmt nicht mit unserer Datenbank überein. Bitte versuchen Sie es erneut oder",
    "LoginPage.emailInvalid":
      "Die eingegebene E-Mail-Adresse ist ungültig. Bitte versuchen Sie es erneut!",
    "LoginPage.usernameUnregistered":
      "Der Benutzername wurde nicht gefunden, bitte versuchen Sie es erneut",
    "LoginPage.usernameInvalid":
      "Der Benutzername wurde nicht gefunden, bitte versuchen Sie es erneut",
    "LoginPage.requestHelp": "Hilfe anfordern.",
    "LoginPage.next": "Fortfahren",
    "LoginPage.login": "Anmelden",
    "LoginPage.mediamarktCheckbox":
      "Hiermit stimme ich den Allgemeinen Nutzungsbedingungen und dem Verhaltenskodex der Veranstalter von MediaMarkt zu.",
    "LoginPage.passwordPlaceholder": "Geben Sie Ihr Passwort ein...",
    "LoginPage.passwordInvalid":
      "Die Kombination aus Benutzername und Passwort ist falsch. Bitte versuchen Sie es erneut oder",
    "LoginPage.resetPassword": "Neues Passwort",
    "LoginPage.resetPassword.description":
      "Geben Sie ein neues Passwort ein. Das Passwort muss mindestens 8 Zeichen lang sein.",
    "LoginPage.resetPassword.success": "Erfolgreich abgeschlossen!",
    "LoginPage.resetPassword.success.description":
      "Sie haben ein neues Passwort festgelegt.",
    "LoginPage.resetPassword.save": "Neues Passwort speichern",
    "LoginPage.resetPassword.noValidCode": "Der Code ist nicht mehr gültig",
    "LoginPage.resetPassword.noValidCode.description":
      "Der Link ist nicht mehr gültig oder wurde bereits verwendet",
    "LoginPage.rememberMe": "Speichern",
    "LoginPage.confirmation":
      "Klicken Sie in der E-Mail auf %slink%e und verwenden Sie den Reset-Code, um ein %neues Passwort% festzulegen.",
    "LoginPage.gotMail": "Sie haben eine E-Mail erhalten!",
    "LoginPage.validRobot": "Sie sind kein Roboter",
    "LoginPage.error":
      "Es ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal",
    "LoginPage.password": "Passwort",
    "LoginPage.password.new": "Neues Passwort",
    "LoginPage.password.confirm": "Passwort bestätigen",
    "LoginPage.password.notEqual": "Die Passwörter stimmen nicht überein",
    "LoginPage.username": "Benutzername",
    "LoginPage.newConnection":
      "Uns ist aufgefallen, dass Sie eine Verbindungsanfrage erhalten haben. Bitte melden Sie sich an, um weitere Informationen zur Anfrage zu erhalten.",
    "LoginPage.welcome": "Willkommen zurück!",
    "LoginPage.welcome.description":
      "Login mit Ihren Zugangsdaten (nur für Arbeitgeber).",
    "LoginPage.forgotYourPassword": "Passwort vergessen?",
    "LoginPage.createAccount":
      "Haben Sie noch keinen Benutzerkonto? Melden Sie sich direkt an!",
    "LoginPage.createAccount.create": "Benutzerkonto erstellen",
    "LoginPage.createAccount.fillIn": "Fehlende Daten eingeben",
    "LoginPage.createAccount.userAlreadyTaken":
      "Dieser Benutzername ist bereits vergeben",
    "LoginPage.createAccount.companyNameAlreadyTaken":
      "Dieser Name der Organisation ist bereits vergeben",
    "LoginPage.createAccount.goBack": "Zurückgehen",
    "LoginPage.createAccount.invalidUsername":
      "Der Benutzername darf keine Leerzeichen enthalten.",
    "LoginPage.createAccount.success":
      "Ihr Benutzerkonto wurde erstellt! Sie erhalten innerhalb weniger Minuten eine Bestätigungsmail mit Ihren Angaben und können sofort starten mit der Planung & dem Austausch von Diensten mit Ihrem Partner!",
    "LoginPage.createAccount.error":
      "Sie können kein Konto mit diesen Informationen erstellen <a href='https://www.fleks.works/contact' target='_blank'>Kontaktieren Sie uns</a>, damit wir gemeinsam eine Lösung suchen können",
    "LoginPage.createAccount.generalAgreements":
      "Durch Klicken auf 'Benutzerkonto erstellen' erklären Sie sich mit den<a href='https://assets.website-files.com/5e6fe86cf07ada83b89b10a2/5f8011c7581ce4bd9999831f_Algemene%20Voorwaarden.pdf' target='_blank'>Allgemeinen Geschäftsbedingungen & dem Auftragsverarbeitungsvertrag einverstanden</a>",
    "LoginPage.credentialsDoNotMatch":
      "Ihr Benutzername und Ihr Passwort stimmen nicht überein.",
    "LoginPage.forgot": "Passwort zurücksetzen",
    "LoginPage.forgot.description":
      "Geben Sie Ihren Benutzernamen ein, mit dem Sie sich normalerweise anmelden. Achtung! Bei diesem Feld wird zwischen Groß- und Kleinschreibung unterschieden.",
    "LoginPage.forgot.sendResetEmail": "E-Mail zum Zurücksetzen senden",
    "LoginPage.forgot.checkYourEmail": "E-Mail überprüfen",
    "LoginPage.forgot.checkYourEmail.caption":
      "Wir senden einen Link zum Zurücksetzen des Passworts an die mit diesem Benutzernamen verknüpfte E-Mail-Adresse. Überprüfen Sie Ihren Posteingang.",
    "LoginPage.forgot.didNotReceive":
      "Keine E-Mail erhalten? Kontaktieren Sie dann bitte Ihren Arbeitgeber.",
    "LoginPage.forgot.itIsNotAValidUsername":
      "Der Benutzername wurde nicht erkannt.",
    "LoginPage.2fa.sendCode.error":
      "Der eingegebene Code muss 6 Ziffern enthalten.",
    "LoginPage.2fa.descriptionChoose.subTitle":
      "Ihre Organisation hat die zwei-Faktor-Authentifizierung aktiviert. Wählen Sie die Authentifizierungsmethode aus, mit der Sie den Code zur Anmeldung erhalten möchten.",
    "LoginPage.2fa.checkboxSelect.methodGoogle":
      "Google Authentifizierung verwenden",
    "LoginPage.2fa.checkboxSelect.methodEmail":
      "Die mit meinem Benutzerkonto verknüpfte E-Mail-Adresse verwenden",
    "LoginPage.2fa.descriptionChoose.title": "Methode auswählen",
    "LoginPage.2fa.title.authenticationGoogle": "Google Authentifizierung",
    "LoginPage.2fa.subtitle.authenticationGoogle":
      "Verwenden Sie die Google Authentifizierungs-App, um das Bild unten zu scannen.",
    "LoginPage.2fa.secondSubtitle.authenticationGoogle":
      "Nach dem Scannen des Bildes zeigt die App einen 6-stelligen Code an, den Sie unten eingeben können.",
    "LoginPage.2fa.thirdSubtitle.authenticationGoogle":
      "Geben Sie den 6-stelligen Code ein, der unten in der Google Authentifizierungs-App angezeigt wird.",
    "LoginPage.2fa.title.authenticationEmail": "E-Mail-Authentifizierung",
    "LoginPage.2fa.subtitle.authenticationEmail":
      "Es wurde eine E-Mail an die mit Ihrem Benutzerkonto verknüpfte E-Mail-Adresse gesendet. Geben Sie den 6-stelligen Code ein, der in der E-Mail unten angezeigt wird.",
    "LoginPage.2fa.enterCode": "Code eingeben",
    "LoginPage.2fa.enterCode.error":
      "Der eingegebene Code ist ungültig. Versuchen Sie es erneut.",
    "LoginPage.2fa.titleSetMethod.successMessage.google":
      "Die Authentifizierungsmethode wurde Ihrem Benutzerkonto hinzugefügt!",
    "LoginPage.2fa.descriptionSetMethod.successMessage.google":
      "Von nun an werden Sie bei jeder Anmeldung gebeten, einen Verifizierungscode einzugeben. Sie können über die Google Authentifizierungs-Anwendung auf diesen Code zugreifen. Beachten Sie bitte, dass Sie, falls Sie Ihr Gerät verlieren oder eine neue Authentifizierungsmethode einrichten sollten, sich an einen Administrator wenden müssen, um fortzufahren.",
    "LoginPage.2fa.titleSetMethod.successMessage.email":
      "Die Authentifizierungsmethode wurde Ihrem Benutzerkonto hinzugefügt!",
    "LoginPage.2fa.descriptionSetMethod.successMessage.email":
      "Von nun an werden Sie bei jeder Anmeldung gebeten, einen Verifizierungscode einzugeben. Bitte überprüfen Sie Ihren Posteingang, in der E-Mail finden Sie den Code, der mit Ihrem Konto verknüpft ist. Beachten Sie bitte, dass Sie, falls Sie Ihr Gerät verlieren oder eine neue Authentifizierungsmethode einrichten sollten, sich an einen Administrator wenden müssen, um fortzufahren.",
    "LoginPage.loginEmployee": "Als Mitarbeiter einloggen",
    "PasswordRecoveryPage.resetPassword": "Passwort zurücksetzen",
    "PasswordRecoveryPage.passwordPlaceholder": "Neues Passwort",
    "PasswordRecoveryPage.rePasswordPlaceholder": "Passwort wiederholen",
    "PasswordRecoveryPage.passwordRequire":
      "Alle Felder sind Pflichtfelder, bitte füllen Sie alle Felder aus",
    "PasswordRecoveryPage.differentPasswords":
      "Passwörter müssen übereinstimmen, bitte erneut eingeben",
    "PasswordRecoveryPage.confirmation":
      "Ihr Passwort wurde geändert %b Sie kehren zur Anmeldeseite zurück",
    "PasswordRecoveryPage.success": "Viel Erfolg!",
    "PasswordRecoveryPage.missCode":
      "Füllen Sie diese Seite über den an Ihre E-Mail gesendeten Link erneut aus",
    "Database.settings.setup": " Tabellen einrichten",
    "Database.settings.description":
      "Dies sind Ihre Tabelleneinstellungen. Passen Sie die folgenden Informationen an, sodass die Einstellungen Ihren Vorlieben entsprechen.",
    "Database.settings.columnsPerTable": "Spalten pro Tabelle",
    "Database.settings.columnsPerTable.description":
      "Fleks unterstützt 10 Spalten pro Tabelle, wobei 'Name' und 'Status' nicht veränderbar sind. Das bedeutet, dass Sie selbst maximal 8 Spalten hinzufügen und anpassen können.",
    "Database.settings.columnsPerTable.whatTableEdit":
      "Welche Tabelle möchten Sie ändern?",
    "Database.settings.rowsPerTable": "Zeilen ändern pro Tabelle",
    "Database.settings.rowsPerTable.description":
      "Hier können Sie die Anzahl der pro Seite angezeigten Zeilen ändern.",
    "Database.settings.rowsPerTable.numberOfRows": "Anzahl der Reihen",
    "Database.settings.rowsPerTable.numberOfRows.maxError":
      "Sie können nicht mehr als 100 festlegen",
    "Database.settings.rowsPerTable.adjustRows": "Zeilen ändern",
    "Database.settings.title": "Tabelleneinstellungen",
    "Database.settings.saveTableViews": "Tabellenansichten speichern",
    "Database.settings.saveTableViews.description": "Möchten Sie Ihre aktuellen Tabellenansichten als Standard festlegen? Dadurch werden Ihre aktuelle Registerkarte und Sortiermethode für Kunden-, Projekt-, Auftrags-, Schicht-, Mitarbeiter- und Stundenregistrierungstabellen gespeichert.",
    "Database.settings.saveTableViews.saveDefault": "Als Standard speichern",
    "Database.customization.clients": "Tabelle der Auftraggeber",
    "Database.customization.projects": "Projekttabelle",
    "Database.customization.jobs": "Diensttabelle",
    "Database.customization.jobRequests": "Tabelle der Dienstanfragen",
    "Database.customization.newemployers": "Tabelle der neuen Arbeitgeber",
    "Database.customization.employers": "Tabelle der Arbeitgeber",
    "Database.customization.employees": "Tabelle der Mitarbeitenden",
    "Database.customization.incomingEmployees":
      "Tabelle der externen Mitarbeitenden",
    "Database.customization.shifts": "Tabelle der Einplanungen",
    "Database.customization.applicants": "Tabelle der Bewerber",
    "Database.customization.newFreelancers": "Tabelle der neuen Freiberufler",
    "Database.customization.freelancers": "Tabelle der Freiberufler",
    "Database.customization.singles":
      "Tabelle über Zeiterfassung pro Einplanung",
    "Database.customization.totals": "Zeiterfassung pro Mitarbeitenden",
    "Database.customization.singlesInternal":
      "Tabelle der Intern pro Einplanung",
    "Database.customization.totalsInternal":
      "Tabelle der Intern pro Mitarbeiter",
    "Database.customization.externals": "Tabelle der externen Stunden",
    "Database.customization.allHours": "Alle Stunden",
    "Database.customization.invoices": "Tabelle über Rechnungen",
    "Database.customization.description":
      "Ändern Sie die Reihenfolge oder ersetzen Sie die Spalten durch Ziehen der Felder.",
    "Database.customization.saveChanges": "Speichern",
    "Database.tables.haveSelected":
      "Sie haben alle Felder der Seite ausgewählt.",
    "Database.tables.AllListHaveBeenSelected":
      "Alle {total} Felder sind ausgewählt.",
    "Database.tables.selectAllList":
      "Alle {total} Felder auf der Registerkarte '{tab}' auswählen",
    "Clients.archived": "{items} Auftraggeber archiviert",
    "Clients.name": "Name",
    "Clients.id": "Ausweisnummer",
    "Clients.city": "Stadt",
    "Clients.projects": "Projekte",
    "Clients.date": "Datum",
    "Clients.clients": "Auftraggeber",
    "Clients.newClient": "Auftraggeber oder Standort hinzufügen",
    "Clients.all": "Alle",
    "Clients.filter": "Filter",
    "Clients.create-client": "Auftraggeber",
    "Clients.create-department": "Standort erstellen",
    "Clients.upload": "Hochladen",
    "Clients.street-name": "Straßenname",
    "Clients.zip-code": "PLZ",
    "Clients.house-number": "Hausnummer",
    "Clients.house-number-ext": "Zusatz",
    "Clients.require-text": "Dieses Feld ist ein Pflichtfeld",
    "Clients.fill-in": "Bitte eingeben",
    "Clients.general": "Allgemeines",
    "Clients.logo": "Logo",
    "Clients.contact": "Ansprechpartner",
    "Clients.Single.totalProjects": "Gesamtzahl der Projekte",
    "Clients.Single.ongoingProjects": "Laufende Projekte",
    "Clients.Single.finishedProjects": "Frühere Projekte",
    "Clients.Single.project": "Projekte",
    "Clients.Details.general": "Allgemeines",
    "Clients.Details.clientId": "Kundennummer",
    "Clients.Details.id": "Kundennummer",
    "Clients.Details.name": "Name",
    "Clients.Details.streetName": "Straßenname",
    "Clients.Details.number": "Hausnummer",
    "Clients.Details.ext": "Ext",
    "Clients.Details.stateRegion": "Stadt",
    "Clients.Details.zipCode": "PLZ",
    "Clients.Details.documents": "Dokumente",
    "Clients.Details.documents.add": "Ein Dokument hinzufügen",
    "Clients.Details.documents.upload": "Dokument hochladen",
    "Clients.Details.documents.dragText": "Datei hier hochladen.",
    "Clients.Details.documents.edit": "Dokument bearbeiten",
    "Clients.Details.documents.delete": "Dokument löschen",
    "Clients.Details.documents.delete.confirmation":
      "Sind Sie sicher, dass Sie das Dokument löschen möchten?",
    "Clients.Details.documents.deleteFile.confirmation":
      "Sind Sie sicher, dass Sie die Datei löschen möchten?",
    "Clients.Details.Parent": "Auftraggeber",
    "Clients.Details.description.placeholder":
      "Fügen Sie eine Beschreibung der Funktionen hinzu, die der Mitarbeitende für diesen Auftraggeber ausführen wird, dies ist für den Mitarbeitenden in seiner Stellenbeschreibung sichtbar.",
    "Clients.Details.description": "Beschreibung (optional)",
    "Clients.title": "Auftraggeber",
    "Clients.contactPerson": "Ansprechpartner des Auftraggebers",
    "Clients.addDepartment": "Standort hinzufügen",
    "Clients.parent": "Auftraggeber",
    "Clients.type": "Typ",
    "Clients.type.checkbox": "Dies ist einer der Standorte des Auftraggebers",
    "Clients.create": "Auftraggeber erstellen",
    "Clients.Details.templates": "Standardprojekt",
    "Clients.templates.no-data":
      "Fügen Sie dem Auftraggeber ein Vorlagenprojekt hinzu. Wenn Sie später ein Projekt für diesen Auftraggeber erstellen, können Sie die Vorlage auswählen.",
    "Clients.emptyState.subTable": "Keine Standorte hinzugefügt",
    "Clients.emptyState.title": "Es wurden keine Auftraggeber hinzugefügt",
    "Clients.emptyState.description":
      "Klicken Sie auf die violette Schaltfläche in der oberen rechten Ecke, um einen Auftraggeber oder Standort hinzuzufügen.",
    "Clients.noClients": "Keine Auftraggeber gefunden",
    "Clients.active": "Aktiv",
    "Clients.inactive": "Inaktiv",
    "Clients.numberOfProjects": "Anzahl der Projekte",
    "Clients.customNumber": "Ausweisnummer",
    "Clients.dateCreated": "Erstellungsdatum",
    "Clients.standardProjects.new": "Standardprojekt hinzufügen",
    "Clients.archive.confirmation": `Sie sind dabei,
      {Items, Plural,
        one {# Auftraggeber}
        other {# Auftraggeber}    
      } zu {archive, select,
        true {zu-archivieren}
        false {archivieren}
      }, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Clients.chamberOfCommerce": "IHK-Nummer",
    "Clients.adjustGeneralInfo": "Allgemeine Informationen bearbeiten",
    "Clients.adjustContactPersonInfo": "Information Ansprechpartner bearbeiten",
    "Clients.saveGeneralInfo": "Speichern",
    "Templates.errors.delete": "Die Vorlage kann nicht gelöscht werden",
    "Templates.errors.edit":
      "Es wurden keine Vorlagen gefunden, die geändert werden können",
    "Templates.success.delete": "Projektvorlage wurde gelöscht",
    "Templates.info.template": "[Text]",
    "Templates.success.create": "Vorlage wurde erstellt",
    "Templates.success.edit": "Vorlage wurde geändert",
    "Templates.info.loading": "Vorlageneinstellungen laden",
    "Templates.edit": "Vorlage bearbeiten",
    "Templates.delete": "Standardprojekt löschen",
    "Templates.create": "Vorlage erstellen",
    "Templates.export.project": "Projekte",
    "Templates.export.job": "Dienste",
    "Templates.export.employee": "Mitarbeitende",
    "Templates.export.declaration_internal": "Intern pro Einplanung",
    "Templates.export.declaration_total": "Intern insgesamt",
    "Templates.export.shift_internal": "Interne Einplanungen",
    "Templates.export.shift_all": "Interne & externe Einplanungen",
    "Templates.export.billable_hours": "Extern pro Einplanung",
    "Templates.export.internal_per_employee": "Intern pro Mitarbeiter",
    "Templates.exportCreation": "Neue Exportvorlage",
    "Templates.exportEdit": "Vorlage bearbeiten",
    "Templates.exportAvailableFor": "Vorlage verfügbar für",
    "Templates.roles": "Rollen",
    "Templates.adminOnly":
      "Dieser Bericht ist nur für Admin-Benutzer verfügbar",
    "Export.templates.delete": "Vorlage wurde erfolgreich gelöscht",
    "Export.templates.create": "Vorlage wurde erfolgreich erstellt",
    "Export.templates.edit": "Vorlage wurde erfolgreich geändert",
    "Templates.model.project_title": "Projekttitel",
    "Templates.userTypes.app_brand_user_group": "Manager",
    "Templates.userTypes.dashboard_ftp_user_group": "Admin",
    "Templates.userTypes.planner_user_group": "Planer",
    "Templates.shiftCard.internal": "Nur intern",
    "Templates.shiftCard.internalExternal": "Interne & externe Mitarbeitende",
    "Templates.model.project_custom_number": "Projektnummer",
    "Templates.model.project_manager": "Manager",
    "Templates.model.project_full_address": "Vollständige Adresse",
    "Templates.model.project_start_date": "Startdatum des Dienstes",
    "Templates.model.project_end_date": "Enddatum des Dienstes",
    "Templates.model.project_number_of_employees": "Anzahl Mitarbeitende",
    "Templates.model.project_accepted_users": "Anzahl geplant",
    "Templates.model.project_category_names": "Funktionskategorien",
    "Templates.model.project_total_jobs": "Gesamtzahl der Dienste",
    "Templates.model.project_pending_jobs": "Anzahl ausstehender Dienste",
    "Templates.model.project_brand_names": "Auftraggeber",
    "Templates.model.employee_custom_number": "Personalnummer",
    "Templates.model.employee_full_name": "Vollständiger Name",
    "Templates.model.job_project_number": "Projektnummer",
    "Templates.model.job_category_name": "Funktionskategorie",
    "Templates.model.job_category_sales_price": "Funktionsverkaufspreis",
    "Templates.model.sign_off_time": "Digitale Signatur aus der Manager-App",
    "Templates.model.employee_sign_off_time": "Digitale Unterschrift aus der Mitarbeiter-App",
    "Templates.model.job_full_address": "Vollständige Adresse",
    "Templates.model.job_tenant_name": "Dienstinhaber",
    "Templates.model.employee_current_tenant_name": "Registriert bei",
    "Templates.model.jap_start_date": "Startdatum und Uhrzeit",
    "Templates.model.jap_end_date": "Enddatum und Uhrzeit",
    "Templates.model.jap_custom_number": "Planungs-ID",
    "Templates.model.billable_billed_hours": "Abzurechnende Stunden",
    "Templates.model.billable_travel_time": "Fahrtzeit",
    "Templates.model.billable_travel_distance": "Gefahrene Kilometer",
    "Templates.model.billable_transportation_cost":
      "Kosten für öffentliche Verkehrsmittel",
    "Templates.model.billable_other_cost": "Sonstige Kosten",
    "Templates.model.employee_birth_date": "Geburtsdatum",
    "Templates.model.employee_full_address": "Anschrift des Mitarbeitenden",
    "Templates.model.employee_phone_number": "Telefonnummer",
    "Templates.model.employee_email": "E-Mail-Adresse",
    "Templates.model.employee_bank_acc_number": "IBAN",
    "Templates.model.job_start_date": "Startdatum",
    "Templates.model.jap_invalid_state": "Art der Stornierung",
    "Templates.model.contract_name": "Vertragsname",
    "Templates.model.comment_texts": "Anmerkungen",
    "Templates.model.job_created_by": "Hergestellt von",
    "Templates.model.job_date_created": "Gemacht auf",
    "Templates.model.jap_created_by": "Hergestellt von",
    "Templates.model.jap_date_created": "Gemacht auf",
    "Templates.model.employee_created_by": "Hergestellt von",
    "Templates.model.project_created_by": "Hergestellt von",
    "Templates.model.project_date_created": "Gemacht auf",
    "Templates.model.employee_qualifications": "Mitarbeiterqualifikation",
    "Templates.model.job_end_date": "Enddatum",
    "Templates.model.declaration_user_hours_worked":
      "Anzahl der Arbeitsstunden",
    "Templates.model.declaration_compensation_hours": "Extrastunden",
    "Templates.model.declaration_break_compensation": "Pausenstunden",
    "Templates.model.declaration_applicable_travel_time": "Fahrtzeit",
    "Templates.model.declaration_travel_time_rate": "Fahrzeittarif",
    "Templates.model.declaration_travel_time_fee": "Fahrtzeitpauschale",
    "Templates.model.declaration_travel_distance_applicable":
      "Gefahrene Kilometer",
    "Templates.model.declaration_km_driving_cost": "Kilometerpauschale",
    "Templates.model.declaration_transportation_cost":
      "Kosten für öffentliche Verkehrsmittel",
    "Templates.model.declaration_other_cost": "Sonstige Kosten",
    "Templates.model.declaration_gross_salary": "Bruttogehalt",
    "Templates.model.declaration_check_in": "Anmeldezeit",
    "Templates.model.declaration_check_in_on_location":
      "Am Standort eingecheckt",
    "Templates.model.declaration_check_out": "Check-out-Zeit",
    "Templates.model.declaration_approved_at": "Angenommen am",
    "Templates.model.declaration_approved_by_email": "Angenommen von",
    "Templates.model.declaration_exported_at": "Bearbeitet am",
    "Templates.model.declaration_exported_by_email": "Bearbeitet von",
    "Templates.model.jap_active_hourly_rate": "Stundenlohn",
    "Templates.model.declaration_active_fixed_rate": "Fester Tarif",
    "Templates.model.declaration_active_payment_type":
      "Zahlungsart (Festtarif oder Stundensatz)",
    "Templates.model.employee_first_name": "Vorname",
    "Templates.model.employee_initials": "Initialen",
    "Templates.model.employee_last_name": "Nachname",
    "Templates.model.employee_surname_prefix": "Prefix",
    "Templates.model.employee_address": "Straße",
    "Templates.model.employee_city": "Stadt",
    "Templates.model.employee_house_number": "Hausnummer",
    "Templates.model.employee_house_number_ext": "Zusatz",
    "Templates.model.employee_archived_at": "Archiviert am",
    "Templates.model.employee_wage_tax_rebate_status":
      "Status Lohnabsetzbetrag",
    "Templates.model.initial": "Initialen",
    "Templates.model.employee_gender": "Geschlecht",
    "Templates.model.employee_post_code": "PLZ",
    "Templates.model.employee_document_number": "Dokumentnummer",
    "Templates.model.employee_social_number": "Sozialversicherungsnummer",
    "Templates.model.employee_wage_tax_rebate": "Lohnabsetzbetrag",
    "Templates.model.employee_wage_tax_rebate_date":
      "Gutschriftdatum Lohnabsetzbetrag",
    "Templates.model.employee_date_created": "Erstellt am",
    "Templates.model.employee_last_invitation_at": "Eingeladen am",
    "Templates.model.employee_basic_reg_completed_at":
      "Basisregistrierung abgeschlossen am",
    "Templates.model.employee_last_login_at": "Zuletzt angemeldet am",
    "Templates.model.employee_last_contacted_at": "Letzter Kontakt am",
    "Templates.model.employee_last_jap_date":
      "Letzter abgeschlossener Dienst am",
    "Templates.model.contract_start_date": "Vertragsbeginn",
    "Templates.model.contract_end_date": "Vertragsende",
    "Templates.model.job_custom_number": "Dienstnummer",
    "Templates.model.job_start_time": "Startzeit",
    "Templates.model.job_end_time": "Ende",
    "Templates.model.job_worked_hours": "Stundenzahl",
    "Templates.model.job_number_of_employees":
      "Anzahl der benötigten Mitarbeitenden",
    "Templates.model.job_accepted_employees":
      "Anzahl der eingeplanten Mitarbeitenden",
    "Templates.model.job_is_full": "Dienst besetzt (ja/nein)",
    "Templates.model.job_pending_employees":
      "Anzahl der ausstehenden Registrierungen",
    "Templates.model.job_invited_users": "Anzahl der Eingeladenen",
    "Templates.model.job_city": "Stadt",
    "Templates.model.job_address_description": "Adressenbeschreibung",
    "Templates.model.job_zip_code": "Dienst PLZ",
    "Templates.model.job_extra_note": "Interne Notiz",
    "Templates.model.ratecard_date": "Datum",
    "Templates.model.ratecard_month": "Monat",
    "Templates.model.project_brand_custom_number": "Nummer des Auftraggebers",
    "Templates.model.ratecard_type": "Zuschlagskartentyp",
    "Templates.model.job_category_custom_number": "Funktion ID",
    "Templates.model.ratecard_start_time": "Startzeit",
    "Templates.model.ratecard_end_time": "Ende",
    "Templates.model.ratecard_surcharge": "Zuschlag",
    "Templates.model.ratecard_surcharge_percentage": "Zuschlagsprozentsatz",
    "Templates.model.ratecard_number_of_units": "Zahl der Einheiten",
    "Templates.model.employee_shirt_size": "Hemdgröße",
    "Templates.model.employee_shoe_size": "Schuhgröße",
    "Templates.model.employee_license": "Führerschein",
    "Templates.model.employee_car": "Auto",
    "Templates.model.employee_education": "Ausbildung",
    "Templates.model.jap_exception_state_name": "Sonderstatus",
    "Templates.model.jap_exception_state_code": "Sonderstatus ID",
    "Templates.model.jap_start_time": "Startzeit",
    "Templates.model.jap_end_time": "Ende",
    "Templates.model.jap_total_hours": "Stundenzahl",
    "Templates.model.jap_start_date_short": "Startdatum",
    "Templates.model.jap_end_date_short": "Enddatum",
    "Templates.model.free_fields": "Freie Felder",
    "Templates.model.employee_shifts_total_km_driving_cost": "Fahrtkosten",
    "Templates.model.employee_shifts_total_net_costs": "Gesamtkosten",
    "Templates.model.employee_shifts_total_gross_salary":
      "Bruttogehalt (exkl. Fahrtzeitpauschale)",
    "Templates.model.employee_shifts_number_job_applications":
      "Anzahl gearbeiteter",
    "Templates.model.employee_shifts_total_users_hours_worked":
      "Arbeitsstunden",
    "Templates.model.employee_shifts_total_compensation_hours": "Extrastunden",
    "Templates.model.employee_shifts_total_travel_time_fee":
      "Fahrtzeitpauschale",
    "Templates.model.employee_shifts_total_applicable_travel_time": "Fahrtzeit",
    "Templates.model.employee_shifts_total_travel_distance":
      "Gefahrene Kilometer",
    "Templates.model.employee_shifts_total_transportation_cost":
      "Kostenerstattung für öffentliche Verkehrsmittel",
    "Templates.model.employee_shifts_total_other_cost": "Sonstige Kosten",
    "Templates.model.employee_shifts_highest_hourly_rate":
      "Stundenlohn am Ende des Zeitraums",
    "Templates.model.employee_shifts_total_hourly_rate_hours_worked":
      "Stundenzahl mit Stundensatz",
    "Templates.model.employee_shifts_total_reserve_rate_hours_worked":
      "Stundenzahl mit festem Reservetarif",
    "Templates.model.employee_shifts_total_fixed_rate_hours_worked":
      "Stundenzahl mit Festpreis",
    "Templates.model.employee_shifts_total_hourly_gross_salary":
      "Bruttogehalt mit Stundensatz",
    "Templates.model.employee_shifts_total_reserve_gross_salary":
      "Gesamterstattung Reserve",
    "Templates.model.employee_shifts_total_fixed_gross_salary":
      "Gesamterstattung Festpreis",
    "Templates.model.function_description": "Function description",
    "ContactPerson.NewContactPerson": "Ansprechpartner erstellen",
    "ContactPerson.notCreated": "Dieser Ansprechpartner wurde nicht erstellt",
    "ContactPerson.created": "Ansprechpartner wurde erstellt",
    "ContactPerson.create": "Neuen Ansprechpartner erstellen",
    "ContactPerson.userExist": "Die E-Mail-Adresse ist bereits vergeben",
    "ContactPerson.no-data": "Es wurden noch keine Ansprechpartner hinzugefügt",
    "Projects.title": "Projekte",
    "Projects.newProject": "Neues Projekt",
    "Projects.all": "Alle",
    "Projects.running": "Laufend",
    "Projects.upcoming": "Bevorstehende",
    "Projects.past": "Frühere",
    "Projects.name": "Name",
    "Projects.nameTitle": "Titel",
    "Projects.clients": "Auftraggeber",
    "Projects.typeOfWork": "Funktion",
    "Projects.jobs": "Dienste",
    "Projects.pending": "Ausstehende",
    "Projects.projectManager": "Projektleiter",
    "Projects.startDate": "Startdatum",
    "Projects.endDate": "Enddatum",
    "Projects.addEndDate": "Enddatum hinzufügen",
    "Projects.id": "Projektnummer",
    "Projects.missedFunctionDescription":
      "Die Beschreibung darf nicht leer sein",
    "Projects.fixedRate": "Festbetrag",
    "Projects.hourlyRate": "Festbetrag pro Stunde",
    "Projects.ageHourlyRate": "Auszahlung nach Alter",
    "Projects.customAgeRelatedRate": "Nach Alter",
    "Projects.customNumber": "Projektnummer",
    "Projects.contractBased": "Vertraglich",
    "Projects.breakApplicable": "Pause gültig",
    "Projects.travelCost":
      "Geben Sie an, ob die Fahrkosten automatisch je nach Wohnort und Dienststandort der Mitarbeitenden berechnet werden sollen",
    "Projects.publicTransport":
      "Geben Sie an, ob die Kosten für öffentliche Verkehrsmittel geltend gemacht werden können",
    "Projects.publicTransport.true": "Sonstige Kosten können deklariert werden",
    "Projects.publicTransport.false":
      "Kosten für öffentliche Verkehrsmittel können nicht deklariert werden",
    "Projects.hourCorrection": "Stundenkorrektur",
    "Projects.workingHoursUpdateable":
      "Wählen Sie aus, ob ein Mitarbeitender eine Stundenkorrektur beantragen darf",
    "Projects.workingHoursUpdateable.true":
      "Der Mitarbeitende darf kann eine Stundenkorrektur mit Begründung beantragen",
    "Projects.workingHoursUpdateable.false":
      "Ein Mitarbeitender darf keine Stundenkorrektur beantragen",
    "Projects.travelExpenses": "Fahrtkosten",
    "Projects.otherCosts": "Sonstige Kosten",
    "Projects.otherCostsApplicable":
      "Geben Sie an, ob weitere Kosten deklariert werden können",
    "Projects.otherCostsApplicable.true":
      "Sonstige Kosten können deklariert werden",
    "Projects.otherCostsApplicable.false":
      "Sonstige Kosten können nicht deklariert werden",
    "Projects.breakAdjustable":
      "Die Pause muss vom Mitarbeitenden ausgefüllt werden",
    "Projects.travelTimeApplicable":
      "Wählen Sie aus, ob die Fahrzeit eingegeben werden darf",
    "Projects.kmDrivenApplicable":
      "Wählen Sie aus, ob die gefahrenen Kilometer eingegeben werden dürfen",
    "Projects.preCalculatedAndTravelTime.FalseFalse":
      "Die Fahrtzeit wird nicht ausgezahlt",
    "Projects.preCalculatedAndTravelTime.FalseTrue":
      "Die Fahrtzeit kann der Mitarbeitende selbst eingeben, sie wird nicht vorab berechnet",
    "Projects.preCalculatedAndTravelTime.TrueFalse":
      "Die Fahrtzeit wird im Voraus mit Google Maps berechnet, anhand des Dienststandorts und des Wohnorts des Mitarbeitenden. Der Mitarbeitende darf dies nicht selbst ändern",
    "Projects.preCalculatedAndTravelTime.TrueTrue":
      "Die Fahrtzeit wird im Voraus mit Google Maps berechnet, anhand des Dienststandorts und des Wohnorts des Mitarbeitenden. Der Mitarbeitende darf dies selbst noch auf dem Antragsformular anpassen.",
    "Projects.preCalculatedAndKmDriven.FalseFalse":
      "Die gefahrenen Kilometer werden nicht ausbezahlt",
    "Projects.preCalculatedAndKmDriven.FalseTrue":
      "Der Mitarbeitende kann die gefahrenen Kilometer selbst eingeben, sie sind nicht vorab berechnet",
    "Projects.preCalculatedAndKmDriven.TrueFalse":
      "Die gefahrenen Kilometer werden im Voraus mit Google Maps berechnet, anhand des Dienststandorts und des Wohnorts des Mitarbeitenden. Der Mitarbeitende darf dies nicht selbst ändern",
    "Projects.preCalculatedAndKmDriven.TrueTrue":
      "Die gefahrenen Kilometer werden im Voraus mit Google Maps berechnet, anhand des Dienststandorts und des Wohnorts des Mitarbeitenden. Der Mitarbeitende darf dies selbst noch auf dem Antragsformular anpassen.",
    "Projects.questions": "Fragen",
    "Projects.question": "Frage",
    "Projects.newQuestion": "Neue Frage",
    "Projects.addQuestion": "Frage hinzufügen",
    "Projects.question.press-enter":
      "Drücken Sie die Eingabetaste, um Ihre Frage/Antwort zu bestätigen",
    "Projects.answer": "Antwort {Nummer}",
    "Projects.newAnswer": "Neue Antwort",
    "Projects.cancel": "Stornieren",
    "Projects.add-quiz": "Quiz hinzufügen",
    "Projects.payment-method": "Zahlungsmethode",
    "Projects.payment-information": "Zahlungsinformationen",
    "Projects.declaration-form": "Das Antragsformular",
    "Projects.declaration-form-sub":
      "Wählen Sie aus, welche Kosten deklariert werden können und ob eine Stundenkorrektur beantragt werden kann",
    "Projects.hourly-rate": "Festbetrag pro Stunde",
    "Projects.reserve-rate": "Reservebetrag",
    "Projects.briefing-pdf": "Briefing_für_Projekt.pdf",
    "Projects.Single.totalJobs": "Gesamtzahl der Dienste",
    "Projects.Single.openJobs": "Ausstehende Dienste",
    "Projects.Single.filledJobs": "Ausgefüllte Dienste",
    "Projects.Single.newJob": "Neuer Dienst",
    "Projects.Single.published": "Veröffentlicht",
    "Projects.Single.unpublished": "Nicht veröffentlicht",
    "Projects.Single.no-published":
      "Das Projekt konnte nicht veröffentlicht werden",
    "Projects.add-briefing": "Briefing hinzufügen (pdf)",
    "Projects.images": "Fotos",
    "Projects.projectSales": "Sales",
    "Projects.projectCoordinator": "Koordinator",
    "Projects.create": "Projekt erstellen",
    "Projects.createAndPublish": "Erstellen und veröffentlichen",
    "Projects.category": "Funktion",
    "Projects.created-quiz": "Das neue Quiz wurde erstellt",
    "Projects.Details.pool": "Pools",
    "Projects.Details.poolDescription":
      "Die Dienste, die mit diesem Projekt verbunden sind, sind für die Mitarbeitenden sichtbar, die unter die Kriterien der ausgewählten Pools fallen. Möchten Sie die Dienste für alle sichtbar machen? Dann fügen Sie keinen Pool hinzu.<br /> Die Dienste sind jetzt für %fu Mitarbeitende sichtbar. Möchten Sie eine Ausnahme für einen Dienst machen? Dann können Sie einen Pool zu einem Dienst hinzufügen!",
    "Projects.Details.enterBranch": "Funktion auswählen",
    "Projects.Details.enterTraining": "Ausbildung(en) auswählen",
    "Projects.Details.enterQualification": "Qualifikation(en) auswählen",
    "Projects.pool.predefined": "Standardpool",
    "Projects.pool": "Angepasster Projektpool",
    "Projects.pool.predefined.switch": "Angepassten Pool erstellen",
    "Projects.predefinedPools":
      "Wählen Sie einen oder mehrere Standardpools aus",
    "Projects.createProject": "Projekt erstellen",
    "Projects.fillIn": "Ausfüllen",
    "Projects.general": "Allgemeines",
    "Projects.projectName": "Projektname",
    "Projects.jobCategory": "Funktion",
    "Projects.fieldRequired": "Dieses Feld ist ein Pflichtfeld",
    "Projects.select": "Auswählen",
    "Projects.client": "Auftraggeber",
    "Projects.department.2": "Abteilung",
    "Projects.startEndDate": "Startdatum - Enddatum",
    "Projects.responsible": "Projektleiter",
    "Projects.description": "Beschreibung",
    "Projects.characters": "von den 1000 Zeichen",
    "Projects.payment": "Zahlung",
    "Projects.projectSettings": "Projekteinstellungen",
    "Projects.projectSettings.cardAutoScheduled.title":
      "Automatische Planung (ohne Genehmigung)",
    "Projects.projectSettings.cardAutoScheduled.subTitle":
      "Wählen Sie aus, ob die internen & externen Mitarbeitenden erst vom Planer freigegeben werden müssen oder ob sie direkt eingeplant werden können",
    "Projects.projectSettings.cardAutoScheduled.InternalEmployees":
      "Interne Mitarbeitende",
    "Projects.projectSettings.cardAutoScheduled.ExternalEmployees":
      "Externe Mitarbeitende",
    "Projects.projectSettings.cardAutoScheduled.descriptonSwicthInternal":
      "Wählen Sie aus, ob ein interner Mitarbeitender sofort bei der Anmeldung eingeplant werden kann (wer zuerst kommt, mahlt zuerst)",
    "Projects.projectSettings.cardAutoScheduled.descriptonSwicthExternal":
      "Wählen Sie aus, ob ein externer Mitarbeitender von einer Verbindung direkt (ohne Zustimmung des Planers) eingeplant werden kann",
    "Projects.paymentMethod": "Zahlungsmethode",
    "Projects.chooseMethod": "Zahlungsmethode auswählen",
    "Projects.amount": "Betrag",
    "Projects.errorDescription":
      "Die Beschreibung ist zu lang, verwenden Sie bitte weniger Zeichen",
    "Projects.reserveRate": "Reservebetrag",
    "Projects.reserveRateOptional": "Reservebetrag (optional)",
    "Projects.new.details":
      "Dieses Projekt ist für die ausgewählten Pools sichtbar. Um das Projekt für alle sichtbar zu machen, wählen Sie keinen Pool aus",
    "Projects.addPool": "Pool hinzufügen",
    "Projects.Details.location": "Standort (optional)",
    "Projects.Details.location.2": "Standort",
    "Projects.Details.date": "Datum",
    "Projects.location.address": "Straße",
    "Projects.location.city": "Ort",
    "Projects.location.house_number": "Hausnummer",
    "Projects.location.house_number_ext": "Zusatz",
    "Projects.location.zip_code": "PLZ",
    "Projects.projectCreated": "Das Projekt wurde erstellt",
    "Projects.newProjectCreated": "Neues Projekt erstellt",
    "Projects.somethingWrong": "Es ist ein Fehler aufgetreten",
    "Projects.load-error":
      "Beim Laden des Projekts/der Projekte ist ein Fehler aufgetreten",
    "Projects.fieldsRequired":
      "Projektname, Funktion, Auftraggeber, Startdatum - Enddatum, Zahlungsmethode, Betrag und Reservebetrag sind erforderlich",
    "Projects.no-clone": "Das Projekt konnte nicht kopiert werden",
    "Projects.cloning": "Projekt kopieren",
    "Projects.Pools.edit": "Pool anpassen",
    "Projects.Pools.error-load":
      "Beim Laden des geeigneten Mitarbeitenden ist ein Fehler aufgetreten",
    "Projects.Pools.save-edit": "Das Speichern des Pools war erfolgreich!",
    "Projects.Pools.error-edit":
      "Das Speichern des Pools konnte nicht durchgeführt werden:(",
    "Projects.projectNumber": "Projektnummer",
    "Projects.projectNamePlaceholder": "Projektnummer hinzufügen (optional)",
    "Projects.published": "Veröffentlicht",
    "Projects.unpublished": "Nicht veröffentlicht",
    "Projects.filledJobs": "Ausgefüllte Dienste",
    "Projects.pendingJobs": "Bevorstehende Dienste",
    "Projects.filledPositions": "Besetzte Stellen",
    "Projekte.NameTitel": "Titel",
    "Projects.visible": "Sichtbar",
    "Projects.department": "Standort hinzufügen (optional)",
    "Projects.caduced":
      "Das Enddatum des Projekts ist erreicht, das Projekt ist in der App nicht sichtbar",
    "Projects.publishedNoPending":
      "Das Projekt wurde veröffentlicht, aber es gibt keine ausstehenden Dienste. Das Projekt ist für die Mitarbeitenden nicht sichtbar.",
    "Projects.publishedWithPending":
      "Das Projekt wurde veröffentlicht und es stehen den Mitarbeitenden %sjp-Dienste zur Verfügung.",
    "Projects.noPublishedNoPending":
      "Das Projekt wurde NICHT veröffentlicht und es gibt keine ausstehenden Dienste. Das Projekt ist für die Mitarbeitenden nicht sichtbar.",
    "Projects.noPublishedWithPending":
      "Es gibt %sjp ausstehende Dienste, veröffentlichen Sie das Projekt, um die Dienste für die geeigneten Mitarbeitenden sichtbar zu machen.",
    "Projects.type": "Typ",
    "Projects.vacancy": "Dies ist eine Stellenausschreibung",
    "Projects.notVacancy": "Das ist ein Projekt",
    "Projects.vacancy.subtext.true":
      "Sie haben ausgewählt, dass es sich um ein Stellenangebot handelt, geben Sie hier die erforderlichen Wochenstunden pro Mitarbeitenden ein",
    "Projects.vacancy.subtext.false":
      "Sie haben ausgewählt, dass dies KEIN Stellenangebot ist, die Mitarbeitenden melden sich für die Dienste an",
    "Projects.vacancy.hoursPerWeek": "Anzahl der Wochenstunden",
    "Projects.vacancy.numberOfEmployees": "Anzahl Mitarbeitende",
    "Projects.fixed-rate": "Gesamtfestbetrag",
    "Projects.appliedPools": "Angewandte(r) Pool(s)",
    "Projects.notAppliedPools": "Es wurden keine Pools angewendet",
    "Projects.new.selectClient": "1. Auftraggeber/Standort auswählen",
    "Projects.new.fillData": "2. Informationen",
    "Projects.template": "Projektvorlage",
    "Projects.clientTemplate": "Projektvorlage Auftraggeber",
    "Projects.departmentTemplate": "Projektvorlage Standort",
    "Projects.createTemplate":
      "Ein Standardprojekt (Vorlage) erstellen für: [client_name]",
    "Projects.editTemplate": "Projektvorlage anpassen von: [client_name]",
    "Projects.teamInstructionsPdf": "Teamanweisungen",
    "Projects.addTeamInstructions": "Teamanweisungen hinzufügen (pdf)",
    "Projects.breakRules": "Pausenregeln",
    "Projects.breakApplicable.title":
      "Wählen Sie aus, ob der Mitarbeitende seine Pause bezahlt bekommt",
    "Projects.breakApplicable.subtitle":
      "Geben Sie an, ob Pausenkosten deklariert werden können",
    "Projects.breakApplicable.true":
      "Die Pause wird mit der Anzahl der Arbeitsstunden verrechnet",
    "Projects.breakApplicable.false":
      "Auf die Gesamtarbeitszeit wird keine Pause angerechnet",
    "Projects.emptyState.title": "Es wurden noch keine Projekte erstellt",
    "Projects.emptyState.description":
      "Klicken Sie oben rechts auf die violette Schaltfläche und erstellen Sie ein neues Projekt.",
    "Projects.noProjects": "Keine Projekte gefunden",
    "Projects.internalNote": "Interne Notiz",
    "Projects.goToTablePreferences": "Gehen Sie zu den Tabelleneinstellungen",
    "Projects.clientName": "Auftraggeber",
    "Projects.city": "Stadt",
    "Projects.toOldDesign": "Klicken Sie hier für die alte Übersicht?",
    "Projects.archived": "{items} Projekte archiviert",
    "Projects.advancedFilters.close": "Seitenleiste schließen",
    "Projects.advancedFilters.title": "Alle Filter",
    "Projects.archive.confirmation": `Sie sind dabei,
      {Items, Plural,
        one {# Projekt}
        other {# Projekte}    
      } zu {archive, select,
        true {zu-archivieren}
        false {archivieren}
      }, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Projects.adjustLocationInfo": "Standortdetails ändern",
    "Projects.adjustPaymentInfo": "Zahlungseinstellungen ändern",
    "Projects.adjustQuiz": "Quiz anpassen",
    "Projects.adjustEvaluation": "Bewertungslink anpassen",
    "Projects.evaluationGuide.description":
      "Die folgenden Felder können im Bewertungslink ersetzt werden, damit sie vom System automatisch ausgefüllt werden, wenn die Mitarbeitenden von ihrem Handy aus auf die Bewertung zugreifen",
    "Projects.evaluationGuide.fields": `
        - Name des Mitarbeitenden = [employee_full_name]<br/>
        - Personalnummer = [employee_custom_number]<br/>
        - Startdatum = [job_start_date]<br/>
        - Enddatum = [job_end_date]<br/>
        - Startzeit = [job_start_time]<br/>
        - Ende = [job_end_time]<br/>
        - Dienststandort = [job_full_address]<br/>
        - PLZ Dienst = [job_zipcode]<br/>
        - Interne Notiz = [job_extra_note]<br/>
        - Planungsnummer = [custom_number]<br/>
        - Projektname = [project_name]<br/>
        - Funktion = [job_category_name]`,
    "Projects.evaluationGuide.footer": `
        Um dies beim Ausfüllen der Bewertung anzugeben, muss der Text auf der rechten Seite des Links platziert werden, der mit der Information übereinstimmt<br/><br/>
        <b>Beispiel</b> : <em>https://docs.google.com/forms/d/e/1FAIpQLSfh8ReUe8_yOAU4qg5m8_R1jQxm52rIg7E6r3sFb6vTtGpCJw/viewform?entry.1147536406=[employee_full_name]</em>`,
    "Projects.isThisArunningProject": "Ist das ein kontinuierliches Projekt?",
    "Projects.runningProjectDescription": `Bei einem Projekt können Sie ein Start- und Enddatum auswählen, um den Zeitraum anzugeben, in dem das Projekt stattfindet. "Projekte" können auch als "Abteilung" oder "Kostenstelle" interpretiert werden. Wenn dies tatsächlich der Fall ist, können Sie das Enddatum leer lassen. In diesem Fall betrachtet Fleks das Projekt immer als 'Laufend'.`,
    "Projects.creation": "Projekt erstellen",
    "Projects.publishProjectCreation": "Offene Dienste veröffentlichen", 
    "Projects.publishCreation": "Konzeptplanung",
    "Projects.publishProjectDetails": "Offene Dienste veröffentlicht",
    "Projects.publishDetails": "Konzeptplanung",
    "Projects.conceptPlanning" : "Konzeptplanung",
    "Projects.conceptPlanningTrue": "Aktiviert",
    "Projects.conceptPlanningFalse": "Nicht aktiv",
    "Projects.notificationTitle": "Nachricht an die kommenden eingeplanten Mitarbeiter senden",
    "Projects.hoverpublishProjectCreation": "Wenn du offene Dienste zum Projekt hinzufügst, erscheinen sie auf der Seite 'Offene Jobs' in der App, damit geeignete Mitarbeiter sich anmelden können. Du kannst die offenen Dienste später immer noch über die Projektdetailseite veröffentlichen.",
    "Projects.hoverpublishProjectDetails": "Veröffentliche das Projekt, damit die offenen Dienste auf der Seite 'Offene Jobs' sichtbar werden und geeignete Mitarbeiter sich anmelden können.",
    "Projects.hoverPublishCreation": "Wenn die Entwurfplanung aktiviert ist, erhält der Mitarbeiter keine automatischen Benachrichtigungen über die Planung. In der App sind nur das Projekt und das Datum sichtbar. So kannst du die Planung anpassen, ohne dass der Mitarbeiter sofort Benachrichtigungen erhält. Achtung: Vergiss nicht, die Entwurfplanung wieder auszuschalten, sonst könnte es passieren, dass der Mitarbeiter seinen Dienst verpasst.",
    "Projects.hoverPublishDetails": "Aktiviere die Entwurfplanung, um sicherzustellen, dass keine automatischen Nachrichten an die Mitarbeiter gesendet werden. Solange die Entwurfplanung aktiviert ist, sieht der Mitarbeiter in der App nur das Projekt und das Datum des Dienstes. Achtung: Vergiss nicht, die Entwurfplanung wieder auszuschalten, sonst könnte der Mitarbeiter seinen Dienst verpassen.",
    "Projects.notificationMessageConceptPlanningTrue": "Die Konzeptplanung ist aktiviert, bei der Terminierung werden keine automatischen Nachrichten versendet",
    "Projects.notificationMessageConceptPlanningFalse": "Die Konzeptplanung ist deaktiviert, die Leistungsdetails sind für die Mitarbeiter in der App unter 'Meine Jobs' sichtbar",
    "Projects.notificationMessagepublishProjectTrue": "Die offenen Schichten wurden veröffentlicht. Mitarbeiter können sich über die App anmelden. Senden Sie eine Benachrichtigung an die entsprechenden Mitarbeiter, um so schnell wie möglich Anmeldungen zu erhalten.",
    "Projects.notificationMessagepublishProjectFalse": "Das Projekt wurde nicht veröffentlicht, es ist nicht mehr möglich für die Mitarbeiter, sich für offene Dienste anzumelden.",
    "Projects.detailsModal.sidebar.title": "Projektübersicht",
    "Projects.detailsModal.sidebar.fromStartDateToEndDate":
      "{start_date} bis {end_date}",
    "Projects.detailsModal.sidebar.selectDate": "Wählen Sie einen Tag aus:",
    "Projects.detailsModal.content.subtitleOcupation": "Planung",
    "Projects.detailsModal.content.tableColumn.NoName":
      " <span class='avenir-next-italic'>Offene Stelle</span> ",
    "SelectClientList.title": "Auftraggeber suchen",
    "SelectClientList.select": "Auftraggeber auswählen",
    "SelectClientList.selectDepartment": "Standort auswählen",
    "Sidebar.dashboard": "Dashboard",
    "Sidebar.planner": "Planer",
    "Sidebar.scheduler": "Planungsbord",
    "Sidebar.database": "Datenbank",
    "Sidebar.newFreelancers": "Neue Freiberufler",
    "Sidebar.freelancers": "Freiberufler",
    "Sidebar.clients": "Auftraggeber",
    "Sidebar.projects": "Projekte",
    "Sidebar.jobs": "Dienste",
    "Sidebar.jobRequests": "Dienst anfordern",
    "Sidebar.newEmployers": "Neue Arbeitgeber",
    "Sidebar.employers": "Arbeitgeber",
    "Sidebar.employees": "Mitarbeitende",
    "Sidebar.applicants": "Bewerber",
    "Sidebar.hours": "Zeiterfassung",
    "Sidebar.create-project": "Neues Projekt",
    "Sidebar.create-client": "Neuer Auftraggeber",
    "Sidebar.create-quiz": "Quiz erstellen",
    "Sidebar.create-employee": "Neuer Mitarbeitende",
    "Sidebar.shifts": "Einplanungen",
    "Sidebar.singles": "Pro Einplanung",
    "Sidebar.singlesInternal": "Intern pro Einplanung",
    "Sidebar.internals": "Intern",
    "Sidebar.totals": "Pro Mitarbeitenden",
    "Sidebar.totalsInternal": "Intern pro Mitarbeiter",
    "Sidebar.allHours": "Alle Stunden",
    "Sidebar.invoices": "Rechnungen",
    "Sidebar.archive": "Archiv",
    "Sidebar.company": "Firmeninformationen für die App",
    "Sidebar.hr": "Personal & Finanzen",
    "Sidebar.admin": "Admin",
    "Settings.selectExportTemplate": "Exportvorlage auswählen",
    "Settings.selectRateCard": "Preisliste auswählen",
    "ExportModal.surchargeExport": "Aufpreis Export",
    "Settings.page.admin.deactivate": "Deaktivieren",
    "Settings.page.admin.deactivateSuccess": "Der Benutzer wurde deaktiviert",
    "Settings.page.admin.activateSuccess": "Der Benutzer wurde aktiviert",
    "Settings.page.users": "Benutzer",
    "Settings.page.admin.archiveSuccess":
      "Die Workflow-Vorlage wurde archiviert",
    "Clients.timesheets": "Kontinuierliche Aufträge",
    "Clients.timesheets.management": "Zugriff auf kontinuierliche Aufträge",
    "Clients.timesheets.management.description":
      "Kontinuierliche Aufträge sind für Mitarbeitende gedacht, die längerfristig direkt am Arbeitsplatz beim Auftraggeber tätig sind. Der Zeitplan wird direkt zwischen dem Flexworker und dem Auftraggeber besprochen. Der Mitarbeitende kann die vereinbarten Leistungen selbst direkt in der mobilen App erstellen. Da Mitarbeitende zu den Projekten des Auftraggebers hinzugefügt werden können, können diese die zukünftigen vereinbarten Dienste erfassen und zugleich auch die in der Vergangenheit geleisteten Stunden ihrer Dienste registrieren. Die Stunden müssen wie normale Dienste genehmigt werden.",
    "Clients.timesheets.addEmployee": "Mitarbeitenden hinzufügen",
    "Clients.timesheets.addProjects": "Projekte auswählen",
    "Clients.timesheets.editProjects": "Zugriff auf die Projekte anpassen",
    "Clients.timesheets.linkProjects": "Zugriff auf Projekt(e) bestätigen",
    "Sidebar.templates": "Vorlagen",
    "Sidebar.template-project": "Standardprojekte",
    "Sidebar.make-salary-run": "Lohnzettel starten",
    "Sidebar.externalConnections": "Externe Verbindungen",
    "Sidebar.days": "Tage",
    "Sidebar.directPay": "Direktzahlung",
    "Sidebar.directPayDescription": "Direkte Bezahlung per Finqle gewählt",
    "Pictures.viewer": "Bilder anschauen",
    "Employees.creation": "Mitarbeitende/n erstellen",
    "Employees.availability.dateSelector": "Datum oder Zeitraum auswählen",
    "Employees.availability.weekSelector": "Wochentage auswählen",
    "Employees.availability.availabilitySelector": "Verfügbarkeit auswählen",
    "Employees.description": "Ihre Änderung wurde gespeichert",
    "Employees.errorSaveMessage":
      "Ihre Änderungen konnten nicht gespeichert werden.",
    "Employees.create-employee": "Neuer Mitarbeitende",
    "Employees.information": "Informationen",
    "Employees.create-new-employee": "Mitarbeitenden erstellen",
    "Employees.loginCredentials": "Zugangsdaten",
    "Employees.resetEmail": "E-Mail zum Zurücksetzen des Passworts senden",
    "Employees.resetPassword":
      "Anweisungen zum Zurücksetzen des Passworts werden an den Mitarbeitenden gesendet",
    "Employees.sendPassword":
      "E-Mail für eine Anfrage zum Zurücksetzen des Passworts wurde gesendet an: %em",
    "Employees.email": "E-Mail-Adresse",
    "Employees.password": "Passwort",
    "Employees.initials": "Initialen",
    "Employees.first_name": "Vorname",
    "Employees.full_name": "Name",
    "Employees.prefix": "Namenszusatz",
    "Employees.surname": "Nachname",
    "Employees.last_name": "Nachname",
    "Employees.birth_date": "Geburtsdatum",
    "Employees.gender": "Geschlecht",
    "Employees.street": "Straße",
    "Employees.street.2": "Straße",
    "Employees.state": "Stadt",
    "Employees.zip-code": "PLZ",
    "Employees.mobile": "Telefonnummer",
    "Employees.personalNumber": "Personalnummer",
    "Employees.creation.basicDetails": "Ausgangsdaten",
    "Employees.creation.addressDetails": "Adressdaten",
    "Employees.schedule": "Einplanen",
    "Employees.reInvite": "Wieder einladen",
    "Employees.schedule.confirmation": `Sie sind dabei, {Items, Plural,
          one {# Mitarbeitenden}
          other {# Mitarbeitende}    
        } für einen bereits begonnen Dienst einzuplanen, möchten Sie diese Aktion wirklich ausführen?`,
    "Employees.reject": "Ablehnen",
    "Employees.accept": "Akzeptieren",
    "Employees.propose": "Vorschlagen",
    "Employees.propose.confirmation": `Sie sind dabei, {Items, Plural,
          one {# Mitarbeitenden}
          other {# Mitarbeitende}    
        } für einen bereits begonnen Dienst vorzuschlagen, möchten Sie diese Aktion wirklich ausführen?`,
    "Employees.proposeExit": "Vorschlagen und schließen",
    "Employees.scheduleAndPropose": "Einplanen & vorschlagen",
    "Employees.uninvite": "Widerrufen",
    "Employees.cancel": "Stornieren",
    "Employees.distance": "Entfernung",
    "Employees.numberOfEmployees": "Anzahl der Angestellten",
    "Employees.invalidFormat": "Sie haben etwas falsch eingegeben",
    "Employees.onlyText": "Nur Buchstaben möglich",
    "Employees.language": "Sprache",
    "Employees.dutch": "Niederländisch",
    "Employees.english": "Englisch",
    "Employees.weekHours": "Wochenstunden",
    "JobRequests.title": "Dienst anfordern",
    "Jobs.updateJobsSpecifications": "Informationen bearbeiten",
    "Jobs.updateJobsSpecifications.allUpdated":
      "Alle Dienste wurden aktualisiert",
    "Jobs.updateJobsSpecifications.allNotUpdated":
      "Die Dienste konnten nicht angepasst werden",
    "Jobs.updateJobsSpecifications.oneNotUpdated":
      "Ein oder mehrere Items wurden nicht aktualisiert, bitte überprüfen Sie die Dienste",
    "Jobs.updateJobsSpecifications.onePartiallyUpdated":
      "Ein oder mehrere Items wurden teilweise aktualisiert, bitte überprüfen Sie die Dienste",
    "Jobs.title": "Dienste",
    "Jobs.newJob": "Dienst",
    "Jobs.all": "Alle bevorstehenden",
    "Jobs.allJobs": "Alle",
    "Jobs.filled": "Besetzt",
    "Jobs.pending": "Ausstehende",
    "Jobs.past": "Frühere",
    "Jobs.open": "Ausstehendes",
    "Jobs.project": "Projekt",
    "Jobs.projectManager": "Projektleiter",
    "Jobs.sales": "Sales",
    "Jobs.connection": "Verbindung",
    "Jobs.acceptJob": "Dienstanfrage annehmen",
    "Jobs.rejectJob": "Dienstanfrage ablehnen",
    "Jobs.requests": "Dienst anfordern",
    "Jobs.request.accepted": "Die Anfrage wurde angenommen",
    "Jobs.request.rejected": "Die Anfrage wurde abgelehnt",
    "Jobs.request.error":
      "Bei der Bearbeitung Ihrer Auswahl ist ein Fehler aufgetreten",
    "EmployeeList.unSetException":
      "Ausnahme kann nicht rückgängig gemacht werden",
    "WorkFlow.unarchive": "Zum Archivieren wechseln, um zu dearchivieren",
    "EmployeeList.unCancel": "Erneut einplanen",
    "EmployeeList.notExpired": "Erneut einplanen",
    "Employees.intake": "Kontaktmöglichkeit",
    "Employees.dateCreatedMin": "Datum erstellt (min)",
    "Employees.dateCreatedMax": "Datum erstellt (max)",
    "Employees.archived": "{items} Mitarbeitende archiviert",
    "Employees.archivedinfo":
      "Archiviert am: - {archivedAt}<br/> Archiviert von: - {archivedBy}",
    "Employees.export": "Exportieren",
    "Employees.sendOnboarding": "Onboarding-E-Mail senden",
    "Employees.sendMessage": "Eine Nachricht senden",
    "Employees.additionalFields": "Freie Felder",
    "Employees.nameOfTheField": "Feldname",
    "Employees.contentOfTheField": "Inhalt des Feldes",
    "Employees.single.setCards": "Setkarte",
    "Employees.single.collection.title": "Sammlung",
    "Employees.single.collection.description": "Ergänzen Sie Ihre Sammlung mit passenden Fotos zu den Setkarten.",
    "Employees.single.collection.upload.photo.title": "Wähle ein Foto aus",
    "Employees.single.collection.upload.photo.description": "Klicken Sie hier und laden Sie ein Foto hoch",
    "Employees.setCardsError": "* Mindestens <b>{item}</b> Bilder erforderlich",
    "Employees.setCards": "Setkarte erstellend",
    "Employees.setCardsModal.description": "Erstellen Sie eine Setkarte für Ihre Mitarbeiter, indem Sie die drei besten Fotos auswählen. Geben Sie an, welche Eigenschaften der Mitarbeiter hat. Sehen Sie sich das Beispiel an, sind Sie zufrieden? Klicken Sie auf Setkarte generieren.",
    "Employees.setCardsModal.dropdownOne.title": "Allgemein",
    "Employees.setCardsModal.dropdownTwo.title": "Eigenschaften",
    "Employees.setCardsModal.dropdownThree.title": "Freie Felder",
    "Employeees.setCardsmodal.submitButton": "Setkarten generieren",
    "Hours.function_based": "Menge",
    "Hours.extraCost": "Zusätzliche Kosten",
    "Hours.acceptedTab": "Akzeptiert",
    "Jobs.occupation": "Besetzung",
    "Jobs.address": "Adresse",
    "Jobs.city": "Stadt",
    "Jobs.time": "Start-Endzeit",
    "Jobs.date": "Datum",
    "Jobs.dates": "Daten",
    "Jobs.cityNote": "Stadt/Notiz",
    "Jobs.export": "Exportieren",
    "Jobs.houseNumber": "Hausnummer",
    "Jobs.houseNumber2": "Nummer",
    "Jobs.houseNumberExt": "Hausnummerzusatz",
    "Jobs.houseNumberExt2": "Zusatz",
    "Jobs.zipCode": "PLZ",
    "Jobs.extraNote": "Interne Notiz",
    "Jobs.logo": "Logo",
    "Jobs.projectName": "Projektname",
    "Jobs.client": "Auftraggeber",
    "Jobs.coordinator": "Koordinator",
    "Jobs.startDate": "Startdatum",
    "Jobs.endDate": "Enddatum",
    "Jobs.zipCodeMin": "PLZ (min)",
    "Jobs.zipCodeMax": "PLZ (max)",
    "Jobs.resetFilters": "Filter löschen",
    "Jobs.Single.employees": "Mitarbeitende",
    "Jobs.Single.scheduled": "Eingeplant",
    "Jobs.Single.pending": "Ausstehende",
    "Jobs.Single.details": "Details",
    "Jobs.Single.media": "Medien",
    "Jobs.Single.instructions": "Anweisungen",
    "Jobs.Single.jobSettings": "Diensteinstellungen",
    "Jobs.Details.workLocation": "Dienststandort",
    "Jobs.Details.streetName": "Straßenname",
    "Jobs.Details.number": "Hausnummer",
    "Jobs.Details.addition": "Zusatz",
    "Jobs.Details.zipCode": "PLZ",
    "Jobs.Details.city": "Stadt",
    "Jobs.Details.employees": "Mitarbeitende",
    "Jobs.Details.dateTime": "Datum & Uhrzeit",
    "Jobs.Details.pool": "Aktueller Pool",
    "Jobs.Details.addPool": "Einen Pool hinzufügen",
    "Jobs.Details.updatePool": "Pool aktualisieren",
    "Jobs.Details.date": "Datum",
    "Jobs.Details.time": "Zeit",
    "Jobs.Details.maxCancellationTime": "Max. Stornierungszeit",
    "Jobs.Details.maximumCancellationTime": "Maximale Stornierungszeit",
    "Jobs.Details.description": "Beschreibung",
    "CreationFlow.modal.success.title": "Erfolgreich abgeschlossen!",
    "CreationFlow.modal.success.description":
      "Sie haben <b>{items}</b> Dienst(e) erstellt.",
    "CreationFlow.modal.success.description.project":
      "Sie haben gerade das Projekt: <b>{Project_Name}</b> erstellt.",
    "CreationFlow.modal.success.description.employee":
      "Sie haben <b>{items}</b> Mitarbeitende(n) erstellt.",
    "CreationFlow.modal.actions.sendAMessage": "Eine Nachricht senden",
    "CreationFlow.modal.actions.shareJobs": "Dienst teilen",
    "CreationFlow.modal.actions.publishOnAdmin":
      "Dienst auf {admin_Name} veröffentlichen",
    "CreationFlow.modal.actions.goToTheJobsTable": "Zur Diensttabelle gehen",
    "CreationFlow.modal.actions.done": "Fertigstellen",
    "CreationFlow.modal.actions.createJobs": "Sofort Dienste erstellen",
    "CreationFlow.modal.actions.goToProjectDetails": "Detailseite anzeigen",
    "CreationFlow.modal.actions.goToProjectsTable":
      "Zurück zur Tabellenübersicht",
    "CreationFlow.modal.actions.scheduleNow": "Direkt einplanen",
    "CreationFlow.modal.actions.goToTheEmployeesTable": "Zur Tabelle gehen",
    "Jobs.Details.documents": "Dokumente",
    "Jobs.Details.address-description": "Adressenbeschreibung",
    "Jobs.Details.project": "Projekt",
    "Jobs.Details.jobRequestDetails.title": "Dienstanfrage Details",
    "Jobs.Details.jobRequestDetails.employees":
      "Anzahl angeforderter Mitarbeitender",
    "Jobs.Details.jobRequestDetails.remark": "Anmerkung",
    "Jobs.planSomeoneIn": "Einplanen",
    "Jobs.searchForAnEmployee": "Suchen",
    "Jobs.schedule": "Mitarbeitenden einplanen",
    "Jobs.addressDescription": "Adressenbeschreibung",
    "Jobs.hours": "Stunden",
    "Jobs.name": "Adresse",
    "Jobs.type": "Diensttyp",
    "Jobs.workflow": "Workflow-Vorlagen",
    "Jobs.selectWorkflow": "Vorlage auswählen",
    "Jobs.new": "Neuer Dienst",
    "Jobs.fullAddress": "Dienststandort",
    "Jobs.teamInstructions.successUpload":
      "Die Teamanweisungen wurden hinzugefügt",
    "Jobs.teamInstructions.errorUpload":
      "Beim Hochladen der Teamanweisungen ist ein Problem aufgetreten",
    "Jobs.Details.poolDescription":
      "Stellen Sie den Dienst der Gruppe von Mitarbeitenden zur Verfügung, die für diese Arbeit geeignet sind. Soll der Dienst für alle oder für den Pool des Projekts sichtbar sein? Fügen Sie dann keinen Pool hinzu. Ihr Dienst ist jetzt für %fu Mitarbeitende sichtbar.",
    "Jobs.plannedJobTimes": "Geplante Dienstzeiten",
    "Jobs.emptyState.title": "Es wurden noch keine Dienste erstellt",
    "Jobs.emptyState.description":
      "Klicken Sie oben rechts auf die violette Schaltfläche und erstellen Sie einen neuen Dienst.",
    "Jobs.shareJobs.emptyState.title": "In Fleks Dienste teilen",
    "Jobs.shareJobs.emptyState.description": `Arbeiten Sie mit externen Parteien zusammen, um die Dienste zu besetzen? Diese Dienste können Sie jetzt auch direkt von Fleks aus versenden. So können Sie Ihren Planungsverlauf weiterhin zentral in Fleks verfolgen.<br/><br/>Kontaktieren Sie Fleks für eine weitere Informationen oder senden Sie eine E-Mail an <a href:"mailto:support@fleks.info">support@fleks.info</a>, um die Verbindung anzufragen. Achten Sie dabei darauf, dass auch die kooperierende Partei davon auf dem Laufenden ist.`,
    "Jobs.shareJobs.emptyState.closeModal":
      "Klar und deutlich! Ansicht schließen",
    "Jobs.shareJobs.confirmation": `Sie sind dabei, {Items, Plural,
        one {# Job}
        other {# Jobs}    
      } Dienst(e), die bereits begonnen wurden oder in der Vergangenheit liegen, zu teilen, möchten Sie diese Aktion wirklich ausführen?`,
    "Jobs.shareJobs.restrictShareJobWithIncomingEmployee":
      "Sie können diesen Job nicht mit einem anderen Unternehmen teilen, da ein oder mehrere ankommende Mitarbeitende für diesen Job eingeplant wurden.",
    "Jobs.sharedJobs.restrictMultipleShareJobWithIncomingEmployee": `Sie können die ausgewählten Jobs nicht teilen, da {incomingEmployees} für Job(s) mindestens ein ankommender Mitarbeitende bereits daran gebunden wurde`,
    "Jobs.timeNoCluster": "Zeit (wird nicht in einem Cluster angezeigt)",
    "Jobs.numberOfPendingSpots": "Status und Anzahl der offenen Stellen",
    "Jobs.numberOfSchedules": "Anzahl der Einplanungen",
    "Jobs.startTime": "Startzeit",
    "Jobs.endTime": "Ende",
    "Jobs.requested": "Angefordert",
    "Jobs.requested.resume": "Angefordert ({totalAccepted}/{totalShared})",
    "Jobs.shared": "Geteilte",
    "Jobs.sharedAt": "Angefordert am",
    "Jobs.cancelledAt": "Storniert am",
    "Jobs.cancelledBy": "Storniert von",
    "Jobs.employeeRequested":
      "Anzahl der angeforderten Mitarbeitenden {tenant_name}",
    "Jobs.remarkMessage": "Anmerkung",
    "Jobs.employeeRequested.supplier": "Angefordert von {tenant_name}",
    "Jobs.scheduledEmployees": "Eingeplante Mitarbeitende",
    "Jobs.appliedEmployees": "Registrierte Mitarbeitende",
    "Jobs.owner": "Inhaber",
    "Jobs.accepted": "Angenommen",
    "Jobs.accepted.resume": "Angenommen ({totalAccepted}/{totalShared})",
    "Jobs.accepted.by": "Von ({tenantName})",
    "Jobs.cancelled": "Storniert",
    "Jobs.cancelled.resume": "Storniert ({totalAccepted}/{totalShared})",
    "Jobs.notRequested": "Nicht angefordert",
    "Jobs.alreadyFilled": "Schon besetzt",
    "Jobs.createTemplate":
      "Erstellen von Standarddiensten für: {client_name} - {standard_project_name}",
    "Jobs.searchCandidates": "Geeignete Mitarbeitende suchen",
    "Jobs.times": "Zeit",
    "Jobs.street": "Straßenname",
    "Jobs.internalNote": "Interne Notiz",
    "Jobs.teamInstructions": "Teamanweisungen",
    "Jobs.teamInstructions.choice1": "Ja",
    "Jobs.teamInstructions.choice2": "Nein",
    "Jobs.jobStatus": "Status, Registrierungen und Einladungen",
    "Jobs.archive.confirmation": `Sie sind dabei,
      {Items, Plural,
        one {# Dienst}
        other {# Dienste}    
      } zu {archive, select,
        true {zu-archivieren}
        false {archivieren}
      }, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Jobs.cancel.confirmation": `{withMyEmployees, select,
        true {One or more jobs have at least of one your employees working on it.}
        false {}
      } Alle Einplanungen von externen Parteien werden storniert. Möchten Sie fortfahren?`,
    "Jobs.cancel.success": `{items, plural,
        one {# Dienst storniert}
        other { # Dienste storniert }
      }`,
    "Jobs.cancelJob": "Dienst stornieren",
    "Jobs.cancelJobs": "Dienst(e) stornieren",
    "Jobs.ReasonOptional": "Grund (optional)",
    "Jobs.cancellationPolicyWarning":
      "Sie können den Dienst bis<b>{day}</b> <b>{month_name}</b> <b>{year}</b> unverbindlich stornieren, sind Sie sicher, dass Sie diesen Dienst stornieren möchten?",
    "Jobs.activeApplicationsWarning":
      "Es gibt noch eine oder mehrere gültige Anmeldungen für diesen Dienst, indem die Aktion bestätigt wird, werden sie automatisch storniert.",
    "Jobs.cancelSuccessful": "Dienst wurde storniert",
    "Jobs.DateTimeModal.title": "Datum und Uhrzeit anpassen",
    "Jobs.plannedEmployees": "Eingeplant",
    "Jobs.proposalEmployees": "Vorgeschlagen",
    "Jobs.proposedEmployees": "Vorgeschlagen",
    "Jobs.registeredEmployees": "Registriert",
    "Jobs.invitedEmployees": "Eingeladen",
    "Jobs.adjustDescription": "Beschreibung ändern",
    "Jobs.adjustAddressDescription": "Dienstadresse ändern",
    "Jobs.archived": `{Items, Plural,
        one {# Dienst archiviert }
        other { # Dienste archiviert }
      }`,
    "Jobs.proposeEmployee": "Vorschlagen",
    "Jobs.import.error":
      "Beim Lesen des Google-Blatts ist ein Fehler aufgetreten. Stellen Sie sicher, dass die Spaltennamen mit der Vorlage übereinstimmen.",
    "Jobs.plannedFreelancers": "Eingeplante Freiberufler",
    "Jobs.appliedFreelancers": "Registrierte Freiberufler",
    "Jobs.appliedFreelancers.applicationDate": "Registriert am",
    "Jobs.positionsOfTheJob": "Anzahl der Stellen für den Dienst",
    "Jobs.jobCategory": "Funktion",
    "Jobs.jobFunction": "Dienstfunktion",
    "Jobs.details.single": "Einen Tag",
    "Jobs.details.multiple": "Mehrere Tage",
    "Jobs.details.repetitiveJobs": "Wiederkehrender Dienst",
    "Jobs.details.dateAndTime": "Datum und Uhrzeit",
    "Jobs.details.from": "Von",
    "Jobs.details.until": "Bis",
    "Jobs.details.description": "Beschreibung (optional)",
    "Jobs.description.title": "Arbeitsbeschreibung",
    "Jobs.selectFileToImport": "Wählen Sie eine zu importierende Datei aus",
    "Jobs.description1":
      "Duplizieren Sie das Beispiel für das<a href='https://docs.google.com/spreadsheets/d/1MTRrhjcDpj5jquhJo5iMJYILS00R-MCvO9TwGXgEDvM/edit#gid=0' target='_blank'>folgende Google Blatt</a> in Ihr eigenes Google Drive Benutzerkonto.",
    "Jobs.description2":
      "Wurde die Importdatei korrekt ausgefüllt? Dann können Sie diese Datei auswählen, indem Sie auf die untere Schaltfläche klicken und mit dem Import beginnen.",
    "Jobs.description3":
      "Das Limit für die Personalnummern für eine Aufgabe beträgt 70. Darüber hinaus können Sie insgesamt 50 Dienste einschließlich Personalnummer importieren",
    "Jobs.associateProject":
      "In welches Projekt möchten Sie die Dienste importieren?",
    "Jobs.selectAProject": "Projekt auswählen",
    "Jobs.chooseTheFile": "Datei auswählen",
    "Jobs.chooseTheFileFromGoogle": "Eine Datei über Google auswählen",
    "Jobs.chooseTheFileFromLocal": "Eine Datei von Ihrem Computer auswählen",
    "Jobs.warnings.planInAndPropose":
      "Sie haben eigene und externe Dienste ausgewählt. Möchten Sie wirklich einplanen & vorschlagen?",
    "Jobs.warnings.numberOfEmployees":
      "Es ist nicht möglich, die Anzahl der Mitarbeitenden anzupassen, für einen oder mehrere Dienste wurden mehrere Mitarbeitende eingeplant",
    "Jobs.creation.warning.customNumbersLimit":
      "Dieser Dienst kann nicht erstellt werden, da die Anzahl der Personalnummern auf 70 begrenzt ist.",
    "Jobs.creation.warning.JobLimitWithCustomNumbers":
      "Dieser Dienst kann nicht erstellt werden, da die Anzahl für die Erstellung von Diensten inklusive Personalnummern auf 50 begrenzt ist.",
    "Jobs.goToProjects": "Gehen Sie zu Projekten",
    "NewEmployers.title": "Neue Arbeitgeber",
    "NewEmployers.companyName": "Firmenname",
    "NewEmployers.city": "Stadt",
    "NewEmployers.address": "Adresse",
    "NewEmployers.createdAt": "Erstellungsdatum",
    "NewEmployers.contactPerson": "Mitarbeitenden kontaktieren",
    "NewEmployers.emptyState.title": "Keine neuen Arbeitgeber gefunden",
    "NewEmployers.emptyState.description": "",
    "NewEmployers.contactPersonFirstName": "Vorname Ansprechpartner",
    "NewEmployers.verified": "{items} Arbeitgeber verifiziert",
    "NewEmployers.verify.confirmation": `Sie sind dabei, {Items, Plural,
        one {# Arbeitgeber}
        other {# Arbeitgeber}    
      } zu verifizieren, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Employers.title": "Arbeitgeber",
    "Employers.companyName": "Firmenname",
    "Employers.city": "Stadt",
    "Employers.address": "Adresse",
    "Employers.activationDate": "Aktivierungsdatum",
    "Employers.createdAt": "Erstellungsdatum",
    "Employers.contactPerson": "Mitarbeitenden kontaktieren",
    "Employers.emptyState.title": "Keine Arbeitgeber gefunden",
    "Employers.emptyState.description": "",
    "Employers.contactPersonFirstName": "Vorname Ansprechpartner",
    "Employers.chamberOfCommerceNumber": "IHK-Nummer",
    "Employees.title": "Mitarbeitende",
    "Employees.incoming.title": "Externe Mitarbeitende",
    "Employees.internals": "Intern",
    "Employees.externals": "Extern",
    "Employees.newEmployee": "Mitarbeitende hinzufügen",
    "Employees.all": "Alle",
    "Employees.name": "Name",
    "Employees.number": "Telefon",
    "Employees.city": "Stadt",
    "Employees.comments": "Anmerkungen",
    "Employees.editComments": "Kommentar bearbeiten",
    "Employees.editComments.success": "Kommentar erfolgreich bearbeitet",
    "Employees.comments.extra": "Anrufnotizen",
    "Employees.age": "Alter",
    "Employees.lastActive": "Letzte Registrierung",
    "Employees.activeSince": "Aktiv seit",
    "Employees.firstLogin": "Erste Anmeldung",
    "Employees.lastLogin": "Zuletzt angemeldet",
    "Employees.phase": "Phase",
    "Employees.houseNumber": "Hausnummer",
    "Employees.houseNumberExt": "Zusatz (optional)",
    "Employees.idType": "Ausweisart",
    "Employees.documentNumber": "Dokumentnummer",
    "Employees.dateCreation": "Erstellt am",
    "Employees.lastInvitation": "Eingeladen am",
    "Employees.bankAccountNumber": "Bankverbindung",
    "Employees.initial": "Initialen",
    "Employees.bsn": "Sozialversicherungsnummer (SV)",
    "Employees.startDate": "Startdatum",
    "Employees.endDate": "Enddatum",
    "Employees.firstName": "Vorname",
    "Employees.lastName": "Nachname",
    "Employees.locationRadius": "Standort max. (km)",
    "Employees.noMaxLocationRadius": "Keine max. km",
    "Employees.locationKm": "Max (km)",
    "Employees.zipCodeFrom": "PLZ ab",
    "Employees.zipCodeFromMin": "PLZ (min)",
    "Employees.zipCodeTill": "PLZ bis",
    "Employees.zipCodeTillMax": "PLZ (max)",
    "Employees.categories": "Kategorien",
    "Employee.specifications": "Mitarbeitervorgaben",
    "Employees.categoryName": "Function name",
    "Employees.activeContract": "Mit Vertrag",
    "Employees.whitContract": "Ohne Vertrag",
    "Employees.drivingLicense": "Führerschein",
    "Employees.car": "Auto",
    "Employees.sectors": "Funktionen",
    "Employees.trainings": "Schulungen",
    "Employees.qualifications": "Qualifikationen",
    "Employees.travelInformation": "Reiseinformationen",
    "Employees.additionalInformation": "Zusätzliche Informationen",
    "Employees.travelSpecifications": "Reisespezifikationen",
    "Employees.faculties": "Spezifikationen",
    "Employees.addFaculties": "Spezifikation anpassen",
    "Employees.resetFilters": "Filter löschen",
    "Employees.IBAN": "IBAN",
    "Employees.socialSecurityNumber": "SV Nummer",
    "Employees.expirationDate": "Gültigkeitsdatum",
    "Employees.frontSideId": "Ausweisvorderseite",
    "Employees.backSideId": "Ausweisrückseite",
    "Employees.viewImg": "Ansehen",
    "Employees.upload": "Hochladen",
    "EmployeeModal.title": "Informationen über Mitarbeitende exportieren",
    "Employees.phoneNumber": "Telefonnummer",
    "Employees.validation.birthDate": "Mindestens 18 Jahre",
    "Employees.validation.zipCode":
      "PLZ muss 4 Ziffern und dann 2 Buchstaben enthalten.",
    "Employees.validation.password":
      "Passwort muss mindestens 8 und maximal 32 Zeichen enthalten.",
    "Employees.validation.email": "E-Mail-Adresse nicht bekannt.",
    "Employees.validation.email.length":
      "E-Mail-Adresse muss mindestens 8 Zeichen enthalten.",
    "Employees.validation.fields": "Einige Felder wurden falsch eingegeben.",
    "Employees.validation.fields.description":
      "Mitarbeitende wurde erstellt, aber noch nicht verifiziert, da Felder falsch ausgefüllt wurden. Sie können ihn/sie bei den Bewerber finden.",
    "Employees.validation.exists": "Der Mitarbeitende existiert bereits.",
    "Employees.registrationDate": "Registrierungsdatum",
    "Employees.circlePhase":
      "%n hat in den letzten 30 Tagen %t-mal gearbeitet.",
    "Employees.checkPhase":
      "%n hat ihren/seinen Ausweis und Bankverbindung angegeben",
    "Employees.checkPhase2":
      "%n hat noch keinen Ausweis vorgelegt und keine Bankverbindung angegeben",
    "Employees.checkPhase3":
      "%n hat noch kein Profilbild hinzugefügt und/oder Name, Adresse, Wohnort-Daten noch nicht vollständig angegeben",
    "Employees.filePhase": "%n hat derzeit keinen Vertrag",
    "Employees.filePhase2":
      "%n hat derzeit den folgenden Vertrag unterzeichnet: %cn, het contract eindigt op %ed",
    "Employees.filePhase3":
      "%n hat folgendes Vertragsangebot erhalten: %cn - der Vertrag endet am %ed",
    "Employees.filePhase4":
      "%n hat derzeit den folgenden Vertrag unterzeichnet: %cn - der Vertrag endet am %ed. Ein neuer Vertragsvorschlag ist fertig: %ucn - dieser Vertrag beginnt am: %sd",
    "Employees.filePhase5":
      "Das folgende Vertragsangebot ist fertig: %ucn - dieser Vertrag beginnt am: %sd",
    "Employees.birthDate": "Geburtsdatum",
    "Employees.birthDate.2": "Geburtsdatum",
    "Employees.birthDate.3": "Geburtsdatum",
    "Employees.address": "Adresse",
    "Employees.jobCategory": "Jobkategorie",
    "Employees.isVerified": "Phase 1",
    "Employees.isRegComplete": "Phase 2",
    "Employees.birthDateDtGte": "Alter (max)",
    "Employees.birthDateDtLte": "Alter (min)",
    "Employees.socialNumber": "Sozialversicherungsnummer",
    "Employees.wageTaxRebate": "Lohnabsetzbetrag",
    "Employees.wageTaxRebateDate": "Lohnabsetzbetrag Änderungsdatum",
    "EmployeesImport.title": "Mitarbeitenden importieren",
    "EmployeesImport.sheetName": "Blattname",
    "EmployeesImport.initialCell": "Zelle oben links",
    "EmployeesImport.finalCell": "Zelle rechts unten",
    "EmployeesImport.reserve": "Reserve",
    "EmployeesImport.quiz": "Quiz anwenden",
    "Employees.minNumberTimes": "Wie oft gearbeitet (min)",
    "Employees.maxNumberTimes": "Wie oft gearbeitet (max)",
    "Employees.stepsEqual": "Registrierungsstatus",
    "Employees.stepsGte": "Registrierungsstatus",
    "Employees.stepsLte": "Registrierungsstatus",
    "Employees.phase_1_incomplete": "Phase 1 unvollständig",
    "Employees.phase_1_complete": "Phase 1 vollständig",
    "Employees.phase_2_incomplete": "Phase 2 unvollständig",
    "Employees.phase_2_complete": "Phase 2 vollständig",
    "Employees.isApproved": "Angenommen",
    "Employees.isExported": "Verarbeitet",
    "Employees.startPeriodJobs": "Start Dienstperiode",
    "Employees.endPeriodJobs": "Ende Dienstperiode",
    "Employees.numberOfJobsMin": "Anzahl der Dienste (min)",
    "Employees.numberOfJobsMax": "Anzahl der Dienste (max)",
    "Employees.hasWorked": "Hat gearbeitet",
    "Employees.wageTaxRebateGte": "Änderung Lohnsteuer (min)",
    "Employees.wageTaxRebateLte": "Änderung Lohnsteuer (max)",
    "Employees.hasWorkedGte": "Erster Arbeitstag (min)",
    "Employees.hasWorkedLte": "Erster Werktag (max)",
    "Employees.fullName": "Name",
    "Employees.search": "Mitarbeitenden suchen",
    "Employees.newEmployees": "Neue Mitarbeitende",
    "Employees.newApplication.invitation": "Mitarbeitende wurde eingeladen",
    "Employees.newApplication.schedule": "Mitarbeitende ist eingeplant",
    "Employees.newApplication.rejected": "Mitarbeitende wurde abgelehnt",
    "Employees.newApplication.proposed": "Mitarbeitende wurde vorgeschlagen",
    "Employees.newApplication.cancel":
      "Mitarbeitende wurde erfolgreich storniert",
    "Employees.options": "Optionen",
    "Employees.monday": "Mo",
    "Employees.tuesday": "Di",
    "Employees.wednesday": "Mi",
    "Employees.thursday": "Do",
    "Employees.friday": "Fr",
    "Employees.saturday": "Sa",
    "Employees.sunday": "So",
    "Employees.night": "Nacht",
    "Employees.morning": "Morgen",
    "Employees.afternoon": "Nachmittag",
    "Employees.evening": "Abend",
    "Employees.jobPermission": "Qualifiziert",
    "Employees.history.absent": "Abwesend",
    "Employees.history.notifications": "Erhaltene Push-Benachrichtigungen",
    "Employees.customNumber": "Personal-ID",
    "Employees.fullAddress": "Adresse des Mitarbeitenden",
    "Employees.insertion": "Namenszusatz",
    "Employees.sendOnboarding.explain": "Onboarding-E-Mail senden",
    "Employees.invitedAt": "Eingeladen am",
    "Employees.openedAt": "Eröffnet am",
    "Employees.registeredAt": "Registriert am",
    "Employees.firstJobAt": "Erster Werktag am",
    "Employees.lastJobAt": "Letzter Dienst am",
    "Employees.emptyState.title":
      "Keine Mitarbeitenden gefunden oder hinzugefügt",
    "Employees.emptyState.description":
      "Klicken Sie auf die violette Schaltfläche in der oberen rechten Ecke, um einen Mitarbeitenden hinzuzufügen.",
    "Employees.newEmployeesCreated":
      "Neuer Mitarbeitende erstellt mit: {employee_email}.",
    "Employees.withdrawInvite": "Widerrufen",
    "Employees.workedRecently": "Aktiv (30 Tage)",
    "Employees.workedRecently.hover": "In den letzten 30 Tagen gearbeitet",
    "Employees.worked": "Aktiv (60 Tage)",
    "Employees.worked.hover": "Arbeitete im Vormonat",
    "Employees.inactive": "Inaktiv",
    "Employees.inactive.hover":
      "Hat im letzten Monat oder in diesem Monat nicht gearbeitet",
    "Employees.waiting": "Neu",
    "Employees.waiting.hover": "Wartet auf den ersten Dienst!",
    "Employees.archive.confirmation": `Sie sind dabei,
      {Items, Plural,
        one {# Mitarbeitenden}
        other {# Mitarbeitende}    
      } zu {archive, select,
        true {zu-archivieren}
        false {archivieren}
      }, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Employees.single.contract.payroll": "Lohn-/Einkommensteuer",
    "Employees.single.contract.payroll.wage":
      "Anrecht auf Lohn-/Einkommensteuer?",
    "Employees.single.contract.payroll.wage.yes": "Ja, ab {date}",
    "Employees.single.contract.payroll.wage.no": "Nein",
    "Employees.single.contract.business": "Geschäftsdaten",
    "Employees.single.contract.business.iban": "IBAN",
    "Employees.single.contract.contracts": "Verträge",
    //"Employees.single.contract.contracts.dateRange": "{from} von bis {to}",
    "Employees.single.contract.contracts.dateRange":
      "{from} {to, plural, =null {} other {von bis}} {to}",
    "Employees.single.contract.contracts.add": "Vertrag hinzufügen",
    "Employees.single.contract.contracts.addOne": "Einen Vertrag hinzufügen",
    "Employees.single.contract.shareEmployee.titleButtonModal":
      "Verbindungsvertrag aktualisieren",
    "Employees.single.contract.shareEmployee.titleButtonModalEdit":
      "Verbindungsvertrag aktualisieren",
    "Employees.single.contract.shareEmployee.titleCard":
      "Einen Mitarbeitenden teilen",
    "Employees.single.contract.shareEmployee.titleCardEdit":
      "Mitarbeitenden teilen anpassen",
    "Employees.single.contract.shareEmployee.textButtonAdd":
      "Den Mitarbeitenden teilen",
    "Employees.single.contract.shareEmployee.descriptionCard":
      "Wählen Sie eine Verbindung, mit der Sie den Mitarbeitenden teilen und fügen Sie einen Zeitraum und/oder einzelne Tage hinzu.",
    "Employees.single.contract.shareEmployee.descriptionCardEdit":
      "Ändern Sie den Zeitraum und/oder fügen Sie einzelne Tage hinzu.",
    "Employees.single.contract.shareEmployee.labelTextTitleConnection":
      "Betrifft die Verbindung",
    "Employees.single.contract.shareEmployee.labelTextConnection":
      "Verbindung auswählen",
    "Employees.single.contract.shareEmployee.labelTextPeriod":
      "Zeitraum auswählen",
    "Employees.single.contract.shareEmployee.labelTextWeekdays":
      "Optional: Wochentage in diesem Zeitraum angeben",
    "Employees.single.contract.shareEmployee.labelTextSingleDays":
      "Einzelne Tage auswählen",
    "Employees.single.contract.shareEmployee.labelTextHoursPerWeek":
      "Optional: Wie viele Stunden pro Woche?",
    "Employees.single.contract.shareEmployee.labelInputHoursAmount":
      "Stundenzahl",
    "Employees.single.contract.shareEmployee.labelWeekdaysSelected":
      "Alle Wochentage sind ausgewählt.",
    "Employees.id": "Ausweisnummer",
    "Employees.forgotPassword": "Passwort vergessen? Klicken Sie hier",
    "Employees.lastContact": "Letzter Kontakt",
    "EmployeesImport.sheetName.explain":
      "Wenn Sie dieses Feld leer lassen, wird das erste Blatt importiert, geben Sie hier den Namen des Blattes ein, wenn Sie eine Ausnahme machen möchten",
    "EmployeesImport.initialCell.explain":
      "Die Zelle A1 ist standardmäßig ausgewählt, füllen Sie die Zelle hier aus, wenn Sie eine Ausnahme für die obere linke Zelle machen möchten",
    "EmployeesImport.finalCell.explain":
      "Standardmäßig wird die letzte gültige Zelle ausgewählt, geben Sie die Zelle hier ein, wenn Sie eine Ausnahme für die Zelle rechts unten machen möchten",
    "EmployeesImport.import.explain":
      "Beim Importieren eines Blattes muss die erste Zeile die Namen der Zellen enthalten",
    "EmployeesImport.explain":
      "Importieren Sie Mitarbeitende mit folgenden Angaben, die erste Zeile muss folgende Namen enthalten: E-Mail-Adresse, Vorname, Nachname, Initialen, Adresse, Hausnummer, Hausnummerzusatz, Telefonnummer, PLZ, Ort, Geburtsdatum, ist verifiziert, ist registriert, Jobkategorien",
    "EmployeesImport.wrongCell": "Keine gültige Zelle",
    "EmployeesImport.discardedEmployees":
      "Mitarbeitende wurden nicht importiert, da die E-Mail-Adresse fehlt",
    "EmployeesImport.discardedFreeFields": `Folgende freie Felder konnten nicht gefunden werden: {free_fields}`,
    "EmployeesImport.import": "Importieren",
    "EmployeesImport.error": "Beim Importieren ist ein Fehler aufgetreten",
    "EmployeesImport.error-explain":
      "Es ist ein Fehler aufgetreten, sind der eingegebene Blattname, die Start- und/oder Endzelle korrekt?",
    "EmployeesImport.toCreate": "Mitarbeitende importieren",
    "EmployeesImport.created": "Erstellte Mitarbeitende",
    "EmployeesImport.success": "Mitarbeitende wurden erstellt",
    "EmployeesImport.error-save":
      "Folgende Mitarbeitende wurden nicht erstellt, da die E-Mail-Adresse bereits verwendet wird",
    "EmployeesImport.results": "Importierte Mitarbeitende",
    "EmployeesImport.errorWhenSendOnboardingEmail":
      "Die E-Mail wurde nicht gesendet, bitte überprüfen Sie die E-Mail-Adresse",
    "EmployeesImport.sendOnboardingEmail":
      "Informieren und senden Sie die Onboarding-E-Mail direkt an den/die neuen Mitarbeitenden",
    "EmployeesImport.sendOnboardingEmail.single":
      "Informieren Sie den Mitarbeitenden mit der Onboarding-E-Mail über die Nutzung von Fleks.",
    "EmployeesImport.descriptionWithTemplate":
      "Verwenden Sie<a href='https://docs.google.com/spreadsheets/d/1nc3h3X7-4K3DId-Q85Uj1oNiY8Mqz0BbY2q7COVQ3IQ/edit#gid=0' target='_blank'>following Google Sheet</a> als Vorlage für den Import Ihrer Mitarbeitenden.",
    "Employees.filePhase6": "Der letzte Vertrag endete am: %ed",
    "Employees.BasicInformationModal.title":
      "Allgemeine Informationen bearbeiten",
    "Jobs.jobFreeFieldsModal.title": "Passen Sie den Informationsdienst für kostenlose Felder an",
    "Employees.TravelSpecificationsModal.title":
      "Reiseinformationen bearbeiten",
    "Employees.CharacteristicsModal.title": "Merkmale anpassen",
    "Employees.AdditionalInformationModal.title":
      "Zusätzliche Informationen anpassen",
    "Employees.FacultiesModal.title": "Spezifikationen anpassen",
    "Employees.FacultiesModal.footer": "ACHTUNG! Bestehende Kategorien, die bereits mit einem Enddatum hinzugefügt wurden, werden überschritten.",
    "Employees.SpecificationsModal.title": "Kategorie hinzufügen",
    "Employees.modalSave.tittleButton": "Hinzufügen",
    "Employees.BusinessDataModal.title": "Geschäftsinformationen ändern",
    "Employees.BusinessDataModal.adjustBankData.title": "Bankdaten anpassen",
    "Employees.PayrollIncomeTaxModal.title":
      "Angaben zum Lohnabsetzbetrag ändern",
    "Employees.adjustContract": "Vertragsinformationen",
    "Employees.modal.save": "Allgemeine Informationen speichern",
    "Employees.contactMoment": "Kontaktmöglichkeiten",
    "Employees.sendMessageScheduledEmployees": "Dienstplan-Mail senden",
    "Employees.selectMaximumDate": "Wählen Sie das maximale Datum",
    "Employees.agenda": "Kalender",
    "Employees.inviteToJob": "Zu diesem Dienst einladen",
    "Employees.planInForThisJobs": "Für diesen Dienst einplanen",
    "Employees.proposeForThisJob": "Für diesen Dienst vorschlagen",
    "Employees.shareEmployees": "Mitarbeitende teilen",
    "Employees.shareEmployees.alreadyShared": `Mindestens einer der ausgewählten Mitarbeitenden wurde bereits mit {tenant_name} geteilt`,
    "Employees.shareEmployees.success": `Die ausgewählten Mitarbeitenden werden mit {tenant_name} geteilt`,
    "Applicants.title": "Bewerber",
    "Applicants.newApplicant": "Bewerber hinzufügen",
    "Applicants.accepted": "Angenommen",
    "Applicants.applicants": "Bewerber",
    "Applicants.createApplicant.title": "Neubewerber",
    "Applicants.createApplicant.save": "Bewerber erstellen",
    "Applicants.sendOnboardingEmail.check":
      "Informieren Sie den/die Bewerber mit der Onboarding-E-Mail über die Nutzung von Fleks.",
    "Applicants.newApplicantCreated":
      "Neubewerber erstellt mit: {employee_email}.",
    "Applicants.newAccepted": "Neue/r Bewerber angenommen",
    "Applicants.confirm-accept-checked":
      "Sind Sie sicher, dass Sie den/die Bewerber in die nächste Phase verschieben möchten?",
    "Applicants.emptyState.title": "Es wurden noch keine Bewerber hinzugefügt",
    "Applicants.emptyState.description":
      "Klicken Sie auf die violette Schaltfläche in der oberen rechten Ecke, um einen Mitarbeitenden hinzuzufügen.",
    "Applicants.registered": "Registriert",
    "Applicants.link": "Link zum Anmeldeformular anzeigen",
    "Applicants.link.title": "Link zum Anmeldeformular teilen",
    "Applicants.link.description":
      "Kopieren Sie den unten stehenden Link und veröffentlichen Sie ihn dort, wo potenzielle Mitarbeitende ihn lesen und sich für Ihre Organisation anmelden können!",
    "Applicants.agreement":
      "Durch die Erstellung eines Benutzerkontos stimmen Sie der Datenschutzerklärung/Vereinbarung zu",
    "History.totalJobs": "Gesamtzahl der Dienste",
    "History.extraHours": "Gesamtzahl der zusätzlichen Stunden",
    "History.grossSalary": "Gesamtbruttogehalt",
    "History.travelTime": "Gesamtfahrzeit",
    "History.travelTimeFee": "Gesamtfahrzeit Kostenerstattung",
    "History.kmDriven": "Gesamtzahl km",
    "History.otherCost": "Sonstige Kosten insgesamt",
    "History.sick": "Anzahl der Krankheitszeiten",
    "History.noShow": "Anzahl Nichterscheinen",
    "Notifications.error": "Es ist ein Fehler aufgetreten",
    "Notifications.beAware": "Achtung",
    "Notifications.confirm-accept": "Sind Sie sicher?",
    "Notifications.confirm-accept-checked":
      "Sind Sie sicher, dass Sie alle Bewerber annehmen möchten?",
    "Notifications.successful": "Erfolgreich",
    "Notifications.archived": "archiviert",
    "Notifications.unarchived": "De-archivieren",
    "Notifications.activate":
      "Sie können jetzt Benachrichtigungen von Fleks erhalten, geben Sie an, ob Sie sie erhalten möchten",
    "Notifications.confirm-accept-declarations":
      "Sie sind dabei, die ausgewählte(n) Zeiterfassung(en) zu akzeptieren. Möchten Sie fortfahren?",
    "Hours.isException": "Ausnahme?",
    "Hours.isCompleted": "Arbeitsablauf abgeschlossen?",
    "Hours.employeeZipCode": "PLZ",
    "Hours.employeePhoneNumber": "Telefonnummer",
    "Hours.employeeEmail": "E-Mail-Adresse des Mitarbeitenden",
    "Hours.employeeCity": "Stadt",
    "Hours.employeeAddress": "Adresse",
    "Hours.employeeFullAddress": "Adresse des Mitarbeitenden",
    "Hours.employeeHouseN": "Hausnummer",
    "Hours.employeeHouseNExt": "Zusatz",
    "Hours.jobZipCode": "PLZ des Dienstes",
    "Hours.jobCity": "Stadt des Dienstes",
    "Hours.jobAddress": "Anschrift des Dienstes",
    "Hours.jobFullAddress": "Dienststandort",
    "Hours.jobHouseN": "Hausnummer des Dienstes",
    "Hours.jobHoouseNExt": "Hausnummerzusatz des Dienstes",
    "Hours.jobCategory": "Funktion",
    "Hours.startDateMin": "Startdatum (min)",
    "Hours.startDateMax": "Startdatum (max)",
    "Hours.bonus": "Bonus",
    "Hours.travelDistance": "Entfernung",
    "Hours.hostNumber": "Personalnr.",
    "Hours.name": "Name",
    "Hours.lastname": "Nachname",
    "Hours.city": "Stadt",
    "Hours.totalHours": "Gesamtstundenzahl",
    "Hours.extra": "Extra",
    "Hours.travelCosts": "Fahrtkosten",
    "Hours.totalCost": "Gesamtpreis",
    "Hours.title": "Zeiterfassung",
    "Hours.newEmployee": "Neuer Mitarbeitende",
    "Hours.employeeId": "Pers nr", //
    "Hours.all": "Alle",
    "Hours.open": "Offen",
    "Hours.outliers": "Ausreißer",
    "Hours.accepted": "Angenommen",
    "Hours.notSent": "Arbeitsablauf unvollständig",
    "Hours.checkTheHours": "Stunden überprüfen",
    "Hours.incompleted": "Unvollständig",
    "Hours.processed": "Verarbeitet",
    "Hours.initial": "Initialen",
    "Hours.prefix": "Namenszusatz",
    "Hours.birthDay": "Geburtsdatum",
    "Hours.age": "Alter",
    "Hours.projectTitle": "Projektname",
    "Hours.startDate": "Startdatum",
    "Hours.workedDay": "Arbeitsstunden",
    "Hours.additionalHours": "Extrastunden",
    "Hours.breakHours": "Pausenstunden (min)",
    "Hours.appliedPaymentMethod": "Angewandte Zahlungsmethode",
    "Hours.hourlyWage": "Stundenlohn",
    "Hours.fixedHourlyWage": "Fester Stundenlohn",
    "Hours.fixedAmount": "Festbetrag",
    "Hours.fixedReserveRate": "Fester Reservesatz",
    "Hours.travelHours": "Fahrtzeiten",
    "Hours.travelHoursRate": "Fahrtzeiten Stundentarif",
    "Hours.noteStartTime": "Beim Aktualisieren der Start- und Endzeit sind beide erforderlich.",
    "Hours.noteBreak": "Es ist möglich, nur die Pause zu aktualisieren.",
    "Hours.calculate-error":
      "Bei der Berechnung der Fahrtkosten ist ein Fehler aufgetreten, stimmt der Standort?",
    "Hours.calculate-success": "Die Fahrtkosten wurden berechnet",
    "Hours.no-calculate":
      "Die Fahrtkosten konnten nicht berechnet werden. Können Sie überprüfen, ob die Adressdaten des Mitarbeitenden und des Dienstes korrekt sind?",
    "Hours.numberDrivenKm": "Gefahrene Kilometer",
    "Hours.ratePerKm": "Tarif pro km",
    "Hours.drivenKmFee": "Kilometerkosten",
    "Hours.publicTransportFee":
      "Kostenerstattung für öffentliche Verkehrsmittel",
    "Hours.advances": "Vorschüsse",
    "Hours.projectNumber": "Projektnummer",
    "Hours.declarationNumber": "Dienstnummer",
    "Hours.note": "Hinweise",
    "Hours.status": "Status",
    "Hours.endDate": "Enddatum",
    "Hours.details": "Details",
    "Hours.accepted-success": "Zeiterfassung wurde akzeptiert",
    "Hours.close-success": "Zeiterfassungen wurden verarbeitet",
    "Hours.open-to-approved-success":
      "Die Stunden werden im Hintergrund genehmigt, die Änderungen werden in ein paar Minuten in der Tabelle angezeigt. Das kann bis zu einer Stunde dauern",
    "Hours.approved-to-processed-success":
      "Die Stunden werden im Hintergrund verarbeitet, die Änderungen werden in ein paar Minuten in der Tabelle angezeigt. Das kann bis zu einer Stunde dauern",
    "Hours.toinvoiced-to-invoiced-success":
      "Die Stunden werden im Hintergrund abgerechnet, die Änderungen werden in ein paar Minuten in der Tabelle angezeigt. Das kann bis zu einer Stunde dauern",
    "Hours.otherCost": "Sonstige Kosten",
    "Hours.checkTimes": "Registrierte Zeit",
    "Hours.projectID": "Projektnummer",
    "Hours.travelDistanceCost": "Km-Kosten",
    "Hours.travelTimesCost": "Fahrtzeitkosten",
    "Hours.transportationCost": "Kosten für öffentlichen Nahverkehr",
    "Hours.declarationPhoto": "Foto(s) deklarieren",
    "Hours.both": "Foto(s) deklarieren",
    "Hours.fixed_rate": "Festbetrag",
    "Hours.hourly_rate": "Festbetrag pro Stunde",
    "Hours.custom_age_related_rate": "Nach Alter",
    "Hours.reserve_rate": "Reservebetrag",
    "Hours.declaration": "Zeiterfassung",
    "Hours.costs": "Bruttogehalt",
    "Hours.travel": "Fahrtkosten",
    "Hours.travelDistanceApplicable": "Gefahrene Kilometer",
    "Hours.travelTime": "Fahrtzeit",
    "Hours.travelTimeCompensation": "Fahrzeit Stundentarif",
    "Hours.travelTimeFee": "Fahrtzeitpauschale",
    "Hours.travelTimeRate": "Stundensatz Fahrzeit",
    "Hours.publicTransport":
      "Kosten für öffentlichen Nahverkehr können deklariert werden",
    "Hours.preCalculateTravel": "Fahrtkosten werden mit Google Maps berechnet",
    "Hours.grossSalary": "Bruttogehalt (exkl. Fahrtzeitpauschale)",
    "Hours.otherCosts": "Sonstige Kosten",
    "Hours.kmDrivingCost": "Kilometerpauschale",
    "Hours.netCosts": "Gesamtkosten",
    "Hours.gross-salary": "Bruttogehalt",
    "Hours.needCheckAndPlan":
      "Die Zeiterfassung muss noch von der Planung geprüft werden",
    "Hours.checkedAndNeedPlan":
      "Die Zeiterfassung ist von der Planung genehmigt und muss noch von der Finanzabteilung geprüft werden",
    "Hours.checkedAndPlanned":
      "Die Zeiterfassung wurde von der Planung und der Finanzabteilung genehmigt",
    "Hours.worked": "Gearbeitet",
    "Hours.approvedExported": "Status",
    "Hours.wasScheduleBefore.warning":
      "'[full_name_employee]' wurde zuvor für diesen Dienst abgelehnt, sind Sie sicher, dass Sie den Mitarbeitenden einplanen möchten?",
    "Hours.actions.correction": "Korrektur vornehmen",
    "Hours.actions.comment": "Kommentar hinzufügen",
    "Hours.actions.sendToProcessed": "Senden an bearbeitet",
    "Hours.actions.acceptDeclaration": "Zeiterfassung akzeptieren",
    "Hours.actions.calculateTravelExpenses": "Fahrtkosten berechnen",
    "Hours.actions.acceptDeclaration.allHours": "Deklarierung(en) genehmigen",
    "Hours.actions.acceptDeclaration.allHours.isNotHourOwner":
      "Sie sind nicht der Inhaber der Stunden für {japs} die ausgewählten Bereiche, bitte überprüfen Sie die ausgewählten Zeilen, bevor Sie fortfahren.",
    "Hours.correction": "Neue Zeiterfassung",
    "Hours.actions.invoice.allHours": "Ausgewählte Stunden in Rechnung stellen",
    "Hours.correction.success": "Korrektur wurde vorgenommen",
    "Hours.correction.error": "Die Korrektur konnten nicht vorgenommen werden",
    "Hours.update.success": "Die Stunden wurden angepasst",
    "Hours.update.error": "Die Stunden wurden nicht angepasst",
    "Hours.newDeclaration": "Neue Zeiterfassung",
    "Hours.invitePending": "Einladung ausstehend",
    "Hours.appliedPending": "Anmeldung ausstehend",
    "Hours.proposed": "Vorgeschlagen",
    "Hours.proposal": "Vorschlag",
    "Hours.activeFixedRate": "Festbetrag",
    "Hours.applicableTravelTime": "Fahrtzeit",
    "Hours.totalNetCost": "Nettokosten",
    "Hours.activePaymentType": "Angewandte Zahlungsmethode",
    "Hours.totalTravelTime": "Fahrtzeit",
    "Hours.numberJobApplications": "Wie oft gearbeitet",
    "Hours.age_hourly_rate": "Auszahlung nach Alter",
    "Hours.contract_based": "Vertraglich",
    "Hours.highestHourlyRate": "Stundenlohn am Ende des Zeitraums",
    "Hours.hoursHourlyRate": "Stundenzahl mit Stundensatz",
    "Hours.hoursReserveRate": "Stundenzahl mit festem Reservetarif",
    "Hours.hoursFixedRate": "Stundenzahl mit Festpreis",
    "Hours.totalHourlySalary": "Bruttogehalt mit Stundensatz",
    "Hours.totalReserveSalary": "Gesamterstattung Reserve",
    "Hours.totalFixedSalary": "Gesamterstattung Festpreis",
    "Hours.makeSalaryRun": "Lohnzettel starten",
    "DownloadModal.totalSalary": "Lohnzettel",
    "Hours.correction.info":
      "Die Korrektur wird in die geöffnete Zeiterfassung eingetragen",
    "Hours.correction.disabled": `Sie können die Stunden nicht mehr ändern, da sie bereits von {email} bearbeitet wurden, in diesem Fall können Sie eine Korrektur vornehmen. Gehen Sie zur Registerkarte ‚Bearbeitet' in der Tabelle Intern pro Planungstabelle und klicken Sie auf die Zeiten, um schnell eine Korrektur vorzunehmen.`,
    "Hours.notCalculated":
      "Die Fahrtkosten und die Fahrtzeit konnten nicht automatisch berechnet werden",
    "Hours.isRejected": "Abgelehnt",
    "Hours.emptyState.title": "Oh nein! Wir finden keine Zeiterfassung",
    "Hours.emptyState.description":
      "Aktualisieren Sie die Seite und versuchen Sie es erneut.",
    "Hours.log": "Status",
    "Hours.hour": "Stunden",
    "Hours.startAndEndTime": "Startzeit und Ende",
    "Hours.hoursWorked": "Arbeitsstunden",
    "Hours.extraHours": "Bonusstunden",
    "Hours.rates": "Tarife",
    "Hours.travelExpenses": "Fahrtkosten",
    "Hours.amountOfKm": "Anzahl der km",
    "Hours.publicTransportationCost": "Kosten für öffentliche Verkehrsmittel",
    "Hours.toolTip.title": "Reisespezifikationen",
    "Hours.tooltip.car": "Auto",
    "Hours.tooltip.license": "Führerschein",
    "Hours.tooltip.publicTransportCard": "ÖPNV",
    "Hours.notOwner":
      "Sie können die Zeiterfassung nicht anschauen, die Zeiterfassung wird vom Inhaber des Dienstes überprüft",
    "Hours.supplier": "Lieferant",
    "Hours.singles": "Pro Einplanung",
    "Hours.singles.title": "Intern pro Einplanung",
    "Hours.totals.title": "Intern pro Mitarbeitenden",
    "Hours.workedHoursAndBreakHours": `- Geplante Stunden: {plannedHours}<br/>- Anmeldezeit: {checkIn}<br/>- Registrierte Stunden: {clockedHours}<br/>- Definitive Stunden: {definitiveHours}<br/>- Arbeitsstunden: {workedHours}<br/>- Pausenstunden: {breakCompensation}<br/>- Abmeldezeit(manager): {sign_off_time}<br/>- Abmeldezeit(Mitarbeiter): {employee_sign_off_time}`,
    "Hours.workedHoursAndBreakHours.allHours": `- Geplante Stunden: {plannedHours}<br/>- Anmeldezeit: {checkIn}<br/>- Registrierte Stunden: {clockedHours}<br/>- Definitive Stunden: {definitiveHours}<br/>- Vorgeschlagene Stunden: {suggestedHours}<br/> - Anmerkung: {comment}`,
    "Hours.travelTimeAndApplicableTravelTime": `- Fahrtzeit: {travelTime}<br/>- Relevante Fahrzeit: {applicableTravelTime}`,
    "Hours.travelDistanceAndTravelDistanceApplicable":
      "Anzahl km {travelDistance}, relevante km {travelDistanceApplicable}",
    "Hours.archive.confirmation": `Sie sind dabei,
      {Items, Plural,
        one {# Zeiterfassung}
        other {# Zeiterfassung}    
      } zu {archive, select,
        true {zu-archivieren}
        false {archivieren}
      }, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Hours.proposedDate": "Vorgeschlagen am",
    "Hours.registeredDate": "Registriert am",
    "Hours.invitedDate": "Eingeladen am",
    "Hours.toInvoice": "In Rechnung zu stellen",
    "Hours.invoiced": "In Rechnung gestellt",
    "Hours.initialCheck": "Erste Kontrolle",
    "Hours.feebackLoopStatus": "Status Prüfzeitplan",
    "Hours.suggestionMade": "Abgegebener Kommentar",
    "Hours.feedbackLoopStatus.not_started": "Noch nicht angefangen",
    "Hours.feedbackLoopStatus.active": "Aktiv",
    "Hours.feedbackLoopStatus.approved": "Angenommen",
    "Hours.feedbackLoopStatus.rejected": "Abgelehnt",
    "Hours.feedbackLoopStatus.expired": "Abgelaufen",
    "Hours.options.sendToFinqle": "Senden an Finqle", 
    "Hours.sendToFinqleSuccess": "De geselecteerde uren en kosten zijn naar Finqle verstuurd. Staat de Billing request nog niet in Finqle? Kijk dan in het Connections portaal om de fout te bekijken.", 
    "Hours.sendToFinqlePartialSuccess": "Een deel van de geselecteerde uren en kosten is naar Finqle verstuurd. Niet alles kon worden aangemaakt. Bekijk het Connections portaal voor de succesvolle en niet-succesvolle diensten.", 
    "Hours.sendToFinqleFailed": "De geselecteerde uren en kosten zijn niet naar Finqle verstuurd er heeft een probleem plaatsgevonden. Neem contact op met het support team van Fleks.", 
    "Hours.sendToFinqleConfirmTitle": "Bist du sicher ?",
    "Hours.sendToFinqleConfirmDescription": "Die Stunden werden an finqle gesendet, bereits gesendete Schichten werden nicht erneut gesendet.",
    "Hours.startFeedbackLoop.selectedJapOwn": `Sie haben {japWithoutShared, plural,
        one { # eigene Einplanung }
        other { # eigene Einplanungen }
      } ausgewählt, können aber den Prüfzeitplan nicht versenden. Dazu dürfen Sie nur die geteilten Dienste auswählen.`,
    "Hours.startFeedbackLoop.selectedJapNotHourOwner": `Sie haben {japWithoutShared, plural,
        one { # eigene Einplanung }
        other { # eigene Einplanungen }
      } ausgewählt, können aber den Prüfzeitplan nicht versenden. Dazu müssen Sie Inhaber der Stunden sein.`,
    "Hours.startFeedbackLoop.selectedJapWithFeedbackloopActive": `Sie haben {japWithFeedbackloopActive, plural,
        one { # Einplanung }
        other { # Einplanungen }
      } ausgewählt, die bereits gesendet wurden. Sie können diese Teile nicht erneut senden. Wählen Sie geteilte Dienste aus, für die noch kein Prüfzeitplan gesendet wurde.`,
    "Hours.startFeedbackLoop.empty.title": "Prüfzeitplan senden",
    "Hours.startFeedbackLoop.empty.subtitle": `Sie haben {jap, plural, one { # Einplanung} other { # Einplanungen} } ausgewählt, um die geleisteten Arbeitsstunden überprüfen zu lassen und um Genehmigung oder Feedback anzufordern`,
    "Hours.startFeedbackLoop.modal.title": "An geprüft senden",
    "Hours.startFeedbackLoop.modal.subtitle": `Sie haben {jap, plural, one { # Einplanung} other { # Einplanungen} } zur Überprüfung gesendet. Wählen Sie eine Frist und Verbindung(en) aus, um die Stunden überprüfen zu lassen.`,
    "Hours.startFeedbackLoop.modal.deadline": "Frist",
    "Hours.startFeedbackLoop.modal.error.deadline":
      "Wählen Sie die richtige Frist aus",
    "Hours.startFeedbackLoop.modal.button.titleSend": "Stunden senden",
    "Hours.startFeedbackLoop.modal.checkbox.selectAll":
      "Alle Verbindungen auswählen",
    "Hours.statusFeedbackLoop.no_feedback.hourOwner": "Geprüft",
    "Hours.statusFeedbackLoop.no_feedback.hourSupplier": `Geprüft von {hourOwner}`,
    "Hours.statusFeedbackLoop.feedback_requested.hourSupplier":
      "Überprüfen Sie die Stunden",
    "Hours.statusFeedbackLoop.feedback_added.hourSupplier":
      "Vorschlag hinzugefügt",
    "Hours.statusFeedbackLoop.feedback_sent.hourOwner":
      "Vorschlag gesendet von {hourSupplier}",
    "Hours.statusFeedbackLoop.feedback_sent.hourSupplier": "Vorschlag gesendet",
    "Hours.statusFeedbackLoop.hours_approved.hourOwner": `Genehmigt von {hourSupplier}`,
    "Hours.statusFeedbackLoop.automatically_accepted.hourOwner":
      "Überprüfungszeitraum abgelaufen",
    "Hours.statusFeedbackLoop.automatically_accepted.hourSupplier":
      "Automatisch akzeptiert",
    "Hours.statusFeedbackLoop.hourApprovalRequest":
      "Stundenkontrolle angefordert",
    "Hours.statusFeedbackLoop.suggestionApproved": "Vorschlag genehmigt",
    "Hours.statusFeedbackLoop.suggestionDenied": "Vorschlag abgelehnt",
    "Hours.statusFeedbackLoop.approved": "Angenommen",
    "Hours.popoverModal.hourSupplier.title": "Einen Vorschlag senden",
    "Hours.popoverModal.hourOwner.title": "Angeforderter Vorschlag",
    "Hours.popoverModal.hourSupplier.titleButton.add": "Vorschlag hinzufügen",
    "Hours.popoverModal.hourSupplier.titleButton.approved":
      "Stunden genehmigen",
    "Hours.popoverModal.hourOwner.titleButton.accept": "Vorschlag annehmen",
    "Hours.popoverModal.hourOwner.titleButton.decline": "Ablehnen",
    "Hours.popoverModal.hourOwner.titlePopConfirm.decline":
      "Sie sind dabei, den Vorschlag abzulehnen, möchten Sie fortfahren?",
    "Hours.popoverModal.hourOwner.titlePopConfirm.accept":
      "Sie sind dabei, den Vorschlag anzunehmen, möchten Sie fortfahren?",
    "Hours.allHours.button.sendReviewedHours": "Überprüfte Stunden senden",
    "Hours.allHours.button.error.sendReviewedHours":
      "Beurteilte Stunden können nur gesendet werden, wenn sie genehmigt wurden oder ein Vorschlag hinzugefügt wurde.",
    "Hours.invoices": "Rechnungen",
    "Hours.invoices.title": "Rechnungen",
    "Hours.invoices.actions.makeCounterProposal": "Gegenvorschlag machen",
    "Hours.invoices.freelancers": "Freiberufler",
    "Hours.invoices.lastProposal": "Neuester Vorschlag",
    "Hours.invoices.invalidatedProposals": "Abgelaufene Vorschläge",
    "Hours.invoices.creater": "Erstellt von",
    "Hours.invoices.breakTime": "Pausenzeiten",
    "Hours.invoices.breakDuration": "Pausenstunden",
    "Hours.invoices.currentDeclaration": "Aktueller Vorschlag",
    "Hours.invoices.newCorrection": "Neuer Vorschlag",
    "Hours.invoices.sendCounterProposal": "Gegenvorschlag senden",
    "Hours.invoices.modal.counterProposal.title": "Gegenvorschlag erstellen",
    "Hours.invoices.accepted-success": "Rechnung wurde akzeptiert",
    "Hours.invoices.created-success": "Gegenvorschlag wurde erstellt",
    "Hours.invoices.viewInvoices": "Rechnung ansehen",
    "Hours.correctionModal.adjustHoursQuickly": "Stunden schnell anpassen",
    "Hours.correctionModal.markAsApproved": "Als genehmigt markieren",
    "Hours.correctionModal.textButton.approve": "Genehmigen",
    "Hours.correctionModal.textButton.updateAndApprove":
      "Aktualisieren und genehmigen",
    "Hours.feedbackLoop.title.button": "Prüfzeitplan senden",
    "Hours.externals": "Extern pro Einplanung",
    "Hours.externals.amountOfHours": "Stunden",
    "Hours.externals.status.pending": "Überprüfen Sie die Stunden",
    "Hours.externals.status.reviewed": "Angenommen",
    "Hours.externals.status.processed": "Verarbeitet",
    "Hours.externals.lastProposal": "Neuester Vorschlag",
    "Hours.externals.previousProposals": "Abgelaufene(r) Vorschlag/Vorschläge",
    "Hours.externals.creator": "Erstellt von",
    "Hours.externals.billableHours": "Stunden",
    "Hours.externals.creationDate": "Erstellungsdatum",
    "Hours.creationDetails": "Details zur Erstellung",
    "Hours.externals.approvalDate": "Angenommen am",
    "Hours.externals.rejectionDate": "Abgelehnt am",
    "Hours.externals.currentProposal": "Aktueller Vorschlag",
    "Hours.externals.employeeName": "Name",
    "Hours.externals.newProposal": "Neuer Vorschlag",
    "Hours.externals.status": "Status",
    "Hours.externals.accept.confirmation": `Sie sind dabei,
      {items, plural,
        one {# Vorschlag}
        other {# Vorschläge}    
      } anzunehmen
      }, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Hours.externals.accept": "Vorschlag annehmen",
    "Hours.externals.accepted-success": "Vorschläge wurde angenommen",
    "Hours.externals.editBillableHours":
      "Mit diesen Informationen sind abrechenbare Stunden verbunden. Aktualisieren Sie auch die abrechenbaren Stunden.",
    "Hours.externals.createBillableHours": "Abrechenbare Stunden erstellen",
    "Hours.externals.currentBillableHours": "Aktuelle abrechenbare Stunden",
    "Hours.externals.newBillableHours": "Neue abrechenbare Stunden",
    "Hours.externals.sendBillableHours": "Abrechenbare Stunden senden",
    "Single.plannedHours": "Geplante Stundenzahl",
    "Single.totalWorked": "Gesamtzahl der Dienste",
    "Single.totalHours": "Gesamtstundenzahl",
    "Single.lastActive": "Zuletzt registriert",
    "Single.calendar": "Kalender",
    "Single.overview": "Übersicht",
    "Single.details": "Details",
    "Single.contract": "Vertrag",
    "Single.history": "Vergangenheit",
    "Single.media": "Medien",
    "Single.comments": "Anmerkungen",
    "Single.hours-registration": "Stundenregistrierung",
    "Single.jobs": "Dienste",
    "Single.pools": "Pools",
    "Single.dateRegistered": "Registrierungsdatum",
    "Single.payment": "Zahlung",
    "Single.candidates": "Kandidaten",
    "Details.general": "Allgemeines",
    "Details.characteristics": "Merkmale",
    "Details.clothingSize": "Konfektionsgröße",
    "Details.length": "Körpergröße",
    "Details.shoeSize": "Schuhgröße",
    "Details.piercingsTattoo": "Piercings/Tätowierungen",
    "Details.no": "Nein",
    "Details.yes": "Ja",
    "Details.planIn": "Einplanen",
    "Details.notVisible": "Ja, aber nicht sichtbar",
    "Details.travel": "Fahren",
    "Details.publicTransport": "Öffentliche Verkehrsmittel",
    "Details.pt": "ÖPNV",
    "Details.week": "Woche",
    "Details.weekend": "Wochenende",
    "Details.license": "Führerschein",
    "Details.car": "Auto",
    "Details.notAlways": "Gelegentlich",
    "Details.normal": "Normal",
    "Details.discount": "Rabatt",
    "Details.sometimes": "Gelegentlich",
    "Details.unknown": "Unbekannt",
    "Details.education": "Ausbildung",
    "Details.hearAboutUs": "Wie sind Sie auf uns aufmerksam geworden?",
    "Details.friend": "Freunde",
    "Details.socialMedia": "Social Media",
    "Details.other": "Sonstiges",
    "Details.branch": "Funktionen",
    "Details.trainings": "Schulungen",
    "Details.qualifications": "Qualifikationen",
    "Details.languages": "Sprachen",
    "Details.languages.adjust": "Passen Sie die Sprachinformationen an",
    "Details.employeeLanguages.addOne": "Sprachen hinzufügen",
    "Details.information": "Informationen",
    "Details.payment": "Bezahlung",
    "Details.description": "Beschreibung",
    "Details.briefing": "Briefing",
    "Details.questions": "Fragen",
    "Details.numberOfQuestions": "Anzahl der Fragen",
    "Details.evaluation": "Auswertung",
    "Details.evaluationLink": "Link",
    "Details.no-evaluation": "Bewertungslink ausfüllen",
    "Details.contact-person": "Mitarbeitenden kontaktieren",
    "Details.documents": "Dokumente",
    "Details.registered": "Registriert",
    "Details.proposed": "Vorgeschlagen",
    "Media.photo": "Profilfoto",
    "Media.dates": `{date} von {start_time} bis {end_time}`,
    "Media.filters.jobFullAddress": `Dienstadresse`,
    "Details.applications": "Anmeldungen",
    "Details.invitations": "Eingeladen",
    "Details.scheduled": "Eingeplant",
    "Details.team": "Team",
    "Details.cancelled": "Storniert",
    "Details.proposals": "Vorgeschlagen",
    "Details.customNumber": "Ausweisnummer",
    "Creation.jobs.pool.predefined": "Standardpool (optional)",
    /* The common strings are using text in different views. */
    "common.all": "Alle",
    "common.doanloadAll": "Alles herunterladen",
    "Common.downloadAll": "Alles herunterladen",
    "Common.downloadMedia": "Medien herunterladen",
    "Common.downloadMedia.declarationPhoto": "Workflow-Fotos",
    "Common.downloadMedia.publicTransport":
      "Quittungen für öffentliche Verkehrsmittel",
    "Common.downloadMedia.otherCosts": "Einnahmen sonstige Kosten",
    "Common.downloadMedia.all": "Alle Arten",
    "Common.downloadMedia.description":
      "Wählen Sie die Bildtypen zum Herunterladen aus:",
    "Common.downloadAllBeta": "Probieren Sie die neue Download-Funktion aus!",
    "Common.downloadAll.confirmation": `Sie sind im Begriff, alle ausgewählten Medientypen herunterzuladen. Sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Common.downloadAll.success": `Wir bereiten eine Datei zum Herunterladen vor, Sie erhalten eine Benachrichtigung und eine E-Mail, wenn die Datei fertig ist`,
    "common.cancel": "Abbrechen",
    "Common.save": "Speichern",
    "Common.saving": "Speichern",
    "Common.savingChangesError":
      "Speichern der Änderungen konnte nicht durchgeführt werden",
    "Common.yes": "Ja",
    "Common.no": "Nein",
    "Common.cancel": "Abbrechen",
    "Common.lastname": "Nachname",
    "Common.usedForPlanning": "Sichtbar während der Planung",
    "Common.notUsedForPlanning": "Nicht sichtbar während der Planung",
    "Common.shareable": "Feld geteilt mit Verbindung(en)",
    "Common.notShareable": "Internes Feld",
    "Common.name": "Name",
    "Common.city": "Stadt",
    "Common.age": "Alter",
    "Common.gender": "Geschlecht",
    "Common.email": "E-Mail-Adresse",
    "Common.mailbox": "E-Mail-Adresse für allgemeine Benachrichtigungen",
    "Common.noReply": "No reply E-Mail-Adresse",
    "Common.zipCode": "PLZ",
    "Common.address": "Adresse",
    "Common.houseNumber": "Hausnummer",
    "Common.houseNumberExt": "Zusatz",
    "Common.postCode": "PLZ",
    "Common.country": "Land",
    "Common.saveAndExit": "Speichern und schließen",
    "Common.save-success": "Gespeichert",
    "Common.save-error": "Beim Speichern ist ein Fehler aufgetreten",
    "Common.try-later": "Problem, versuchen Sie es später noch einmal",
    "Common.required": "Dieses Feld ist ein Pflichtfeld",
    "Common.min": "Das Passwort muss mindestens [Anzahl] Zeichen enthalten",
    "Common.max": "Dieses Feld darf nicht mehr als {Anzahl} Zeichen enthalten",
    "Common.inputMinLength": `Dieses Feld muss mindestens {Anzahl} Zeichen enthalten`,
    "Common.inputMaxLength": `Dieses Feld darf höchstens {Anzahl} Zeichen enthalten`,
    "Common.inputFixedLenght": `Dieses Feld muss genau {number} Zeichen enthalten`,
    "Common.created-success": "Erstellt",
    "Common.created-error": "Beim Erstellen ist ein Fehler aufgetreten",
    "Common.maxLength": "Die maximale Zeichenanzahl wurde überschritten",
    "Common.length": "Keine Übereinstimmung mit der erforderlichen Länge",
    "Common.copyLink": "Link kopieren",
    "Common.clickToCopy": "Klicken, um den Link zu kopieren",
    "Common.copiedLink": "Der Link wurde in die Zwischenablage kopiert",
    "Common.load-error": "Beim Laden der Daten ist ein Fehler aufgetreten",
    "Common.seeComments": "Siehe Kommentare",
    "Common.hideComments": "Kommentare ausblenden",
    "Common.errorOccurred": "Es ist ein Fehler aufgetreten",
    "Common.exit": "Beenden",
    "Common.addFilter": "Anwenden",
    "Common.archive": "Archivieren",
    "Common.filters.resetAll": "Filter löschen",
    "Common.edit": "Anpassen",
    "Common.editTime": "Zeit ändern",
    "Common.delete": "Löschen",
    "Common.next": "Nächste",
    "Common.duplicate": "Duplizieren",
    "Common.viewJobs": "Dienste anzeigen",
    "Common.search": "Suchen",
    "Common.period": "Zeitraum",
    "Common.periodDays": "Zeitraum (Tage)",
    "Common.specifications": "Spezifikationen",
    "Common.periodFrom": "Start Zeitraum",
    "Common.periodTill": "Ende Zeitraum",
    "Common.remove": "Löschen",
    "Common.optional": "Optional",
    "Common.archived": "Archiviert",
    "Common.returned": "Material zurückgegeben",
    "Common.noShow": "Nichterscheinen",
    "Common.calledInSick": "Krankgemeldet",
    "Common.undoCancellation": "Stornierung widerrufen",
    "Common.stepBack": "Zurückgehen",
    "Common.watchOut": "Achtung!",
    "Common.export": "Exportieren",
    "Common.exportAll": "Ergebnisse exportieren ({insgesamt})",
    "Common.exportAllNoTotal": "Ergebnisse exportieren",
    "Common.exportAllBeta": "Testen Sie den neuen Beta-Export!",
    "Common.exportAll.confirmationAll": `Sie sind dabei, alle Daten zu exportieren, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Common.exportAll.confirmation": `Sie sind dabei, alle Daten zu exportieren ({insgesamt}), sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Common.exportAll.success": `Wir exportieren gerade Ihre Daten, Sie erhalten eine E-Mail und eine Benachrichtigung, wenn die Datei fertig ist`,
    "Common.exportPdf": "PDF drucken",
    "Common.exportPdfExplain": "Druckauswahl als PDF",
    "Common.exportPdfAll": "Druckauswahl als PDF ({total})",
    "Common.exportPdfMessageAll":
      "Entsprechend dem Stand an der Stelle der entsprechenden Auswahl von ({total}) nach dem Drucken in PDF.",
    "Common.exportPdfMessage":
      "Je nach Download-Link zum pdf in der Benachrichtigungsbox, wann dies klar ist.",
    "Common.woman": "Frau",
    "Common.women": "Frau",
    "Common.man": "Mann",
    "Common.men": "Mann",
    "Common.applied": "Registriert",
    "Common.invited": "Eingeladen",
    "Common.scheduled": "Eingeplant",
    "Common.savePreset": "Speichern",
    "Common.unarchive": "De- Archivieren",
    "Common.loadMore": "Mehr laden ({insgesamt})",
    "Common.seeProfile": "Zum Profil",
    "Common.moreDetails": "Mehr Details",
    "Common.seeJob": "Zum Dienst",
    "Common.noEmployeesScheduled": "Es wurden keine Mitarbeitende eingeplant",
    "Common.noExternalsScheduled": "Keine externen Mitarbeitenden eingeplant",
    "Common.noFreelancersScheduled": "Keine Freiberufler eingeplant",
    "Common.noApplications": "Keine Anmeldungen",
    "Common.noInvites": "Keine Mitarbeitende eingeladen",
    "Common.noCancelledApplications": "Keine stornierten Anmeldungen",
    "Common.noQualifiedCandidates":
      "Keine qualifizierten Mitarbeitenden gefunden",
    "Common.noEmployees": "Kein(e) Mitarbeitenden",
    "Common.noAppliedFreelancers": "Keine angemeldeten Freiberufler",
    "Common.noPlannedFreelancers": "Keine eingeplanten Freiberufler",
    "Common.noFreelancers": "Kein(e) Freiberufler",
    "Common.noConnections": "Keine Verbindungen",
    "Common.noProjects": "Keine Projekte gefunden",
    "Common.noEmployeesProposed": "Keine Kandidaten vorgeschlagen",
    "Common.hours": `{hours, plural,
        =0 { keine Stunden }
        one { Stunde }
        other { Stunden } 
      }`,
    "Common.close": "Schließen",
    "Common.allDay": "Ganztätig",
    "Common.accept": "Annehmen",
    "Common.reject": "Ablehnen",
    "Common.download": "Herunterladen",
    "Common.pagination": "{first, number}-{last, number} der {total, number}",
    "Common.settings": "Einstellungen",
    "Common.logged": "Angemeldet als",
    "Common.selected": "{items} ausgewählt",
    "Common.unsubscribe": "Abmelden",
    "Common.makeAnExport": "Export erstellen",
    "Common.details": "Details",
    "Common.Share": "Dienst teilen",
    "Common.ShareJobs": "Dienste teilen",
    "Common.sendToMarketplace": "Auf dem Marktplatz veröffentlichen",
    "Common.status.job_applied": "Registriert",
    "Common.status.job_accepted": "Eingeplant",
    "Common.status.quiz_completed": "Quiz gemacht",
    "Common.status.quiz_complete": "Quiz gemacht",
    "Common.status.check_in": "Angemeldet",
    "Common.status.check_in_location": "Am Standort",
    "Common.status.upload_photo": "Fotos hochgeladen",
    "Common.status.evaluation": "Auswertung",
    "Common.status.check_out": "Ausgecheckt",
    "Common.status.hour_declaration": "Zeiterfassung gesendet",
    "Common.invalidStates.auto_canceled": "Nicht ausgewählt",
    "Common.invalidStates.cancelled": "Storniert",
    "Common.invalidStates.rejected": "Abgelehnt",
    "Common.invalidStates.expired": "Abgelaufen",
    "Common.invalidStates.refused": "Abgelehnt",
    "Common.invalidStates.already_scheduled": "Schon eingeplant",
    "Common.invalidStates.unsubscribed": "Abgemeldet",
    "Common.invalidStates.sick": "Krankgemeldet",
    "Common.invalidStates.no_show": "Nichterscheinen",
    "Common.invalidStates.cancelled_by_supplier":
      "Storniert von der Verbindung",
    "Common.invalidStates.cancelled_by_owner": "Vom Arbeitgeber storniert",
    "Common.goToNewTables": "Möchten Sie die neuen Tabellen ausprobieren?",
    "ClientsModal.title": "Auftraggeber exportieren",
    "Common.goToNewPage": "Möchten Sie die neue Seite ausprobieren?",
    "Common.goToOldDesigns": "Brauchen Sie noch die alten Entwürfe?",
    "Common.basicDetails": "Grunddaten",
    "Common.finance": "Finanzen",
    "Common.yesterday": "Gestern",
    "Common.today": "Heute",
    "Common.adjustAvailability": "Verfügbarkeit anpassen",
    "Common.todayAt": "Heute um {Zeit}",
    "Common.contacted": "Kontakt stattgefunden",
    "Common.customNumber": "Ausweisnummer",
    "Common.verify": "Verifizieren",
    "Common.uploadImageOnly": "Sie können nur ein Bild hochladen!",
    "Common.uploadSmallerImage": "Bild muss kleiner als 2 MB sein!",
    "Common.categories": "Kategorien",
    "Common.functions": "Nach Funktion",
    "Common.seeDetails": "Details ansehen",
    "Common.shouldBeNumber": "Dieses Feld muss eine Zahl sein",
    "Common.permissions": "Ermächtigung",
    "Common.noProposals": "Kein Vorschlag erstellt",
    "Common.addingFiles": "Dateien hinzufügen",
    "Common.optionalInformation": "Optionale Informationen",
    "Common.introduction": "Einführung",
    "Common.seeMore": "Erweiterte Suche",
    "Common.EmployeeInformation": "Mitarbeitende Informationen",
    "Common.noBillableHours": "Keine externen Stunden",
    "Common.allSpotsFilled": "Alle Stellen des Dienstes sind besetzt",
    "Common.uploadedFiles": "Dateien hinzugefügt",
    "Common.documents": "Anvullende Dokumente (maximal 5)",
    "Common.documentsDescription":
      "Laden Sie hier aanvullende PDF-Dokumente (Lebenslauf & Zeugnisse etc.).",
    "Common.nameAlreadyTaken":
      "Der Name wird bereits verwendet. Wählen Sie einen anderen Namen oder bearbeiten Sie den alten Namen",
    "Common.jobOwner": "Dienstinhaber",
    "Common.employeeOwner": "Registriert bei",
    "Common.exceptionStateName": "Sonderstatus",
    "Common.exceptionStateId": "Sonderstatus ID",
    "Common.invalidEmail": "Die E-Mail-Adresse ist ungültig",
    "Common.invalidGender":
      "Das Geschlecht ist ungültig (Option ist 'Männer' oder 'Frauen')",
    "Common.invalidCar":
      "Ungültiges Auto (die Optionen sind 'Ja', 'Nein', 'Nicht immer' oder 'Gelegentlich')",
    "Common.invalidLicense":
      "Die Lizenz ist ungültig (Option ist 'Ja' oder 'Nein')",
    "Common.invalidPublicTransportCard":
      "Ungültige Karte für den öffentlichen Nahverkehr (Option ist 'Nein', 'Woche', 'Wochenende', 'Rabatt' oder 'Normal')",
    "Common.invalidFirstName":
      "Die Spalte mit dem Vornamen muss ausgefüllt werden",
    "Common.invalidCustomNumber":
      "Das bearbeitete Zahlenfeld darf höchstens 50 Zeichen enthalten.",
    "Common.invalidPhoneNumber":
      "Die Telefonnummer ist nicht korrekt, vergessen Sie nicht, nur Ziffern einzugeben sowie die Länderkennnummer am Anfang, beginnend mit '+'",
    "Common.invalidHouseNumber": "Das Feld für die Hausnummer ist keine Nummer",
    "Common.invalidContract":
      "Der Vertragsname ist nicht bei Fleks registriert. Erstellen Sie ihn am Ende der Einstellungen oder überprüfen Sie Ihre Datei auf Tippfehler.",
    "Common.invalidFormatStartDateContract":
      "Das Format für das Startdatum des Vertrags ist ungültig.",
    "Common.invalidFormatEndDateContract":
      "Das Format für das Enddatum des Vertrags ist ungültig.",
    "Common.invalidDependeDateContract":
      "Das Feld für das Startdatum des Vertrags und für das Vertragsende ist erforderlich, wenn der Vertragsname hinzugefügt wird",
    "Common.invalidFormatBirthDate":
      "Das Format des Geburtsdatums ist ungültig",
    "Common.invalidFormatExpiryDate":
      "Das Format des Ablaufdatums ist ungültig",
    "Common.invalidFormatRebateDate": "Das Datum der Lohnsteuer ist ungültig",
    "Common.invalidRebate":
      "Das Eingabefeld für den Lohnabsetzbetrag ist ungültig (Optionen 'Ja' oder 'Nein'x`)",
    "Common.invalidShoeSize": "Die Schuhgröße ist keine Zahl",
    "Common.invalidSocialNumber": "Das Feld für SV ist keine Zahl",
    "Common.invalidEducation":
      "Ein Eingabefeld für die Ausbildung ist ungültig (die Optionen sind Fachhochschule, Universität, Realschule, Keine oder Leer)",
    "Common.invalidShirtSize":
      "Es wurde eine ungültige Shirtgröße angegeben (die Optionen sind S, M, L, XL oder XXL )",
    "Common.invalidLanguages":
      "Die Eingabe für die Sprachen ist obligatorisch und darf nicht leer sein. Die Sprachen müssen im System vorhanden sein (Fleks unterstützt Sprachen wie nl oder en)",
    "Common.invalidJobCategories":
      "Die folgende(n) Funktionsgruppe(n) wurden im System nicht gefunden: {categories}",
    "Common.invalidFreeFields":
      "Folgende(s) freie(n) Eingabefeld(er) haben Werte, die nicht mit den voreingestellten Werten im System übereinstimmen: {freefields}",
    "Common.invalidHeight":
      "Das Eingabefeld für die Körpergröße des Mitarbeitenden muss eine Zahl sein",
    "Common.invalidBankNumber": "Die Kontonummer muss 18 Zeichen betragen",
    "Common.invalidPiercingsTattos": `Es wurde eine ungültige Auswahl für Piercings und Tattoos eingegeben (die Optionen sind "Ja", "Nein", "Ja, aber nicht sichtbar" oder "Unbekannt")`,
    "Common.knowledgebase": "Wissensdatenbank",
    "Common.AssignToTheJob": "Direkt einplanen",
    "Common.AssignToTheJobAndExit": "Einplanen und beenden",
    "Common.kmDriven": "Gefahrene km",
    "Common.searchProject": "Projekt suchen",
    "Common.reset2fa": "Methode 2FA zurücksetzen",
    "Common.reset2fa.message.confirm":
      "Sie sind dabei, die Authentifizierungsmethode dieses Benutzers neu einzurichten. Wenn er sich das nächste Mal bei Fleks anmeldet, muss er eine neue Authentifizierungsmethode einrichten. Möchten Sie fortfahren?",
    "Common.additionalDates": "Zusätzliche Daten",
    "Common.sharedWith": "Geteilt mit",
    "Common.empty": " ",
    "HoursModal.title": "Daten exportieren",
    "JobsModal.title": "Daten exportieren",
    "ProjectsModal.title": "Daten exportieren",
    "shiftsModal.title": "Einplanungen exportieren",
    "DownloadModal.content":
      "Ihre aktuelle Auswahl wird als Excel-Datei exportiert.",
    "DownloadModal.numberItems":
      "Dies ist die Anzahl der Items, die exportiert werden: ",
    "DownloadModal.customExportHere": "Passen Sie hier Ihren Export an:",
    "DownloadModal.fullTable": "Standardexport",
    "DownloadModal.fullData": "Vorab exportieren",
    "DownloadModal.salaryRun": "Lohnzettel",
    "DownloadModal.simplifiedSalaryRun": "Lohnzettel vereinfacht",
    "DownloadModal.invoiceInformation": "Rechnungsinformationen",
    "DownloadModal.attendanceList": "Anwesenheitsliste",
    "JobsModal.projectName": "Name des Projekts",
    "JobsModal.projectNumber": "Projektnummer",
    "JobsModal.startDate": "Startdatum",
    "JobsModal.endDate": "Enddatum",
    "JobsModal.city": "Stadt",
    "JobsModal.address": "Adresse",
    "JobsModal.name": "Name",
    "JobsModal.prefix": "Namenszusatz",
    "JobsModal.lastName": "Nachname",
    "JobsModal.phoneNumber": "Telefonnummer",
    "Employees.phoneNumber.2": "Telefonnummer",
    "Employees.invalidPhone": "Ungültige Telefonnummer",
    "Employees.updateFunctions": "Kategorien hinzufügen",
    "JobsModal.ShoeSize": "Schuhgröße",
    "JobsModal.height": "Körpergröße",
    "JobsModal.note": "Nachricht",
    "ProjectsModal.projectName": "Projektname",
    "ProjectsModal.clientName": "Name Auftraggeber",
    "ProjectsModal.jobCategoryName": "Kategoriename",
    "ProjectsModal.jobsNumber": "Anzahl der Dienste",
    "ProjectsModal.jobsPending": "Anzahl ausstehender Dienste",
    "ProjectsModal.projectManager": "Projektleiter",
    "ProjectsModal.startDate": "Startdatum",
    "ProjectsModal.endDate": "Enddatum",
    "ProjectsModal.projectNumber": "Projektnummer",
    "Contract.no": "Nein",
    "Contract.yes": "Ja",
    "Contract.essentials": "Grundlagen",
    "Contract.bankInformation": "Bank Informationen",
    "Contract.tax": "Steuer / Einkommenssteuer",
    "Contract.contracts": "Verträge",
    "Contract.active": "Aktiv?",
    "Contract.signed": "Unterzeichnet?",
    "Contract.contractPerWeek": "Vertragsstunden pro Woche",
    "Contract.howContractsWork": "Wie funktioniert das?",
    "Contract.howContractsWorkDescription":
      "Wenn Sie mit einem Mitarbeitenden vereinbart haben, dass er oder sie eine Standardstundenzahl pro Jahr/Monat/Woche arbeiten muss, können Sie diese Einstellung verwenden. Fleks arbeitet in der Regel mit Vertragsstunden pro Woche.",
    "Contract.contractDays": "Vereinbarte Vertragstage",
    "Contract.contractHours": "Vertragsstunden",
    "Contract.minContractHours": "Vertragsstunden (min)",
    "Contract.maxContractHours": "Vertragsstunden (max)",
    "Contract.noValidContract": "Kein Vertrag",
    "Contract.contractHours.hours": `{hours, plural,
        one { # Stunde }
        other { # Stunden }
      }`,
    "Contract.isSigned": "Unterzeichnet?",
    "Contract.type": "Vertragstyp",
    "Contract.duration-start": "Startdatum",
    "Contract.duration-end": "Enddatum",
    "Contract.month": "Monat(e)",
    "Contract.new": "Neuer Vertrag",
    "Contract.noHoursContract": "Vertrag Null Stunden",
    "Contracts.no-contract": "Ohne Vertrag",
    "Contracts.upcoming-to-expire": "Auslaufend",
    "Contracts.no-signed": "Noch nicht unterschrieben",
    "Contracts.documents": "Dokumente",
    "Contracts.hourPerWeek.onlyNumbers":
      "In dieses Feld können nur Ziffern eingegeben werden",
    "NewProjects.addLocation": "Festen Standort hinzufügen",
    "NewProjects.addBriefing": "Briefing hinzufügen (pdf)",
    "NewProjects.addQuiz": "Erstellen Sie sofort ein Quiz",
    "NewProjects.addDeclaration": "Antragsformular erstellen",
    "NewProjects.chooseAClient": "Auftraggeber auswählen",
    "NewProjects.projectDescription": "Projektbeschreibung",
    "NewProjects.addAnImage": "Bild hinzufügen",
    "NewJobs.name": "Name",
    "NewJobs.applicants": "Anzahl Mitarbeitende",
    "NewJobs.address": "Adresse",
    "NewJobs.city": "Stadt",
    "NewJobs.time": "Zeit",
    "NewJobs.startDate": "Startdatum",
    "NewJobs.criteria1": "Quiz erforderlich?",
    "NewJobs.criteria2": "Reservedienst?",
    "NewJobs.workflow": "Arbeitsablauf-Vorlage",
    "NewJobs.isSuccessful": "Dienst erstellt",
    "NewJob.createJobs": "Dienst(e) erstellen",
    "NewJob.ongoingProjects": "1. Projekte auswählen",
    "NewJob.information": "2. Informationen",
    "NewJob.overview": "3. Übersicht",
    "NewStandardJob.information": "1. Informationen",
    "NewStandardJob.overview": "2. Übersicht",
    "NewJob.next": "Projekt auswählen",
    "NewJob.selectProject": "Ein laufendes Projekt auswählen",
    "NewJob.filterByProject": "Nach Projektname filtern",
    "NewJob.filterByClient": "Nach Auftraggeber filtern",
    "NewJob.addToConcepts": "Zu den Konzepten hinzufügen",
    "NewJob.concepts.list": "Konzepte ({Anzahl})",
    "NewJob.concepts.feedback":
      "Sie haben {Anzahl} Konzepte hinzugefügt, scrollen Sie nach unten, um sie anzuschauen.",
    "NewJob.clearForm": "Entfernen",
    "NewJob.useStandardJob": "Standarddienst verwenden",
    "NewJob.useStandardJob.description": "Standarddienst auswählen",
    "NewJob.useStandardJob.selectClient": "Auftraggeber auswählen",
    "NewJob.useStandardJob.selectAStandardJob": "Standarddienst auswählen",
    "NewJob.useStandardJob.useThis": "Standarddienst verwenden",
    "NewJob.addTeamInstructions": "Teamanweisungen hinzufügen (pdf)",
    "NewJob.addExtraDocument": "Zusätzliche Dokumente hinzufügen",
    "NewJob.addAutoScheduled": "Genehmigungseinstellungen für die Planung",
    "Step2.fillIn": "Bitte eingeben",
    "Step2.general": "Allgemeines",
    "Step2.startDate": "Startdatum:",
    "Step2.startEndTime": "Startzeit und Ende:",
    "Step2.name": "Name:",
    "Step2.repeatJob": "Wiederkehrender Dienst",
    "Step2.notRepeat": "Der Dienst wird nicht wiederholt",
    "Step2.daily": "Täglich",
    "Step2.weekly": "Wöchentlich",
    "Step2.monthly": "Monatlich",
    "Step2.customize": "Wiederkehrender Dienst",
    "Step2.amount": "Anzahl Mitarbeitende:",
    "Step2.criteria": "Schritte für Arbeitsablauf für den Mitarbeitenden",
    "Step2.quiz": "Mitarbeitende muss ein Quiz machen",
    "Step2.reserve": "Dies ist ein Reservedienst",
    "Step2.add": "Hinzufügen",
    "Step2.newJobs": "Die folgende Anzahl an Diensten wird erstellt: ",
    "Step2.newStandardJobs": "Zu erstellende Standarddienste: ",
    "Step2.addAnotherJobs": "Sie können noch %j Dienste hinzufügen",
    "Step2.addAnotherStandardJobs":
      "Sie können noch %j Standarddienste hinzufügen",
    "Step2.totalJobs": "Maximale Anzahl der Dienste: %t (Max 200)",
    "Step2.searchAddress": "Adresse über Maps finden",
    "Step2.createdJobs": "Zu erstellende Dienste:",
    "Step2.createdStandardJobs": "Erstellte Standarddienste:",
    "Step2.previous": "Früheren",
    "Step2.upload": "Dienst(e) erstellen",
    "Step2.selectCriteria": "Arbeitsablauf-Schritte auswählen",
    "Step2.zipCode": "PLZ",
    "Step2.state": "Stadt",
    "Step2.city": "Stadt & PLZ",
    "Step2.ext": "Zusatz",
    "Step2.number": "Hausnummer",
    "Step2.streetName": "Straße",
    "Step2.address": "Adresse",
    "Step2.location": "Standort",
    "Step2.wrong-date": "Das Startdatum muss im laufenden Jahr liegen",
    "Step2.wrong-startTime": "Die Startzeit muss später als 00:00 Uhr sein",
    "Step2.wrong-endTime": "Das Ende muss später als 00:00 Uhr sein",
    "Step2.empty-address": "Sie haben noch keine Adresse angegeben",
    "Step2.empty-city": "Sie haben noch keine Stadt angegeben",
    "Step2.empty-required-fields": "Hausnummer ist ein Pflichtfeld",
    "Step2.empty-workflow":
      "Sie haben noch keine Arbeitsablauf-Vorlage ausgewählt",
    "Step2.empty-category": "Sie haben noch keine Funktion ausgewählt",
    "Step2.empty-name": "Sie haben noch keinen Namen ausgewählt",
    "Step2.importInformation":
      "Stellen Sie sicher, dass das Blatt richtig gelesen wird, indem Sie die folgenden Felder in die erste Zeile setzen: Startdatum, Startzeit, Enddatum, Ende, Anzahl der Mitarbeitenden, Stadt, Straße, Hausnummer, Zusatz, PLZ.",
    "Step2.importWithSheets": "Dienste mit Blättern importieren",
    "Step2.importJobs": "Dienste importieren",
    "Step2.edit": "Anpassen",
    "Step2.importWarning.times": `Die angegebene Zeit ist ungültig`,
    "Step2.importWarning.dates": `Das angegebene Datum ist ungültig`,
    "Step2.importWarning.jobCategory":
      "Die angegebenen Funktionen sind im System nicht bekannt. Überprüfen Sie, ob die Namen übereinstimmen",
    "Step2.importWarning.invalidProject":
      "Die Projektnummer konnte nicht gefunden werden. Überprüfen Sie, ob sie mit der Projektnummer im System übereinstimmt",
    "Step2.importWarning.pools":
      "Die folgenden Pools wurden im System nicht gefunden: {pools}",
    "Step2.endDate": "Dies ist ein Nachtdienst",
    "Step2.useWorkflow": "Arbeitsablauf-Schritte erforderlich",
    "Step2.useWorkflow.false":
      "Von dem Mitarbeitenden wird während des Dienstes keine Aktion erwartet",
    "Step2.useWorkflow.true": "Geben Sie die erforderlichen Schritte an",
    "Step2.useQuiz": "Quiz",
    "Step2.useQuiz.false":
      "Der Mitarbeitende braucht während der Schicht kein Quiz zu machen",
    "Step2.useQuiz.true":
      "Quiz erforderlich, vergessen Sie nicht, es dem Projekt hinzuzufügen!",
    "Step2.useReserve": "Reservedienst",
    "Step2.useReserve.true":
      "Dies ist ein Reservedienst (im Standby-Modus), der Mitarbeitende muss sich nur anmelden",
    "Step2.useReserve.false": "Dies ist kein Reservedienst",
    "Step2.requiredFieldsToUpload":
      "Bitte stellen Sie sicher, dass alle Pflichtfelder ausgefüllt sind",
    "Step2.empty-documents":
      "Stellen Sie sicher, dass alle Dokumente einen Namen und eine Beschreibung haben",
    "NewEmployee.conceptList.textButtonUploadEmployee":
      "Mitarbeitende(n) erstellen",
    "RepeatWindow.defaultRepeated": "Der Dienst wiederholt sich täglich",
    "RepeatWindow.repeat": "Diesen Dienst wiederholen",
    "RepeatWindow.every": "Jeden:",
    "RepeatWindow.day": "Tag(e)",
    "RepeatWindow.week": "Woche/Wochen",
    "RepeatWindow.month": "Monat(e)",
    "RepeatWindow.mon": "Mo",
    "RepeatWindow.tue": "Di",
    "RepeatWindow.wed": "Mi",
    "RepeatWindow.thu": "Do",
    "RepeatWindow.fri": "Fr",
    "RepeatWindow.sat": "Sa",
    "RepeatWindow.sun": "So",
    "RepeatWindow.monday": "Montag",
    "RepeatWindow.tuesday": "Dienstag",
    "RepeatWindow.wednesday": "Mittwoch",
    "RepeatWindow.thursday": "Donnerstag",
    "RepeatWindow.friday": "Freitag",
    "RepeatWindow.saturday": "Samstag",
    "RepeatWindow.sunday": "Sonntag",
    "RepeatWindow.ends": "Ende:",
    "RepeatWindow.never": "Kein",
    "RepeatWindow.occurrences": "Ereignisse",
    "RepeatWindow.on": "Am",
    "RepeatWindow.done": "Erledigt",
    "RepeatWindow.repeatedEvery": "Der Dienst wird wiederholt am/jeden:",
    "RepeatWindow.and": "und",
    "RepeatWindow.occurs": "Die Auswahl wird sich",
    "RepeatWindow.times": "Mal wiederholen",
    "RepeatWindow.until": "Bis",
    "RepeatWindow.after": "Nach",
    "Step3.overview": "Übersicht",
    "Step3.youCreated": "Sie haben Folgendes erstellt",
    "Step3.jobsFor": "Dienste für",
    "Step3.standardJobsFor": "Standarddienste für",
    "Step3.done": "Fertig",
    "EmployeeList.selectCancellation": "Stornieren auswählen",
    "EmployeeList.unDoCancellation": "Stornierung rückgängig machen",
    "EmployeeList.cancelJob": "Stornieren",

    "EmployeeList.reject": "Ablehnen",
    "EmployeeList.noShow": "Nichterscheinen",
    "EmployeeList.calledOff": "Krankgemeldet",
    "EmployeeList.expired": "Registrierung abgelaufen",
    "EmployeeList.archive": "Archivieren",
    "EmployeeList.setException":
      "Als Ausnahme markieren (Ausnahme durch Kommentar spezifizieren)",
    "EmployeeList.calculateTravelExpenses": "Fahrtkosten berechnen",
    "EmployeeList.edit": "Anpassung erstellen",
    "EmployeeList.workingHours": "Arbeitsstunden",
    "EmployeeList.extra": "Extra",
    "EmployeeList.breakHours": "Pausenstunde",
    "EmployeeList.travelTime": "Fahrtzeit",
    "EmployeeList.publicTransport": "Kosten für öffentlichen Nahverkehr",
    "EmployeeList.other": "Sonstiges",
    "EmployeeList.schedule": "Einplanen",
    "EmployeeList.distance": "Entfernung (km)",
    "EmployeeList.search": "Suchen",
    "EmployeeList.open": "Offen",
    "EmployeeList.since": "Seit",
    "EmployeeList.onStandby": "Standby",
    "EmployeeList.activate": "Aktivieren",
    "EmployeeList.reserve": "Reserve",
    "EmployeeList.checkedIn": "Anmelden",
    "EmployeeList.atLocation": "Am Standort",
    "EmployeeList.photos": "Fotos",
    "EmployeeList.areTaken": "Fotos",
    "EmployeeList.checkOut": "Ausgecheckt",
    "EmployeeList.hours": "Stunden",
    "EmployeeList.areSent": "Erklärung",
    "EmployeeList.quiz": "Quiz",
    "EmployeeList.unarchive": "De-archivieren",
    "EmployeeList.notChosen": "Nicht ausgewählt",
    "EmployeeList.jobAccepted": "Dienst angenommen",
    "EmployeeList.evaluation": "Auswertung",
    "EmployeeList.disabledButtonEdit":
      "Dies ist ein externer Mitarbeitender von: {tenantName}. Informationen können nicht bearbeitet werden, nur die Drittpartei kann sie ändern",
    "employee.confirmSaveTitle": "Änderungen speichern",
    "employee.confirmSave": "Möchten Sie die Änderung speichern?",
    "Settings.profileSettings": "Ihr Profil",
    "Settings.actions": "Aktionen",
    "Settings.name": "Name",
    "Settings.phoneNumber": "Telefonnummer",
    "Settings.accountSettings": "Zugangsdaten",
    "Settings.email": "E-Mail-Adresse",
    "Settings.password": "Passwort",
    "Settings.users": "Systembenutzer",
    "Settings.materiallist": "Materialien",
    "Settings.materiallist.new": "Neu",
    "Settings.materiallist.addMaterial": "Neues Material",
    "Settings.materiallist.changeMaterial": "Material ändern",
    "Settings.materiallist.categoryField": "Kategorie",
    "Settings.materiallist.materialName": "Material Name",
    "Settings.materiallist.review": "Rezension", 
    "Settings.materiallist.addCategory": "Neue Kategorie hinzufügen",
    "Settings.materiallist.saveMaterial": "Speichern",
    "Settings.materiallist.cancelMaterial": "Stornieren",
    "Settings.materiallist.remark":"Anmerkung", 
    "Settings.materiallist.deleteTitle":"Material löschen",
    "Settings.materiallist.deleteFirstTextSplitFirst": "Sind Sie sich sicher, dass Sie das entfernen wollen",
    "Settings.materiallist.deleteFirstTextSplitSecond": "?",
    "Settings.materiallist.deleteSecondText": "Das kann nicht rückgängig gemacht werden.",
    "Settings.materiallist.deleteNotification": "Material <b>{item}</b> wurde erfolgreich gelöscht",
    "Settings.materiallist.addNotification": "Material <b>{item}</b> wurde erfolgreich hinzugefügt",
    "Settings.materiallist.editNotification": "Material <b>{item}</b> wurde erfolgreich bearbeitet",
    "Settings.materiallist.example": "E.g. t-shirt",
    "Settings.workflow": "Arbeitsablauf",
    "Settings.defaultWorkflow": "Standard-Workflow",
    "Settings.workflow.new": "Neuen Arbeitsablauf erstellen",
    "Settings.declarationSettings.tittle": "Einstellungen Arbeitsstunden",
    "Settings.declarationSettings.description":
      "Benutzer und Mitarbeitende können die Arbeitszeiten der Dienste in Schritten von <b class='avenir-next-demi-bold>{step} Minuten</b> anpassen",
    "Settings.declarationSettings.edit.description":
      "Benutzer und Mitarbeitende können die Arbeitszeiten der Dienste in folgenden Schritten anpassen:",
    "Settings.declarationSettings.edit.minutes": "Minuten",
    "Settings.declarationSettings.edit.timeInterval": "Zeitintervall",
    "Settings.securitySettings.tittle": "Sicherheit",
    "Settings.securitySettings.description":
      "Die zwei-Faktor-Authentifizierung ist <b class='avenir-next-demi-bold'>{status}</b> für die Administratoren und Planer der Organisation",
    "Settings.securitySettings.edit.2fa.tittle": "2-Faktor-Authentifizierung",
    "Settings.securitySettings.edit.2fa.description":
      "Möchten Sie die 2-Faktor-Authentifizierung für alle Planer und Administratoren Ihres Unternehmens aktivieren?",
    "Settings.securitySettings.enabled": "Aktiviert",
    "Settings.securitySettings.disabled": "Deaktiviert",
    "Settings.standardDeclarationForm": "Standard-Antragsformular",
    "Settings.standardDeclarationForm.description":
      "Dieses Antragsformular wird standardmäßig ausgefüllt, wenn Sie ein Projekt erstellen",
    "Settings.sharedDeclarationForm": "Antragsformular für eingehende Dienste",
    "Settings.sharedDeclarationForm.description":
      "Dieses Antragsformular wird standardmäßig für eingehende Dienste von Verbindungen verwendet",
    "Settings.connections": "Verbindungen",
    "Settings.connectionName": "Name der Verbindung",
    "Settings.activeSince": "Aktiv seit",
    "Settings.jobsShared": "Anzahl geteilter Dienste",
    "Settings.status": "Status",
    "Settings.connnectionActive": "Aktiv",
    "Settings.connnectionDeactivated": "Deaktiviert",
    "Settings.activationConfirm": `Möchten Sie die Verbindung wirklich aktivieren? <b> Sie können wieder Dienste mit der Verbindung teilen.</b>`,
    "Settings.deactivationConfirm": `Möchten die Verbindung wirklich deaktivieren? Dann können Sie keine Anfragen mehr an diese Verbindung senden <b> Alle bereits geteilten und geplanten Dienste sind weiterhin gültig </b> `,
    "Settings.contractSettings": "Verträge für die Mitarbeitenden",
    "Settings.categorySettings": "Kategorien für das Poolmanagement",
    "Settings.sectors": "Funktionen",
    "Settings.qualifications": "Qualifikationen",
    "Settings.trainings": "Schulungen",
    "Settings.contractName": "Vertragsname",
    "Settings.workflowName": "Vorlagenname",
    "Settings.workflowSteps": "Schritte",
    "Settings.paymentType": "Zahlungsmethode",
    "Settings.paidOnAge": "Nach Alter",
    "Settings.fixedAmountPer": "Fester Stundenlohn",
    "Settings.fixedAmount": "Festbetrag",
    "Settings.rate": "Tarif",
    "Settings.cancel": "Stornieren",
    "Settings.create": "Erstellen",
    "Settings.update": "Update",
    "Settings.documents": "Dokumente",
    "Settings.hourlyRate.sub-header":
      "Die Stundenlöhne, wenn ein Projekt je nach Alter ausgezahlt wird",
    "Settings.hourlyRate": "Zahlung nach Alter",
    "Settings.from": "Von",
    "Settings.still": "Bis",
    "Settings.hourlyRatesCreate":
      "Stundenlöhne mit Start- und Enddatum hinzufügen",
    "Settings.hourlyRatesUpdate": "Stundenlöhne für den Zeitraum anpassen",
    "Settings.appointments": "Kontaktmöglichkeiten",
    "Settings.appointments.description":
      "Erstellen Sie hier Möglichkeiten, aus denen Bewerber bei der Anmeldung auswählen können",
    "Settings.appointment.location": "Standort",
    "Settings.appointment.dateTime": "Datum & Uhrzeit (optional)",
    "Settings.appointment.options": "Aktionen",
    "Settings.appointment.saved": "Neuer Kontaktmoment erfolgreich erstellt",
    "Settings.appointment.updated": "Kontaktmoment wurde bearbeitet",
    "Settings.appointment.archived": "Kontaktmoment archiviert",
    "Settings.tenant.title": "Basisinformationen",
    "Settings.tenant.description":
      "Fügen Sie hier die Unternehmensinformationen hinzu, sie sind für die Mitarbeitenden in der mobilen App auf der Informationsseite sichtbar.",
    "Settings.tenant.hours": "Ihr Paket",
    "Settings.tenant.hoursDone": "{currentHours} von {planHours} Stunden",
    "Settings.tenant.remainingMonth": "Verwendbar bis {endOfMonth}",
    "Settings.tenant.checkOutPlans": "Andere Pakete ausschauen",
    "Settings.page.company": "Unternehmens informationen in der App",
    "Settings.page.company.desc":
      "Fügen Sie hier die allgemeinen Informationen Ihres Unternehmens hinzu. Die Informationen und Dokumente sind auf der Seite ‚Über uns' in der mobilen App zu finden.",
    "Settings.page.company.adjust": "Grundinformationen bearbeiten",
    "Settings.page.hr": "Personal & Finanzen",
    "Settings.page.poole": "Poolmanager",
    "Settings.page.admin.changePassword": "Passwort ändern? Klicken Sie hier",
    "Settings.page.admin": "Admin",
    "Settings.page.templates": "Poolmanager",
    "Settings.page.sharing": "Dienste teilen",
    "Settings.page.sharing.desc":
      "Auf dieser Seite sind alle Einstellungen über das Teilen von Diensten aufgeführt. Das Teilen von Diensten ermöglichst es, einen ausstehenden Dienst bei einer anderen Organisation anzufordern.",
    "Settings.page.sharing.connections.pooleCombinations": "Poolkombinationen",
    "Settings.page.sharing.connections.pooleCombinations.saved":
      "Gespeicherte Poolkombinationen",
    "Settings.page.sharing.connections.linkedPool": "Verknüpfter Pool",
    "Settings.page.sharing.connections.employees": "Mitarbeitende Reichweite",
    "Settings.page.sharing.connections.lastModified": "Zuletzt geändert am",
    "Settings.page.sharing.connections.activeSince": `Verbindung aktiv seit {date}`,
    "Settings.page.sharing.connections.adjusting": `Sie bearbeiten die Poolkombination für die Funktion {function}.`,
    "Settings.page.sharing.connections.deleted":
      "Die Poolkombination wurde gelöscht",
    "Settings.page.sharing.connections.edited":
      "Die Poolkombination wurde angepasst",
    "Settings.page.sharing.connections.deleteConfirmation": `Sie sind dabei, diese Kombination zu löschen, sind Sie sicher, dass Sie diese Aktion ausführen möchten? `,
    "Settings.page.rateCards": "Zuschlagkarten",
    "Settings.page.rateCards.ratecards.hoursCrossed": "Überlappende Stunden",
    "Settings.page.rateCards.ratecards.insufficientTime": "Zu wenig Zeit",
    "Settings.rateCards": "Zuschlagkarten",
    "Settings.rateCards.new": "Neue Zuschlagkarte erstellen",
    "Settings.rateCards.update": "Zuschlagkarte anpassen",
    "Settings.rateCards.name": "Name",
    "Settings.rateCards.percentage": "Prozentsatz",
    "Settings.page.rateCards.surcharges": "Zuschläge",
    "Settings.page.rateCards.surcharges.creation": "Zuschlag erstellen",
    "Settings.page.rateCards.surcharges.modification": "Zuschlag anpassen",
    "Settings.page.rateCards.surcharges.new": "Neuer Zuschlag",
    "Settings.page.rateCards.surcharges.create": "Neuen Zuschlag erstellen",
    "Settings.page.rateCards.surcharges.modify": "Zuschlag anpassen",
    "Settings.page.rateCards.surcharges.name": "Name",
    "Settings.page.rateCards.surcharges.percentage": "Prozentsatz",
    "Settings.page.rateCards.holidays": "Feiertage",
    "Settings.page.rateCards.holidays.creation": "Feiertag hinzufügen",
    "Settings.page.rateCards.holidays.modification": "Feiertag ändern",
    "Settings.page.rateCards.holidays.new": "Neuer Feiertag",
    "Settings.page.rateCards.holidays.create": "Hinzufügen",
    "Settings.page.rateCards.holidays.modify": "Anpassen",
    "Settings.page.rateCards.holidays.name": "Name",
    "Settings.page.rateCards.holidays.date": "Datum",
    "Settings.page.reports": "Berichte",
    "Settings.page.reports.title": "Berichtvorlagen",
    "Settings.page.reports.desc":
      "Erstellen Sie die Vorlagen für die Berichte, die Sie für Ihr Unternehmen benötigen. Unterscheiden Sie selbst zwischen Administration- & Planungsberichten, indem Sie die Vorlage der Planung zur Verfügung stellen.",
    "Settings.page.reports.new": "Exportvorlage hinzufügen",
    "Settings.page.reports.item.columns": "Spalten",
    "Settings.pools": "Poolmanager",
    "Settings.pools.name": "Name",
    "Settings.pools.total": "Anzahl Mitarbeitende",
    "Settings.pools.actions": "Aktionen",
    "Settings.pools.subText":
      "Hier können Sie Ihre Standardpools erstellen, sie können den Projekten hinzugefügt werden",
    "Settings.pools.create": "Neuer Standardpool",
    "Settings.pools.edit": "Standardpool anpassen",
    "Settings.pools.archive.confirmation":
      "Sind Sie sicher? Der Pool wird dann aus dem/den Dienst(en) und Projekt(en), zu dem/denen der Pool bereits hinzugefügt wurde, entfernt! Es ist daher möglich, dass ausstehende Dienste für unqualifizierte Mitarbeitende sichtbar werden",
    "Settings.afasImport": "AFAS importieren",
    "Settings.afasIntegration": "AFAS-Integration",
    "Settings.afasIntegration.clientIDs": "Arbeitgeber-Identifikationsnummer",
    "Settings.afasIntegration.clientIDs.note":
      "Anmerkung: Arbeitgeber-Identifikationsnummer muss durch Kommas getrennt werden",
    "Settings.afasIntegration.clientIDs.spaces":
      "Die Arbeitgeber-Identifikationsnummer darf keine Leerzeichen enthalten.",
    "Settings.afasIntegration.settingTitle": "AFAS-Mitarbeitende",
    "Settings.afasIntegration.settingDescription":
      "Bitte angeben, ob Ihre Verbindungen nur Mitarbeitende von AFAS vorgeschlagen dürfen.",
    "Settings.afasIntegration.active":
      "Ihre Verbindungen dürfen nur Mitarbeitende von AFAS vorschlagen",
    "Settings.afasIntegration.inactive":
      "Ihre Verbindungen dürfen alle Mitarbeitende vorschlagen",
    "Settings.generateConnection": "Neue Verbindung",
    "Settings.generateConnection.title": "Verbindung erstellen",
    "Settings.generateConnection.description":
      "Kopieren Sie den unten stehenden Link und senden Sie ihn an das Unternehmen, mit dem Sie eine Verbindung herstellen möchten!",
    "Settings.username": "Benutzername",
    "Settings.functions": "Funktionen",
    "Settings.functions.subText":
      "Hier können Sie Ihre Funktionen erstellen und bearbeiten",
    "Settings.functions.create": "Eine Funktion hinzufügen",
    "Settings.functions.edit": "Funktion ändern",
    "Settings.functions.name": "Name",
    "Settings.functions.description": "Beschreibung",
    "Settings.functions.actions": "Aktionen",
    "Settings.notificationsAndEmails": "Benachrichtigungen und E-Mails",
    "Settings.organizationAdmin": "Admin-Organisation",
    "Settings.organizationAdmin.desc":
      "Auf dieser Seite können Sie die Desktop-Benutzer hinzufügen, Ermächtigungen verwalten und Arbeitsabläufe erstellen.",
    "Settings.userRoles": "Benutzerrechte",
    "Settings.userRoles.admin": "Admin",
    "Settings.userRoles.admin.desc": "Alle Befugnisse.",
    "Settings.userRoles.manager": "Manager",
    "Settings.userRoles.manager.desc":
      "Der Manager kann die Fleks-Manager-Anwendung verwenden, um über die App Einblick zu erhalten, wer eingeplant oder vor Ort ist. Darüber hinaus kann die Manager-App auch zum Ein- und Auschecken mit dem QR-Code verwendet werden.",
    "Settings.userRoles.planner": "Planer",
    "Settings.userRoles.planner.desc":
      "Benutzer können die Planung und Erklärungen verwalten. Aber sie können die personenbezogenen Daten der Mitarbeitenden nicht lesen.",
    "Settings.userRoles.client": "Klient",
    "Settings.userRoles.client.desc":
      "Lassen Sie Ihren Kunden Services selbst erstellen, seinen Pool planen und den Benutzer freigeben. Kunden erhalten keinen Einblick in die Details der Mitarbeiter und sehen Daten von anderen Kunden.",
    "Settings.accounts.active": "Aktive Benutzerkonten",
    "Settings.accounts.inactive": "Deaktivierte Benutzerkonten",
    "Settings.accounts.new": "Neuer Benutzer",
    "Settings.accounts.creation": "Benutzerkonto anlegen",
    "Settings.accounts.modification": "Benutzerkonto ändern",
    "Settings.accounts.new.description":
      "Erstellen Sie einen Systembenutzer für das Dashboard.",
    "Settings.accounts.create": "Benutzer erstellen",
    "Settings.accounts.modify": "Benutzer ändern",
    "Settings.accounts.2fa.messageResetSuccess":
      "Die 2FA-Methode für den Benutzer wurde erfolgreich zurückgesetzt.",
    "Settings.accounts.2fa.messageResetError":
      "Beim Zurücksetzen der 2FA-Methode für den Benutzer ist ein Problem aufgetreten.",
    "Settings.accounts.optional.clients": "Sie können einem Planer-Benutzer optional einen Kunden hinzufügen. Auf diese Weise kann der Planer nur für den/die ausgewählten Kunden planen.",
    "Settings.freeFields.add": "Freies Feld hinzufügen",
    "Settings.freeFields": "Freie Felder für Mitarbeitende",
    "Settings.jobFreeFields": "Jobfreie Felder",
    "Settings.freeFields.new": "Neues Feld",
    "Settings.freeFields.creation": "Feld erstellen",
    "Settings.freeFields.modification": "Feld bearbeiten",
    "Settings.freeFields.create": "Feld erstellen",
    "Settings.freeFields.modify": "Feld bearbeiten",
    "Settings.freeFields.fillableSwitch":
      "Von den Mitarbeitenden im Bewerbungsformular auszufüllen",
    "Settings.freeFields.visibleSwitch": "Sichtbar für Mitarbeiter und Bewerber",
    "Settings.freeFields.mandatorySwitch": "Ist obligatorisch",
    "Settings.freeFields.checkboxEnableValuesOption":
      "Geben Sie die Werte dieses Feldes an",
    "Settings.freeFields.inputEnableValuesOption": "Werte",
    "Settings.freeFields.jobContactPerson": "Ansprechpartner für den Job",
    "Settings.exceptionState.tittle": "Sonderstatus",
    "Settings.exceptionState.add": "Status hinzufügen",
    "Settings.exceptionState.add.id": "Ausweisnummer",
    "Settings.exceptionState.creation": "Sonderstatus erstellen",
    "Settings.exceptionState.modification": "Sonderstatus anpassen",
    "Settings.exceptionState.create": "Sonderstatus erstellen",
    "Settings.exceptionState.modify": "Sonderstatus anpassen",
    "Settings.exceptionState.switchBreakInvoiced.title":
      "Pause wird in Rechnung gestellt",
    "Settings.exceptionState.switchBreakPayedOut.title":
      "Pause wird ausbezahlt",
    "Settings.exceptionState.switchWorkingHoursInvoiced.title":
      "Arbeitszeiten werden in Rechnung gestellt",
    "Settings.exceptionState.switchWorkingHoursPayedOut.title":
      "Arbeitszeiten werden ausbezahlt",
    "Settings.exceptionState.switchCostsInvoiced.title":
      "Kosten werden in Rechnung gestellt",
    "Settings.exceptionState.switchCostsPayedOut.title":
      "Kosten werden ausbezahlt",
    "Settings.tenantSettings": "Externe Stunden",
    "Settings.tenantSettings.billable hours": "Externe Stunden",
    "Settings.tenantSettings.createBillableHoursDescription":
      "Mit den externen Stunden können Sie die Anzahl der Stunden angeben, die für einen Dienst in Rechnung gestellt werden, unabhängig von der Arbeitszeit. Wenn Sie diese Option aktivieren, können Sie über Fleks die Stunden mit Ihrer Verbindung verwalten (gilt also nur für geteilte Dienste)",
    "Settings.tenantSettings.applyBreakToBillableHours":
      "Pausenstunden von den abzurechnenden Stunden abziehen",
    "Settings.salaryProfiles": "Lohnprofile",
    "Settings.salaryProfiles.add": "Neues Lohnprofil",
    "Settings.salaryProfiles.activeFrom": "Aktiv seit {start_date}",
    "Settings.salaryProfiles.create": "Neues Lohnprofil erstellen",
    "Settings.salaryProfiles.update": "Lohnprofil anpassen",
    "Settings.salaryProfiles.name": "Name",
    "Settings.salaryProfiles.customId": "Ausweisnummer",
    "Settings.salaryProfiles.description":
      "Erstellen Sie die Stundensätze im Lohnprofil. Sie können mit einem Vertrag, einer Position oder direkt mit einem Mitarbeitenden verknüpft werden.",
    "Settings.salaryProfiles.maximumAgeMessage":
      "Mitarbeitende, die <b>{age}</b> Jahre oder älter sind, erhalten einen Stundenlohn von <b>{wage} €</b> ",
    "Settings.salaryProfiles.addAgeRatePair":
      "Sie müssen mindestens ein Alter / Betrag pro Stundenpaar pro Zeitraum haben",
    "Settings.salaryProfiles.newRatePeriod": "Neuen Tarifzeitraum hinzufügen",
    "Settings.salaryProfiles.deleteRatePeriod":
      "Sie sind dabei, den Tarifzeitraum zu löschen, sind Sie sicher?",
    "Settings.availabilityPeriodLimit":
      "Verfügbarkeitszeitraum festlegen (in Tagen)",
    "Settings.availabilityPeriodLimit.adjust": "Zeitraum anpassen",
    "Settings.availabilityPeriodLimit.subtitle":
      "Wählen Sie die Anzahl der zukünftigen Tage aus, an denen der Mitarbeitende keine Verfügbarkeit mehr angeben kann.",
    "Setting.pooleSettings.confirmDelete":
      "Sind Sie sicher? Bereits erstellte Dienste behalten dieselbe Funktion. Die Kategorie wird jedoch von den Mitarbeitenden und Pools entfernt. Es ist daher möglich, dass ausstehende Dienste für unqualifizierte Mitarbeitende sichtbar werden",
    "Settings.organizationSettings": "Regel für Kilometerpauschale",
    "Settings.organizationSettings.adjust": "Kostenerstattung anpassen",
    "Settings.organizationSettings.subtitle":
      "Ersten Kilometer nicht erstattet",
    "Settings.organizationSettings.labelKM": "Entfernung (km)",
    "Setting.acceptingHours.card.title": "Stunden genehmigen",
    "Setting.acceptingHours.card.subtitle":
      "Wählen Sie aus, wer für die Stunden verantwortlich ist. Die andere Partei kann diese Stunden nicht bearbeiten",
    "Setting.acceptingHours.card.titleAdjust":
      "Genehmigen der Stunden anpassen",
    "Setting.acceptingHours.card.switchDisable":
      "Meine Organisation ist der Inhaber der Stunden",
    "Setting.acceptingHours.card.switchEnable":
      "Der Inhaber der Stunden ist der Inhaber der externen Mitarbeitenden.",
    "Setting.autoScheduledEmployee.card.title":
      "Automatische Einplanung durch Mitarbeitende",
    "Setting.autoScheduledEmployee.card.subtitle":
      "Geben Sie an, ob Sie möchten, dass Mitarbeitende sich automatisch für Dienste der Verbindungen einplanen können.",
    "Setting.autoScheduledEmployee.card.titleAdjust":
      "Automatische Einplanung durch Mitarbeitende anpassen",
    "Setting.autoScheduledEmployee.card.switchEnable":
      "Ihre Mitarbeitenden können sich automatisch für die Dienste der Verbindungen einplanen.",
    "Setting.autoScheduledEmployee.card.switchDisable":
      "Ihre Mitarbeitenden können sich nicht automatisch für die Dienste der Verbindungen einplanen.",
    "EditTenantData.name": "Firmenname",
    "EditTenantData.description": "Firmenbeschreibung",
    "EditTenantData.email": "Informations-E-Mail",
    "EditTenantData.phone": "Telefon",
    "EditTenantData.mobile": "Handy",
    "EditTenantData.logo": "Logo",
    "EditTenantData.updatedError":
      "Die neuen Informationen des Auftraggebers wurden nicht gespeichert",
    "CreateAppointment.city": "Stadt",
    "CreateAppointment.city.required": "Die Stadt muss angegeben werden",
    "CreateAppointment.city.max": "Stadt darf maximal 100 Zeichen enthalten",
    "CreateAppointment.street": "Straße",
    "CreateAppointment.street.required": "Die Straße muss angegeben werden",
    "CreateAppointment.street.max": "Straße darf maximal 100 Zeichen enthalten",
    "CreateAppointment.houseNumber": "Hausnummer",
    "CreateAppointment.houseNumber.max":
      "Hausnummer darf maximal 10 Zeichen enthalten",
    "CreateAppointment.houseNumberExt": "Hausnummerzusatz",
    "CreateAppointment.houseNumberExt.max":
      "Hausnummerzusatz darf maximal 15 Zeichen enthalten",
    "CreateAppointment.zipCode": "PLZ",
    "CreateAppointment.zipCode.max": "PLZ darf maximal 6 Zeichen enthalten",
    "CreateAppointment.host": "Host",
    "CreateAppointment.datetime": "Datum & Uhrzeit (optional)",
    "HourlyRates.dateRange": "Zeitraum auswählen",
    "HourlyRates.newRate": "Alter und Stundenlohn hinzufügen",
    "HourlyRates.created": "Stundenlohn wurde erfolgreich erstellt",
    "HourlyRates.updated": "Stundenlohn wurde angepasst",
    "HourlyRates.age": "Alter",
    "HourlyRates.rate": "Betrag pro Stunde",
    "HourlyRates.start_date": "Startdatum",
    "HourlyRates.end_date": "Enddatum",
    "HourlyRates.no-data": "Noch kein Stundenlohn erstellt",
    "HourlyRates.age-duplicated":
      "Der Stundenlohn für dieses Alter wurde bereits festgelegt",
    "HourlyRates.minAge": "Das Mindestalter beträgt 16 Jahre.",
    "Planner.header.filters": "Die angezeigten Karten filtern",
    "Planner.header.settings": "Planer Einstellungen",
    "Planner.header.newJob": "Neuen Dienst erstellen",
    "Planner.newJobModal.title": "Dienst erstellen",
    "Planner.newJobModal.date": "Datum",
    "Planner.newJobModal.client": "Auftraggeber",
    "Planner.newJobModal.project": "Projekt",
    "Planner.newJobModal.jobDetails": "Zu den Details des Dienstes",
    "Planner.newJobModal.stepOneCompleted.desc": `Sie erstellen einen Dienst für <b>{date}</b> das Projekt <b>{project_name}</b> bei <b>{client_name}</b> `,
    "Planner.newJobModal.stepOneCompleted.adjust":
      "Diese Informationen bearbeiten",
    "Planner.newJobModal.stepOneCompleted.adjust.bankInformation":
      "Bankdaten anpassen",
    "Planner.newJobModal.details": "Details des Dienstes",
    "Planner.newJobModal.standardJob": "Standarddienst (optional)",
    "Planner.newJobModal.jobCategory": "Funktionstyp",
    "Planner.newJobModal.pools": "Pool (optional)",
    "Planner.newJobModal.street": "Straßenname",
    "Planner.newJobModal.houseNumber": "Hausnummer",
    "Planner.newJobModal.additionalInfo": "Zusatz (optional)",
    "Planner.newJobModal.postalCode": "PLZ",
    "Planner.newJobModal.city": "Stadt",
    "Planner.newJobModal.startTime": "Startzeit",
    "Planner.newJobModal.endTime": "Ende",
    "Planner.newJobModal.available": "Stellenanzahl",
    "Planner.newJobModal.workflow": "Vorlage für Arbeitsablauf",
    "Planner.newJobModal.createJob": "Dienst(e) erstellen",
    "Planner.newJobModal.jobCreatedSuccess": "Der Dienst wurde erstellt",
    "Planner.title": "Planer",
    "Planner.Week.today": "Heute",
    "Planner.Week.week": "Woche",
    "Planner.noJobs": "Keine Dienste",
    "Planner.stats.positions": "Stellen",
    "Planner.Details.jobLocation": "Kein fester Standort",
    "Planner.Details.jobInformation": "Infos über den Dienst",
    "Planner.Details.scheduled": "Eingeplant",
    "Planner.Details.registered": "Registriert",
    "Planner.Details.candidates": "Kandidaten",
    "Planner.Details.extensiveSearch": "Erweiterte Suche",
    "Planner.Details.search": "Suchen",
    "Planner.Details.showMore": "Mehr sehen",
    "Planner.Details.seeAll": "Alle sehen",
    "Planner.Details.employees": "Mitarbeitende",
    "Planner.Details.noJobSelected": "Sie haben noch keinen Dienst ausgewählt",
    "Planner.Details.selectAJob":
      "Wählen Sie zuerst einen Dienst aus, um die zugehörigen Informationen anzuzeigen.",
    "Planner.Details.invited": "Eingeladen",
    "Planner.Details.clusterInformation": "Cluster-Informationen",
    "Planner.fullJobs": "Volle Schichten anzeigen",
    "Planner.filters.client": "Auftraggeber",
    "Planner.jobBecomeFull": "Dienst ist schon voll",
    "Planner.filters.name": "Auftraggeber",
    "Planner.filters.project": "Projektname",
    "Planner.filters.startDate": "Startdatum",
    "Planner.filters.endDate": "Enddatum",
    "Planner.filters.projectManager": "Projektleiter",
    "Planner.filters.typeOfWork": "Funktion",
    "Planner.filters.week": "Woche",
    "Planner.filters.city": "Adresse",
    "Planner.filters.isFull": "Volle Schichten anzeigen",
    "Planner.filters.cluster": "Cluster",
    "Planner.clusterView": "Clusters",
    "Planner.closeDetails": "Seitenleiste schließen",
    "Planner.settings": "Planer einstellen",
    "Planner.settings.back": "Zurück zum Planer",
    "Planner.settings.cancel": "Abbrechen",
    "Planner.settings.save": "Änderungen speichern",
    "Planner.settings.setUp": "Ihren Planer einrichten",
    "Planner.settings.setUp.desc":
      "Dies sind Ihre Planereinstellungen. Passen Sie die folgenden Informationen an, sodass die Einstellungen Ihren Vorlieben entsprechen.",
    "Planner.settings.jobCluster": "Dienstkarten clustern",
    "Planner.settings.jobCluster.desc":
      "Wählen Sie hier aus, ob Sie Ihre Dienste clustern möchten. Wenn Sie Clustern aktivieren, werden die Dienste auf Auftraggeber-, Projekt- und Positionsebene geclustert.",
    "Planner.settings.jobCluster.toggleAction": "Meine Dienste gruppieren",
    "Planner.settings.jobCluster.notApplied":
      "Ihre Dienste sind nicht geclustert.",
    "Planner.settings.jobCluster.applied": "Ihre Dienste sind geclustert.",
    "Planner.settings.fullJobs": "Besetzte Dienste",
    "Planner.settings.fullJobs.desc":
      "Wählen Sie hier aus, ob Sie auch alle besetzten Dienste im Planer sehen möchten.",
    "Planner.settings.fullJobs.toggleAction": "Besetzte Dienste anzeigen",
    "Planner.settings.fullJobs.notApplied":
      "Besetzte Dienste werden nicht angezeigt.",
    "Planner.settings.fullJobs.applied": "Besetzte Dienste werden angezeigt.",
    "Planner.settings.sortCandidates": "Passende Kandidaten anordnen",
    "Planner.settings.sortCandidates.desc":
      "Wählen Sie in der Seitenleiste aus, wie Sie die passenden Kandidaten anordnen möchten.",
    "Planner.settings.sortCandidates.availability":
      "Zuerst immer nach Verfügbarkeit anordnen",
    "Planner.settings.sortCandidates.distance": "Entfernung",
    "Planner.settings.sortCandidates.hoursWorked":
      "Gearbeitete Stunden/Vertragsstunden",
    "Planner.settings.sortCandidates.timesWorked":
      "Anzahl der Einsätze bei dem Auftraggeber",
    "Planner.settings.jobCardInformation":
      "Dienstkarteninformationen und Gruppierung",
    "Planner.settings.jobCardInformation.desc":
      "Wählen Sie aus, welche Informationen Sie in den Dienstkarten sehen möchten und ob die Dienste gruppiert werden sollen. Je mehr Elemente aktiviert sind, desto größer wird die Dienstkarte.",
    "Planner.settings.jobCardInformation.cardLooks":
      "So sehen Ihre Dienstkarten aus",
    "Planner.settings.customizeCandidates": "Kandidateneinstellungen",
    "Planner.settings.customizeCandidates.desc":
      "Wählen Sie die Einstellungen für Ihre Kandidaten",
    "Planner.settings.customizeCandidates.applied": "Alle Kandidaten anschauen",
    "Planner.settings.customizeCandidates.notApplied":
      "Alle Kandidaten anzeigen",
    "Planner.settings.customizeCandidates.excludeUsers":
      "Kandidaten ausschließen, die:",
    "Planner.settings.customizeCandidates.noValidContract":
      "Keinen gültigen Vertrag haben",
    "Planner.settings.customizeCandidates.invitedToAnotherJob":
      "Schon eingeladen wurden",
    "Planner.settings.customizeCandidates.scheduledToAnotherJob":
      "Schon eingeplant sind",
    "Planner.goToOld": "Brauchen Sie den alten Planer?!",
    "Planner.jobCard.pendingSpots": "Ausstehend ({pendingSpots})",
    "Planner.jobCard.totalSchedules": `{totalSchedules, plural,
          =0 { Keine Einplanungen }
          other { # Einplanungen }
        }`,
    "Planner.jobCard.full": "Besetzt",
    "Planner.Details": "Details",
    "Planner.Details.close": "Seitenleiste schließen",
    "Planner.Employees.planned": "Eingeplant",
    "Planner.Employees.pending": "Ausstehend",
    "Planner.Employees.proposed": "Vorgeschlagen",
    "Planner.Employees.proposals": "Vorschlagen",
    "Planner.pending.registered": "Registriert",
    "Planner.pending.invited": "Eingeladen",
    "Planner.pending.canceled": "Storniert",
    "Planner.pending.sick": "Krank",
    "Planner.pending.noShow": "Nichterschienen",
    "Planner.employees.internals": "Intern",
    "Planner.employees.externals": "Extern",
    "Planner.Employees.incoming": "Externe Mitarbeitende",
    "Planner.Employees.incoming.count": "Externe Mitarbeitende - {gesamt}",
    "Planner.Employees.candidates": "Kandidaten - {gesamt}",
    "Planner.candidates.search": "Mitarbeitenden suchen",
    "Planner.clusterDetails": "Clusterdetails",
    "Planner.clusteredJobs": "Geclusterte Dienste",
    "Planner.clusteredJobs.planClusterIn": "Cluster einplanen",
    "Planner.clusteredJobs.overview.contains": "Dieser Cluster enthält",
    "Planner.clusteredJobs.overview.contains.totalJobs": `Dienste, von denen`,
    "Planner.clusteredJobs.overview.contains.sharedJobs": `{totalSharedJobs, plural,
        one {deaktiviert wurde}
        other {deaktiviert wurden}
      }`,
    "Planner.clusteredJobs.overview.furthermore": "Zusätzlich berechnen wir:",
    "Planner.clusteredJobs.overview.furthermore.openSpots":
      "offene Stellen mit",
    "Planner.clusteredJobs.overview.furthermore.applications": "Anmeldungen",
    "Planner.clusteredJobs.overview.furthermore.invitations":
      "verschickte Einladungen",
    "Planner.startPlanning": "Planung beginnen",
    "Planner.jobDetails": "Details des Dienstes",
    "Planner.openPositions": `{pendingSpots, plural,
        0= { Keine offenen Stellen}
        one { # Offene Stelle }
        other { # Offene Stellen}
      }`,
    "Planner.selectedEmployees": "Ausgewählt",
    "Planner.selectedEmployeesInfo": "Wählen Sie hier die Mitarbeiter ab",
    "Planner.selectEmployees": "Kandidaten",
    "Planner.selectProposed": "Vorgeschlagene externe Kandidaten",
    "Planner.selectFreelancers": "Registrierte Freiberufler",
    "Planner.applicationDate": "Registriert am",
    "Planner.selectEmployeesToBeProposed":
      "Vorzuschlagende Mitarbeitende auswählen",
    "EmployeeCard.goBack": "Zurück zu den Informationen",
    "Planner.afasIntegration.notPropose":
      "Sie können keine Mitarbeitenden vorschlagen oder einplanen, die nicht aus AFAS importiert wurden.",
    "Planner.noData.header": "Keine Dienste wählbar",
    "Planner.noData.callToAction":
      "Kehren Sie zum Planer zurück und wählen Sie eine Woche mit Dienste aus.",
    "EmployeeCard.cancellation.reason": "Warum stornieren Sie die Einplanung?",
    "EmployeeCard.cancellation.reason.noShow":
      "Der Kandidat ist nicht erschienen",
    "EmployeeCard.cancellation.reason.sick":
      "Der Kandidat hat sich krankgemeldet",
    "EmployeeCard.cancellation.reason.noReason": "Anderer Grund",
    "EmployeeCard.cancellation.reason.include": "In den Lohnzettel einbeziehen",
    "EmployeeCard.cancellation.beforeJobStarts":
      "Sie stornieren die Einplanung {expiration}, bevor der Dienst beginnt.",
    "EmployeeCard.cancellation.reason.save": "Grund speichern",
    "EmployeeCard.cancellation.confirmation.cancellationNoPast":
      "Sind Sie sicher, dass Sie die Einplanung stornieren möchten? Sie können die Einplanung ohne Folgen bis zum {date} um {hour} stornieren.",
    "EmployeeCard.cancellation.confirmation.cancellationPast":
      "Sie stornieren innerhalb der Stornierungsfrist. Das Stornierungsdatum war {date} um {hour}. ",
    "EmployeeCard.cancellation.confirmation.save": "Stornierung bestätigen",
    "EmployeeCard.cancellation.confirmation.noCancellationPolicy":
      "Sie können diesen Dienst stornieren, da es keine Stornierungsbedingungen eingerichtet wurden",
    "Scheduler.header.settings": "Planungsbord Vorlieben",
    "Scheduler.settings": "Planungsbord Einstellungen",
    "Scheduler.settings.back": "Zurück zum Planungsbord",
    "Scheduler.settings.explication.title": "Ihr Planungsbord einrichten",
    "Scheduler.settings.explication.description":
      "Dies sind Ihre Einrichtungen für das Planungsbord. Passen Sie die folgenden Informationen an, sodass die Einstellungen Ihren Vorlieben entsprechen.",
    "Scheduler.settings.job.title": "Dienstkarteninformationen",
    "Scheduler.settings.job.description":
      "Wählen Sie aus, welche Informationen Sie in Ihren Dienstkarten sehen möchten. Je mehr Elemente aktiviert sind, desto größer wird die Dienstkarte.",
    "Scheduler.settings.job.preview": "So sehen Ihre Dienstkarten aus:",
    "Scheduler.settings.order.title": "Anordnung der Mitarbeitenden",
    "Scheduler.settings.order.description":
      "Wie sollen die Mitarbeitenden auf dem Planungsbord angeordnet werden?",
    "Scheduler.settings.orderAtoZ": "Name A-Z",
    "Scheduler.settings.orderZtoA": "Name Z-A",
    "Scheduler.settings.orderUnplannedHoursAsc":
      "Ungeplante Stunden aufsteigend",
    "Scheduler.settings.orderUnplannedHoursDesc":
      "Ungeplante Stunden absteigend",
    "Scheduler.settings.orderZipCodeAsc": "PLZ - aufsteigend",
    "Scheduler.settings.orderZipCodeDesc": "PLZ - absteigend",
    "Scheduler.filters.isAvailableOn": "Verfügbar am",
    "Scheduler.filters.isAvailableOn.and": "Und",
    "Scheduler.filters.isAvailableOn.or": "Oder",
    "Notifications.header": "Benachrichtigungen",
    "Notifications.footer.clearAll": "Alle Benachrichtigungen löschen",
    "Notifications.footer.clearAllPopconfirm":
      "Möchten Sie wirklich alle Benachrichtigungen löschen?",
    "Notifications.info.close": "Ansicht schließen",
    "Notifications.info.goToPage": "Gehen Sie zu der Seite",
    "Notifications.info.download": "Jetzt herunterladen",
    "Notifications.delete": "Löschen",
    "Notifications.finqle.approveError": `Sie können die Stunden nicht von Fleks auf, Verarbeitet “übertragen, da die Stunden und Kosten zunächst in Finqle verarbeitet werden müssen. Besuchen Sie app.connections.fleks.works, um den Status der Stunden einzusehen.`,
    "Notifications.finqle.acceptError": `Ihre Organisation hat noch keine Tarifkarte ausgewählt, die für die Verbindung mit finqle in Betracht gezogen werden sollte, kontaktieren Sie fleks.`,
    "Notifications.deleteSuccess": "Die Benachrichtigung wurde gelöscht",
    "Planner.Connections": "Verbindungen",
    "Candidates.available.title": "Verfügbar",
    "Candidates.partlyAvailable.title": "Teilweise verfügbar",
    "Candidates.notAvailable.title": "Nicht verfügbar",
    "Candidates.noData": "Keine(n) Mitarbeitenden gefunden",
    "Candidates.notUpdated.title": "Unbekannt",
    "Candidates.errors.load":
      "[section_name]: Wir konnten die Mitarbeitenden nicht einladen",
    "AvailabilityPercentage.partlyAvailable":
      "[first_name] hat angegeben, von [start_time] bis [end_time] verfügbar zu sein",
    "AvailabilityPercentage.contact": "Telefon: [phone_number]",
    "AvailabilityPercentage.wholeDay":
      "[first_name] hat angegeben, den ganzen Tag verfügbar zu sein.",
    "AvailabilityPercentage.wholeDayNotAvailable":
      "[first_name] hat angegeben, den ganzen Tag über nicht verfügbar zu sein.",
    "AvailabilityPercentage.alreadyScheduled":
      "[first_name] ist bereits für einen Dienst eingeplant",
    "MiniWorkFlow.operationNotAllowed": "Aktion nicht möglich",
    "Shifts.all": "Alle",
    "Shifts.confirm-archive-checked":
      "Sind Sie sicher? Die Einplanung wird im Archiv gespeichert",
    "Shifts.title": "Einplanungen",
    "Shifts.isCompleted": "Arbeitsablauf abgeschlossen?",
    "Shifts.activeStatus": "Aktueller Schritt",
    "Shifts.uncompletedStatus": "Schritt unvollständig",
    "Shifts.validOnly": "Nur gültig",
    "Shifts.invalidState": "Stornierungsstatus",
    "Shifts.upcoming": "Bevorstehende",
    "Shifts.emptyState.title": "Es wurden keine Mitarbeitenden eingeplant",
    "Shifts.emptyState.description":
      "Kehren Sie zur Mitarbeitendenseite zurück und fügen Sie einen neuen Mitarbeitenden hinzu.",
    "Shifts.inProgress": "Bei der Arbeit",
    "Shifts.workflowStatus": "Arbeitsablauf-Status",
    "Shifts.toOldDesign": "Klicken Sie hier für die alte Übersicht?",
    "Shifts.goToTablePreferences": "Gehen Sie zu den Tabelleneinstellungen",
    "Shifts.advancedFilters.close": "Seitenleiste schließen",
    "Shifts.advancedFilters.title": "Alle Filter",
    "Shifts.archived": "{items} Einplanungen archiviert",
    "Shifts.client": "Auftraggeber",
    "Shifts.clientId": "Auftraggeber-Identifikationsnummer",
    "Shifts.telephoneNumber": "Telefonnummer",
    "Shifts.project": "Projekt",
    "Shifts.time": "Zeit",
    "Shifts.workflowModalTitle":
      "Bestätigen Sie die Check-in- und Check-out-Zeit",
    "Shifts.worflowLocationButton": "Bestätigen",
    "Shifts.checkIn": "Check-in-Zeit",
    "Shifts.checkOut": "Abreisezeit",
    "Shifts.archive.confirmation.material": "Sie sind im Begriff, einen bestimmten Artikel zurückzugeben. Sind Sie sicher?",
    "Shifts.archive.confirmation": `Sie sind dabei,
      {items, plural,
        one {# Einplanung}
        other { # Einplanungen }    
      } zu {archive, select,
        true {zu-archivieren}
        false {archivieren}
      }, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Shift.workflow.confirmation": "Are you sure to complete the step?",
    "Schicht.jobAngenommen": "Dienst angenommen",
    "Shift.quizComplete": "Quiz abgeschlossen",
    "Shift.checkIn": "Angemeldet",
    "Shift.checkInLocation": "Am Standort",
    "Shift.uploadPhoto": "Fotos hochgeladen",
    "Shift.checkOut": "Ausgecheckt",
    "Shift.hourDeclaration": "Stunden gesendet",
    "Shift.evaluation": "Auswertung",
    "Shift.exceptionState.modal.title": "Sonderstatus hinzufügen",
    "Shift.exceptionState.modal.select": "Status auswählen",
    "Shift.updateJobApplications.allUpdated":
      "Alle Einplanungen wurden angepasst",
    "Shift.updateJobApplications.updated": "Die Einplanung wurde angepasst",
    "Shift.updateJobApplications.updated.exceptionState":
      "Der Sonderstatus wurde angepasst",
    "Shift.updateJobApplications.notUpdated.exceptionState":
      "Der Sonderstatus wurde nicht angepasst",
    "Shift.updateJobApplications.allNotUpdated":
      "Die Einplanungen konnten nicht angepasst werden",
    "Shift.updateJobApplications.oneNotUpdated":
      "Eine oder mehrere Einplanungen wurden nicht angepasst",
    "Shift.updateJobApplications.onePartiallyUpdated":
      "Eine oder mehrere Einplanungen wurden teilweise aktualisiert, bitte überprüfen Sie die Einplanungen",
    "Shift.updateJobApplications.selectedSharedJobApp.withMe": `Das Hinzufügen eines Sonderstatus ist nicht möglich. {jobApplicationsSharedWithMe, plural,
        one { # Einplanung }
        other { # Einplanungen }
      } wurde/wurden geteilt. Um die Einplanungen anzupassen, müssen Sie nur Ihre eigenen Dienste auswählen.`,
      "CreateCategory.categoryCreated": 
      "Die neue Projektkategorie wurde erstellt",
    "Shift.assignMaterials":"Materialien zuordnen",
    "Shift.assignMaterials.selectMaterials": "Materialien auswählen",
    "Shift.assignMaterials.givenMaterials": "Gegebene Materialien",
    "Shift.assignMaterials.materialName": "Material Name",
    "Shift.assignMaterials.remark": "Anmerkung",
    "Shift.assignMaterials.404.primary": "Sie haben dem Mitarbeiter derzeit keine Materialien zugewiesen.",
    "Shift.assignMaterials.404.secondary": "Wählen Sie links die Materialien zum Zuweisen aus.",
    "Shift.assignMaterials.footer.giveMaterials": "Materialien verbinden und sichern",
    "Shift.gatherMaterials.complete": "Workflow-Schritt bestätigen und abschließen",
    "Shift.gatherMaterial.returned": "Alle Elemente weitergeleitet",
    "Shift.assignMaterials.footer.completeStep": "Vollständiger Workflow-Schritt",
    "Shift.assignMaterials.search": "Suchen Sie nach Material oder Kategorie",
    "Shift.assignMaterials.category": "Kategorie",
    "Shift.gatherMaterials":"Materialien einreichen",
    "Shift.gatherMaterials.handed":"Eingereicht",
    "Shift.gatherMaterials.notHanded":"Nicht eingereicht",
    "Shift.gatherMaterials.404":"Kreuzen Sie die oben genannten Punkte an, wenn sie noch nicht eingereicht wurden",
    "Shift.assignMaterials.footer.handed.confirm": "Bestätigen Sie die eingereichten Materialien",
    "Shift.managerSignOff": "Unterschrift", 
    "Shift.managerSignOff.footer.handed.confirm": "Unterschrift bestätigen",
    "CreateCategory.newCategory": "Neue Kategorie",
    "CreateCategory.notCreated": "Die Kategorie konnte nicht erstellt werden",
    "CreateCategory.createCategory": "Neue Kategorie",
    "CreateCategory.inputName": "Geben Sie einen Namen ein",
    "CreateCategory.name": "Name",
    "CreateCategory.pleaseSelectType": "Art auswählen",
    "CreateCategory.selectType": "Art auswählen",
    "CreateCategory.regular": "Funktionen",
    "CreateCategory.trainings": "Schulung",
    "CreateCategory.qualifications": "Qualifikationen",
    "CreateCategory.addDescription": "Eine Beschreibung hinzufügen",
    "CreateCategory.description": "Beschreibung",
    "CreateCategory.categoryType": "Verkaufspreis",
    "CreateCategory.salesPrice": "Sales price",
    "CreateCategory.categoryName": "Name",
    "CreateCategory.salaryProfile": "Lohnprofile",
    "CreateCategory.selectSalaryProfile": "Lohnprofil auswählen",
    "CreateCategory.mandatoryExpirationDate":
      "Die Kategorie muss inklusive Enddatum eingegeben werden.",
    "CreateCategory.idCategory": "Beim Hinzufügen zu einem Mitarbeiter muss die Kategorie eine ID enthalten",
    "CreateCategory.optionalHourlyRate": "Ich möchte optional einen Stundensatz hinzufügen, wenn ich einem Mitarbeiter hinzugefügt werde",
    "CreateCategory.expirationDateTitle":"Bis wann ist diese Ermächtigung gültig?",
    "CreateCategory.title": "Kategoriespezifikationen hinzufügen",
    "CreateCategory.idMandatory": "ID-nummer hinzufügen",
    "CreateCategory.hourlyRates": "Ausnahmestundensatz (optional)",
    "CreateCategory.idMandatory.placeholder": "ID-nummer",
    "CreateCategory.updateCategory": "Kategorie ändern",
    "CreateCategory.categoryUpdated": "Die Änderung wurde gespeichert",
    "CreateCategory.deleteCategory": "Funktion löschen",
    "CreateCategory.categoryDeleted": "Funktion wurde gelöscht",
    "CreateCategory.notDeleted": "Die Funktion konnte nicht gelöscht werden",
    "CreateCategory.notUpdated":
      "Die Änderung konnte nicht gespeichert werden.",
    "CreateCategory.id": "Ausweiskategorie",
    "CreateContract.createContract": "Vertrag aufsetzen",
    "CreateContract.contractName": "Vertragsname",
    "CreateContract.inputName": "Vertragsname ist obligatorisch",
    "CreateContract.contractType": "Vertragstyp",
    "CreateContract.inputType": "Vertragstyp ist obligatorisch",
    "CreateContract.zzp": "Freiberufler",
    "CreateContract.ma": "MA",
    "CreateContract.sis": "SIS",
    "CreateContract.ghost": "GHOST",
    "CreateContract.paymentType": "Zahlungsmethode",
    "CreateContract.inputPayment": "Zahlungsmethode ist obligatorisch",
    "CreateContract.amount": "Betrag",
    "CreateContract.amountIn": "Betrag in Euro",
    "CreateContract.inputAmount": "Betrag ist obligatorisch",
    "CreateContract.fixedRate": "Festbetrag",
    "CreateContract.hourlyRate": "Fester Stundenlohn",
    "CreateContract.ageRate": "Nach Alter",
    "CreateContract.createdContract": "Neuer Vertrag erstellt",
    "CreateContract.updateContract": "Vertragsänderung wurde gespeichert",
    "CreateContract.error":
      "Die Vertragsinformationen konnten nicht gespeichert werden",
    "CreateContract.maxWeekHours": "Maximale Wochenstundenzahl",
    "CreateDashboardUser.createDashboardUser": "Dashboard-Benutzer erstellen",
    "CreateDashboardUser.email": "E-Mail-Adresse",
    "CreateDashboardUser.inputEmail": "Geben Sie eine E-Mail-Adresse ein",
    "CreateDashboardUser.username": "Benutzername",
    "CreateDashboardUser.inputUsername": "Geben Sie einen Benutzernamen ein",
    "CreateDashboardUser.password": "Passwort",
    "CreateDashboardUser.inputPassword": "Geben Sie ein Passwort ein",
    "CreateDashboardUser.inputPasswordLength":
      "Das Passwort muss mindestens 8 Zeichen enthalten",
    "CreateDashboardUser.firstName": "Vorname",
    "CreateDashboardUser.inputFirstName": "Geben Sie einen Vornamen ein",
    "CreateDashboardUser.prefix": "Namenszusatz",
    "CreateDashboardUser.inputPrefix": "Geben Sie einen Namenszusatz ein",
    "CreateDashboardUser.lastName": "Nachname",
    "CreateDashboardUser.inputLastName": "Geben Sie einen Nachnamen ein",
    "CreateDashboardUser.phoneNumber": "Telefonnummer",
    "CreateDashboardUser.invalidEmail": "Dies ist keine gültige E-Mail-Adresse",
    "CreateDashboardUser.invalidNumber": "Dies ist keine gültige Nummer",
    "CreateDashboardUser.newDashboardUser": "Neuer Dashboard-Benutzer",
    "CreateDashboardUser.created": "Der Dashboard-Benutzer wurde erstellt",
    "CreateDashboardUser.notCreated": `{errorCode, select,
          sr_user_11 {Es existiert bereits ein aktives Benutzerkonto mit dieser E-Mail-Adresse.}
          sr_user_10 {Benutzername existiert bereits.}
          other {Der Dashboard-Benutzer wurde nicht erstellt}
        }`,
    "CreateDashboardUser.minLength":
      "Der Benutzername muss mindestens 4 Zeichen enthalten",
    "CreateWorkflowTemplate.createWorkflowTemplate":
      "Arbeitsablauf-Schritte auswählen",
    "CreateWorkflowTemplate.description":
      "Im Arbeitsablauf können Sie angeben, was der Mitarbeitende in dem Dienst ausführen soll. Erstellen Sie hier Ihre Standard-Arbeitsabläufe, diese können bei der Erstellung des Dienstes ausgewählt werden.",
    "CreateWorkflowTemplate.name": "Name des Arbeitsablaufs",
    "CreateWorkflowTemplate.job_applied": "Anmelden",
    "CreateWorkflowTemplate.job_accepted": "Eingeplant",
    "CreateWorkflowTemplate.quiz_complete": "Quiz",
    "CreateWorkflowTemplate.quiz_completeDescription":
      "Mit dem Quiz können Sie dem Mitarbeitenden gezielt Fragen stellen, sie müssen korrekt ausgefüllt werden, um im Arbeitsablauf fortfahren zu können",
    "CreateWorkflowTemplate.item_distribution": "Materialverteilung", 
    "CreateWorkflowTemplate.item_distributionDescription": "Verfolgen Sie, welche Materialien an den Mitarbeiter ausgegeben wurden. Alle unter Einstellungen > Admin hinzugefügten Materialien werden einbezogen.", 
    "CreateWorkflowTemplate.item_gathering": "Materialsammlung", 
    "CreateWorkflowTemplate.item_gatheringDescription": "Verfolgen Sie, welche Materialien an den Mitarbeiter ausgegeben wurden. Alle unter Einstellungen > Admin hinzugefügten Materialien werden einbezogen.", 
    "CreateWorkflowTemplate.check_in": "Anmelden",
    "CreateWorkflowTemplate.check_inDescription":
      "Dem Mitarbeitenden erlauben, sich vor dem Dienst anzumelden. Legen Sie den Zeitrahmen fest, in dem die Anmeldung erfolgen muss.",
    "CreateWorkflowTemplate.check_in_location": "Am Standort",
    "CreateWorkflowTemplate.check_in_locationDescription":
      "Dem Mitarbeitenden erlauben, sich einzuchecken, wenn er/sie vor Ort ist.",
    "CreateWorkflowTemplate.check_in_qr": "Mit QR-Code einchecken",
    "CreateWorkflowTemplate.assignMaterials": "Materialien zuweisen",
    "CreateWorkflowTemplate.check_in_qrDescription":
      "Lassen Sie Ihre Mitarbeitenden mit einem QR-Code einchecken, der Koordinator/Manager vor Ort muss den QR-Code scannen (siehe Manager-App)",
    "CreateWorkflowTemplate.upload_photo": "Fotos",
    "CreateWorkflowTemplate.upload_photoDescription":
      "Lassen Sie den Mitarbeitenden vor Ort Fotos als Anwesenheitsnachweis machen.",
    "CreateWorkflowTemplate.evaluation": "Auswertung",
    "CreateWorkflowTemplate.evaluationDescription":
      "Lassen Sie den Mitarbeitenden nach dem Dienst eine Bewertung schreiben.",
    "CreateWorkflowTemplate.check_out": "Auschecken",
    "CreateWorkflowTemplate.check_outDescription":
      "Lassen Sie den Mitarbeitenden auschecken, Fleks protokolliert den Zeitpunkt des Auscheckens",
    "CreateWorkflowTemplate.check_out_qr": "Mit QR-Code auschecken",
    "CreateWorkflowTemplate.check_out_qrDescription":
      "Lassen Sie Ihre Mitarbeitenden mit einem QR-Code auschecken, der Koordinator/Manager vor Ort muss den QR-Code scannen (siehe Manager-App)",
    "CreateWorkflowTemplate.hour_declaration": "Abrechnen",
    "CreateWorkflowTemplate.hour_declarationDescription":
      "Lassen Sie den Mitarbeitenden nach dem Dienst eine Abrechnung schicken.",
    "CreateWorkflowTemplate.add": "Arbeitsablauf hinzufügen",
    "CreateWorkflowTemplate.isReserved": "Ist ein Reservedienst",
    "CreateWorkflowTemplate.signing_off": "Digitale Signatur aus der Manager-App",
    "CreateWorkflowTemplate.signing_offDescription": "In der Manager-App kann ein Mitarbeiter seinen Namen zum Auschecken unterschreiben, keine Diskussion mehr über Arbeitszeiten!",
    "CreateWorkflowTemplate.employee_signing_off":"Mitarbeiter-App mit digitaler Signatur",
    "CreateWorkflowTemplate.employee_signing_offDescription": "In der Mitarbeiter-App kann sich ein Vorgesetzter vor Ort anmelden und sofort die geleisteten Stunden einsehen.",
    "CreateWorkflowTemplate.message.invalidOrder":
      "Fehler in der Reihenfolge des Arbeitsablaufs",
    "CreateWorkflowTemplate.message.invalidOrder.description":
      "Auschecken kann nicht vor dem Einchecken erfolgen",
    "CreateWorkflowTemplate.messageTooltipStep.completedAt":
      "Abgeschlossen am: {completedAt}",
    "CreateWorkflowTemplate.messageTooltipStep.completedBy":
      "Abgeschlossen von: {completedBy}",
    "CreateWorkflowTemplate.messageTooltipStep.completedByEmployee":
      "Abgeschlossen von: Mitarbeitende",
    "CreateWorkflowTemplate.messageTooltipStep.distance": "Entfernung zum Arbeitsort : {distance}km",
    "CreateWorkflowTemplate.messageTooltipStep.locationButton": "Standort anzeigen",
    "AddPoolToShareJob.poolSpecification": "Poolangabe",
    "AddPoolToShareJob.description":
      "Sie haben <b>{job_number}</b> Dienst(e) akzeptiert. Möchten Sie diese(n) Dienst(e) für eine bestimmte Gruppe oder für alle Ihre Mitarbeitenden sichtbar machen?",
    "AddPoolToShareJob.selectYourPool": "Wählen Sie den Pool",
    "AddPoolToShareJob.tip":
      "Tipp: Um sicherzustellen, dass die richtigen Mitarbeitenden auf Ihren Dienst reagieren, fügen Sie eine Poolangabe hinzu.",
    "AddPoolToShareJob.radio1":
      "Ich möchte den/die Dienst(e) für eine bestimmte Gruppe sichtbar machen",
    "AddPoolToShareJob.radio1Info":
      "Die Poolangabe kann jederzeit auf der Dienst-Detailseite geändert werden.",
    "AddPoolToShareJob.radio2":
      "Ich möchte den/die Dienst(e) für alle meine Mitarbeitenden sichtbar machen",
    "AddPoolToShareJob.save": "Meine Auswahl speichern",
    "AddPoolToShareJob.rememberCombination": "Kombination merken",
    "AddPoolToShareJob.rememberCombinationWarning":
      "Alle Dienstanfragen von {tenant_owner} mit den Funktionen; {job_category} werden zukünftig automatisch mit dem ausgewählten Pool angenommen",
    "SaveCombinations.modal.title": "Dienstverifizierung",
    "SaveCombinations.modal.description":
      "Überprüfen Sie, ob die auf dem Marktplatz geteilten Informationen Ihren Wünschen entsprechen.",
    "SaveCombinations.modal.employersName": "Name des Auftraggebers",
    "SaveCombinations.modal.checkbox1":
      "Von diesem Arbeitgeber immer diese Position annehmen",
    "SaveCombinations.modal.checkbox2":
      "Beim nächsten Mal nachfragen, ob ich den Dienst bewerten möchte",
    "SaveCombinations.save": "Meine Auswahl speichern",
    "ShareJobs.title": "Dienstanfrage senden",
    "ShareJobs.description":
      "Sie haben <b>{job_number}</b> Dienste ausgewählt. Wählen Sie aus, von welchen Verbindungen Sie diese Dienste anfordern möchten.",
    "ShareJobs.searchPlaceholder": "Verbindung suchen",
    "ShareJobs.counterNotification":
      "Sie haben <b>{connections_number}</b> Verbindungen ausgewählt.",
    "ShareJobs.sendRequests": "Meine Anfragen senden",
    "ShareJobs.alreadyShared": "Dienst wurde schon geteilt",
    "ShareJobs.updateJobCancellationDate": "Stornierungsdatum ändern",
    "ShareJobs.updateAllTheJobsCancellationDate":
      "Stornierungsdatum aller Dienste ändern",
    "ShareJobs.cancellationDate": "Stornierungsdatum",
    "ShareJobs.cancellationDate.optional": "Stornierungsdatum (optional)",
    "ShareJobs.employeePropose.optional":
      "Anzahl der Mitarbeitenden (optional)",
    "ShareJobs.employeePropose.error":
      "Die Anzahl der angefragten Mitarbeitenden muss kleiner oder gleich {jobRequestMax} sein",
    "ShareJobs.employeePropose.update": "Anpassen",
    "ShareJobs.employeePropose.notificationSuccess":
      "Die Anfrage wurde angepasst",
    "ShareJobs.cancellationDate.flexible":
      "Flexibel (bis 24 Stunden vor Dienstbeginn)",
    "ShareJobs.cancellationDate.standard":
      "Standard (bis 48 Stunden vor Dienstbeginn)",
    "ShareJobs.cancellationDate.strict":
      "Streng (bis 72 Stunden vor Dienstbeginn)",
    "ShareJobs.cancellationDate.superstrict":
      "Super streng (bis 168 Stunden vor Dienstbeginn)",
    "ShareJobs.marketplace.title": "Setzen Sie Ihren Dienst auf {tenant_name}",
    "ShareJobs.marketplace.description":
      "Um einen Dienst auf {tenant_name} zu setzen, benötigen wir die folgenden Informationen von Ihnen. ACHTUNG: Die Vermittlungskosten für den Freiberufler werden in Klammern hinter dem Stundenlohn ausgewiesen",
    "ShareJobs.marketplace.shareJobWith": "Auf {tenant_name} veröffentlichen",
    "ShareJobs.xJobsSharedFromY": "{x} der {y} Dienste wurden bereits geteilt",
    "ShareJobs.publishOnX.description":
      "Dienst auf {tenant_name} veröffentlichen",
    "ShareJobs.publishOnX.selectedSharedJob.disabled": `Veröffentlichen ist nicht möglich {jobsSharedWithMarketplace, plural,
        one {# Job}
        other {# Jobs}
      } wurden bereits auf {tenant_name} veröffentlicht`,
    "ProfileForm.editProfile": "Profil ändern",
    "ProfileForm.firstNameInput": "Vorname ist ein Pflichtfeld",
    "ProfileForm.lastNameInput": "Nachname ist ein Pflichtfeld",
    "ProfileForm.phoneNumberInput": "Telefonnummer ist ein Pflichtfeld",
    "ProfileForm.updated-success": "Die Profiländerung wurde gespeichert",
    "ProfileForm.updated-error": "Die Profiländerung wurde nicht gespeichert",
    "ChangePassword.changePassword": "Passwort ändern",
    "ChangePassword.description":
      "Klicken Sie auf die Schaltfläche unten, um einen Reset-Code zum Ändern Ihres Passworts zu erhalten unter: %e. Sie können den Reset-Code verwenden, um Ihr Passwort zu ändern.",
    "ChangePassword.sendCode": "Code senden",
    "ChangePassword.code": "Code",
    "ChangePassword.inputCode": "Bitte Code eingeben",
    "ChangePassword.password": "Passwort",
    "ChangePassword.inputPassword": "Geben Sie ein Passwort ein",
    "ChangePassword.confirmPassword": "Passwort bestätigen",
    "ChangePassword.inputConfirmPassword": "Passwort bestätigen",
    "ChangePassword.codeSentSuccess":
      "Der Code wurde gesendet, überprüfen Sie Ihre E-Mail",
    "ChangePassword.codeSentError":
      "Der Code konnte nicht gesendet werden, bitte versuchen Sie es erneut",
    "ChangePassword.changed": "Geändertes Passwort wurde gespeichert",
    "ChangePassword.noChanged":
      "Es ist ein Fehler aufgetreten, ist der Code korrekt und haben Sie mindestens 8 Zeichen verwendet?",
    "Employee.Overview.addJob": "Dienst hinzufügen",
    "Employee.Overview.client": "Auftraggeber",
    "Employee.Overview.schedule": "Einplanen",
    "Employee.Overview.jobsFor": "Ausstehende Dienste",
    "Employee.Overview.jobsFor.empty": "Keine Dienste gefunden",
    "Employee.Overview.scheduleEmployee": "Einplanen",
    "Employee.Overview.scheduleSuccess":
      "Einplanung des Mitarbeitenden war erfolgreich",
    "Employee.Overview.partialSchedule":
      "Einige Mitarbeitende wurden nicht eingeplant",
    "Employee.Overview.acceptedProposal":
      "Der vorgeschlagene Kandidat wurde erfolgreich eingeplant",
    "Employee.Overview.rejectedProposal":
      "Der vorgeschlagene Kandidat wurde erfolgreich abgelehnt",
    "Overview.sick": "Krank",
    "Overview.hadContact": "Kontakt stattgefunden",
    "Overview.rejected": "Abgelehnt",
    "Overview.noShow": "Nichterschienen",
    "Overview.contacted": "Kontakt stattgefunden",
    "Overview.wantToWork": "Ist verfügbar",
    "Overview.unavailability": "Nicht verfügbar",
    "Overview.availability": "Verfügbarkeit",
    "Overview.unavailability.text": "Ist nicht verfügbar",
    "Overview.availability.error":
      "Die Verfügbarkeit von [employee_name] konnte nicht geändert werden",
    "Overview.availability.success":
      "[employee_name] Verfügbarkeit wurde geändert",
    "Overview.availability.description": "Verfügbarkeit wurde angepasst",
    "Overview.availability.default": "hat keine Verfügbarkeit angegeben",
    "Overview.availabilityRange": "[name] [availability] von [from] bis [to]",
    "Overview.available": "Unbekannt",
    "Overview.status": "Status",
    "Overview.dashboardChange": "Verfügbarkeit wurde angepasst",
    "Overview.scheduledRange": "Eingeplant von [from] bis [to]",
    "Overview.updateAvailability": "Verfügbarkeit anpassen",
    "Overview.errorOccurred": "Es ist ein Fehler aufgetreten",
    "ScheduleButton.hasContract":
      "%n hat ein Vertragsangebot, das während %jsd gültig ist, aber noch nicht unterzeichnet wurde.",
    "ScheduleButton.hasNoContract":
      "%n hat keinen gültigen Vertrag während %jsd.",
    "ScheduleButton.unavailable": "%n steht für %jsd auf nicht verfügbar.",
    "ScheduleButton.hasAJob":
      "%n ist bereits für %c, %a eingeplant, von %jst bis %jet.",
    "ScheduleButton.hasMultiJobs":
      "%n ist bereits für mehrere Dienste auf %jsd eingeplant.",
    "ScheduleButton.areYouSure": "Sind Sie sicher?",
    "ScheduleButton.Invite": "Einladen",
    "ScheduleButton.Invite.disabled":
      "Ein oder mehrere der ausgewählten Dienste ist/sind bereits beendet, der Mitarbeitende kann dazu nicht eingeladen werden",
    "ScheduleButton.errorSendInvitation":
      "Einladung konnte nicht gesendet werden",
    "ScheduleButton.confirm": "Bestätigen",
    "ScheduleButton.addExpireTime": "Gültigkeitsdatum",
    "ProposeButton.errorProposing":
      "Mitarbeitende konnte nicht vorgeschlagen werden",
    "Archive.clients": "Auftraggeber",
    "Archive.projects": "Projekte",
    "Archive.jobs": "Dienste",
    "Archive.employees": "Mitarbeitenden",
    "Archive.shifts": "Einplanungen",
    "Archive.applicants": "Bewerber",
    "Archive.hours": "Zeiterfassung",
    "Archive.emptyState.title": "Sie haben noch nichts archiviert",
    "Archive.emptyState.description":
      "Klicken Sie auf das Archivsymbol, um Ihre Dateien zu archivieren",
    "Archive.emptyState.description.1": "Klicken Sie auf das",
    "Archive.emptyState.description.2": "um Ihre Dateien zu archivieren.",
    "Archive.selectedSharedJob.withMe": `Archivierung ist nicht möglich, Sie haben {jobsSharedWithMe, plural,
        one {# Dienst}
        other {# Dienste}
      } ausgewählt, die mit Ihnen geteilt wurden`,
    "Archive.selectedSharedJob.byMe": `Archivierung ist nicht möglich, Sie haben {jobsSharedByMe, plural,
        one {# Dienst}
        other {# Dienste}
      } ausgewählt, die geteilt werden`,
    "Archive.selectedSharedJob.byMe.unique": `Der Dienst kann nicht archiviert werden, dieser Dienst kommt von einer Verbindung`,
    "Archive.selectedSharedJob.both": `Archivierung ist nicht möglich, Sie haben {jobsSharedWithMe, plural,
        one {# Dienst}
        other {# Dienste}
      } ausgewählt, die geteilt werden und {jobsSharedByMe, plural,
        one {# Dienst}
        other {# Dienste}
      } geteilt`,
    "UpdateSpecifications.selectedSharedJob.withMe": `Es ist nicht möglich, die Angaben zu ändern {jobsSharedWithMe, plural,
        one {# Job}
        other {# Jobs}
      } Sie können nur eigene oder nur geteilte Dienste ändern`,
    "NotificationModal.sendMail": "Eine Nachricht senden",
    "NotificationModal.sendMailToEmployees": "Nachricht Einplanungen",
    "NotificationModal.emailsSent": "Nachricht wurde gesendet",
    "NotificationModal.sendNotification": "Benachrichtigung senden",
    "NotificationModal.notificationSent": "Benachrichtigung wurde gesendet",
    "NotificationModal.emailsNotSent": "Die Nachricht wurde nicht gesendet",
    "NotificationModal.subjectPlaceholder": "Ein Thema hinzufügen",
    "NotificationModal.subject": "Thema",
    "NotificationModal.email": "E-Mail-Adresse",
    "NotificationModal.message": "Nachricht",
    "NotificationModal.selectAll": "Alles auswählen",
    "NotificationModal.receivers": "Mitarbeitenden",
    "NotificationModal.modalTitle": "Nachricht an %si Mitarbeitende%sj senden",
    "NotificationModal.about": "über",
    "NotificationModal.notification": "Push-Nachricht in App",
    "NotificationModal.subjectLengthErrorDescription":
      "Der Titel einer Push-Nachricht darf %si Zeichen nicht überschreiten. Ändern Sie die Größe der Nachricht, um sie als Push-Benachrichtigung zu senden, oder senden Sie sie einfach als E-Mail.",
    "NotificationModal.messageLengthErrorDescription":
      "Die Beschreibung einer Push-Nachricht darf %si Zeichen nicht überschreiten. Ändern Sie die Größe der Nachricht, um sie als Push-Benachrichtigung zu senden, oder senden Sie sie einfach als E-Mail.",
    "Documents.uploadText":
      "Klicken Sie hier, oder ziehen Sie eine PDF-Datei in dieses Feld, um sie hochzuladen. Bis zu {max} MB",
    "Documents.error.tooBig": "Die Datei ist zu groß'!",
    "Documents.uploadDescription":
      "Ein relevantes PDF-Dokument hochladen, mehrere Dokumente können gleichzeitig hochgeladen werden",
    "Documents.documentName": "Titel",
    "Documents.documentDescription": "Beschreibung",
    "Documents.documentOptions": "Aktionen",
    "Documents.uploadDocument": "Ein Dokument hinzufügen",
    "Documents.success-upload": "Dokument wurde hinzugefügt",
    "Documents.success-update": "Dokument wurde geändert",
    "Documents.upload-error": "Das Dokument wurde nicht hinzugefügt",
    "Documents.uploader": "Dokument hochladen",
    "Documents.cancel": "Abbrechen",
    "Documents.save": "Speichern",
    "Documents.no-data": "Es wurden noch keine Dokumente hinzugefügt",
    "GoogleFilePicker.defaultInfo":
      "Um das Blatt richtig lesen zu können, muss die erste Zeile die englischen Spaltennamen enthalten und das Datum muss im folgenden Format vorliegen: dd-mm-jjjj",
    "GoogleFilePicker.textButton": "Importieren",
    "EmployeeRow.updateError": "Einladung aktualisieren",
    "EmployeeRow.defaultError":
      "Es ist ein Fehler aufgetreten, versuchen Sie es erneut",
    "ScheduleModal.JobItem.notHaveJob": "steht für den Dienst zur Verfügung",
    "ScheduleModal.JobItem.haveJob": "ist bereits für einen Dienst eingeplant",
    "ScheduleModal.JobItem.willNotHasContract":
      "hat keinen Vertrag für den Dienst",
    "ScheduleModal.JobItem.hasContractNoSigned":
      "hat einen Vertragsvorschlag, der aber noch nicht unterzeichnet wurde",
    "ScheduleModal.filters": "Filter",
    "ScheduleModal.empty":
      "Es wurden keine Mitarbeitenden mit dem angewendeten Pool gefunden",
    "MainView.selected": "ausgewählt",
    "EditPools.hasACar": "Hat ein Fahrzeug",
    "EditPools.hasDrivingLicense": "Hat einen Führerschein",
    "EditPools.name": "Name",
    "EditPools.name.placeholder": "Poolname",
    "EditPools.branch.placeholder": "Funktionen auswählen",
    "EditPools.trainings.placeholder": "Schulungen auswählen",
    "EditPools.qualifications.placeholder": "Qualifikationen auswählen",
    "EditPools.distance": "Entfernung (km)",
    "EditPools.distance.placeholder": "Entfernung",
    "EditPools.distance.toggle": "Maximale Fahrdistanz",
    "EditPools.employee": "Mitarbeitenden (#)",
    "EditPools.filters": "Filter",
    "EditPools.others": "Sonstiges",
    "EditPools.created": "Pool wurde erstellt",
    "EditPools.updated": "Pool wurde angepasst",
    "EditPools.basic": "Allgemeines",
    "EditPools.categories": "Kategorien",
    "EditPools.whitelist": "Ausnahmen",
    "EditPools.delete.confirm":
      "Möchten Sie den Mitarbeitenden wirklich aus dem Pool entfernen?",
    "EditPools.error.nameAlreadyExists": "Der Name ist bereits vergeben",
    "EditFunctions.function": "Funktion",
    "EditFunction.created": "Funktion wurde erstellt",
    "EditFunction.updated": "Funktion wurde angepasst",
    "EditFunctions.basic": "Allgemeines",
    "EditFunctions.name": "Name",
    "EditFunctions.name.placeholder": "Funktionsname",
    "EditFunctions.description": "Beschreibung",
    "EditFunctions.description.placeholder": "Beschreibung",
    "EditFunctions.sharing": "Mit Verbindungen teilen",
    "EditFunctions.isShareable": "Mit meinen Verbindungen teilen",
    "GlobalSearch.employees.noFound": "Keinen Mitarbeitenden gefunden",
    "GlobalSearch.search": "Mitarbeitenden suchen",
    "Availability.na": "-",
    "Availability.setForAJob":
      "Wie ist die neue Verfügbarkeit von <strong className='avenir-next-demi-bold fz-12'>{name}</strong> am Tag <strong className='avenir-next-demi-bold fz-12'>{date}</strong>?",
    "ExpirationCountdown.timeLeft": "Läuft ab am",
    "Invitation.expiredMessage": "Abgelaufen",
    "Invitation.alreadyInvited":
      "{fullName} - bereits für diese Funktion eingeladen",
    "StandardProject.basicData": "Projektinfo",
    "StandardProject.wrongLoad": "Es gibt keine Projektvorlage",
    "StandardProject.standardJobs": "Standarddienste",
    "StandardProject.noStandardJobs": "Keine Standarddienste gefunden",
    "OnboardingInvitation.RegistrationForm.title":
      "Stellen Sie sich selbst vor",
    "OnboardingInvitation.RegistrationForm.fillInformation":
      "Überprüfen oder füllen Sie die fehlenden Informationen aus.",
    "OnboardingInvitation.RegistrationForm.requiredFieldsNotice":
      "Die mit einem * gekennzeichneten Felder sind Pflichtfelder",
    "OnboardingInvitation.RegistrationForm.personal": "Persönlich",
    "OnboardingInvitation.RegistrationForm.contact": "Kontakt",
    "OnboardingInvitation.RegistrationForm.credentials": "Zugangsdaten",
    "OnboardingInvitation.RegistrationForm.address": "Adresse",
    "OnboardingInvitation.ThanksPage.title": "Vielen Dank, [employee_name]",
    "OnboardingInvitation.ThanksPage.notice":
      "Wir freuen uns, dass Sie Ihr Profil ausgefüllt haben!",
    "OnboardingInvitation.ThanksPage.whatsNext": "Was nun?",
    "OnboardingInvitation.ThanksPage.downloadApp":
      "Laden Sie die App sofort im App- oder im Playstore herunter",
    "OnboardingInvitation.ThanksPage.descriptionApp":
      "Schauen Sie sich offenen Dienste an und geben Sie Ihre Verfügbarkeit in der App an. <br /> Falls es noch keine offenen Dienste gibt, können Sie sich schon einmal unsere Hausordnung auf der Seite 'über uns' anschauen.",
    "OnboardingInvitation.loginError": "Hoppla.",
    "OnboardingInvitation.invalidLink":
      "Sie haben keinen Zugriff auf diesen Inhalt, verwenden Sie bitte den in der E-Mail angegebenen Link.",
    "OnboardingEmail.sentTo": "[number_of_emails] E-Mail(s) wurde(n) gesendet",
    "OnboardingEmail.emailAlreadyTaken":
      "Die E-Mail-Adresse ist bereits vergeben",
    "Filters.apply": "Filter anwenden",
    "Filters.advancedApply": "Filter anwenden ({filters_number})",
    "Filters.allFilters": "Alle Filter",
    "Filters.clear": "Alle meine Filter löschen",
    "Filters.removeAllFilters": "Filter löschen",
    "Filters.more": "Weitere Filter",
    "Filters.refresh": "Aktualisieren",
    "Comments.new": "Bitte geben Sie einen Kommentar ein",
    "Comments.new.minLength": "Zu kurzer Kommentar!",
    "Comments.new.added": "Kommentar erfolgreich hinzugefügt",
    "Comments.new.added.error": "Der Kommentar wurde nicht hinzugefügt",
    "Comments.new.add": "Kommentar hinzufügen",
    "SalaryRun.invoiceRun": "Lohnzettel",
    "SalaryRun.title": "Gehalt Export",
    "SalaryRun.periodAndSpecifications": "Zeitraum und Spezifikationen",
    "SalaryRun.declarations": "Zeiterfassung",
    "SalaryRun.selectRateCard":
      "Wählen Sie die anzuwendenden Zuschlagkarten aus (optional)",
    "SalaryRun.select": "Auswählen",
    "SalaryRun.exportOptions": "Exportoptionen",
    "SalaryRun.sentTo.accepted": "Senden an akzeptiert",
    "SalaryRun.sentTo.processed": "Senden an bearbeitet",
    "SalaryRun.sentTo.invoiced": "Als fakturiert markieren",
    "SalaryRun.sentTo.back": "Zurück zur Tabelle gehen",
    "SalaryRun.createReports": "Berichte erstellen",
    "PeriodAndSpecifications.periodExplain":
      "Zeitraum für den Lohnzettel auswählen",
    "PeriodAndSpecifications.specificationsExplain":
      "Wählen Sie aus, was Sie exportieren möchten. Wenn Sie keine Änderungen vornehmen, wird alles exportiert.",
    "PeriodAndSpecifications.specifyTheContractTypes":
      "Nach Vertragstyp filtern",
    "PeriodAndSpecifications.specifyClients": "Nach Auftraggeber filtern",
    "PeriodAndSpecifications.specifyTheProjects": "Nach Projekt filtern",
    "PeriodAndSpecifications.specifyTheBranches": "Funktionen",
    "PeriodAndSpecifications.specifyTheQualifications": "Qualifikationen",
    "PeriodAndSpecifications.specifyTheTrainings": "Schulungen",
    "PeriodAndSpecifications.specifyTheConnections": "Verbindungen auswählen",
    "Declarations.summaryExplain": "Zusammenfassung des Lohnzettels",
    "Declarations.foundDeclarations": "Anzahl der Registrierungen",
    "Declarations.corrections": "Korrekturen",
    "Declarations.noDeclarations": "Arbeitsablauf nicht abgeschlossen",
    "Declarations.toExportOptions": "Zu den Exportoptionen",
    "Declarations.watchOutExplain":
      "Wir haben [number_of_shifts] Einplanungen im ausgewählten Zeitraum gefunden. Gehen Sie zu Einplanungen, um manuell fortzufahren",
    "Declarations.goShifts": "Gehen Sie zu Einplanungen",
    "Declarations.notCorrections":
      "Es wurden keine akzeptierten Registrierungen für den ausgewählten Zeitraum gefunden",
    "Declarations.includeCorrections":
      "Korrekturen: Geben Sie hier an, ob Sie Registrierungen vor dem ausgewählten Zeitraum in den Lohnzettel aufnehmen möchten. Dies sind Registrierungen für den ausgewählten Zeitraum: [start_date] & [end_date]",
    "ExportOptions.declarations": "Zeiterfassung",
    "ExportOptions.exportExplain":
      "Wählen Sie aus, was Sie exportieren möchten",
    "ExportOptions.declarations.explain":
      "Alle Zeiterfassungen im ausgewählten Zeitraum exportieren",
    "ExportOptions.totals": "Summe",
    "ExportOptions.totals.explain":
      "Summen des ausgewählten Zeitraums exportieren",
    "ExportOptions.newEmployees": "Neue Mitarbeitende",
    "ExportOptions.newEmployees.explain":
      "Die Informationen der Mitarbeitenden exportieren, die ihren ersten Arbeitstag in diesem Zeitraum hatten",
    "ExportOptions.wageTaxRebate": "Lohnabsetzbetrag",
    "ExportOptions.wageTaxRebate.explain":
      "Die Informationen der Mitarbeitenden exportieren, die eine Änderung des Lohnabsetzbetrages angefordert haben",
    "ExportOptions.receiptImages": "Quittungen herunterladen",
    "ExportOptions.receiptImages.2": "Quittungen herunterladen",
    "ExportOptions.receiptImages.explain":
      "Alle Quittungen des ausgewählten Zeitraums in einer ZIP-Datei herunterladen",
    "ExportOptions.allDeclarations": "Zuschläge Export",
    "ExportOptions.allDeclarations.explain":
      "Sowohl Ihre internen als auch Ihre externen Mitarbeitenden werden in den Export einbezogen",
    "ExportOptions.allDeclarations.noRateCard":
      "Sie müssen zuerst eine Zuschlagkarte auswählen",
    "ExportOptions.allDeclarations.deselectRateCard":
      "Der Export ist nicht möglich, wählen Sie zuerst eine Zuschlagkarte aus.",
    "SentToAccepted.periodExplain": `Fast fertig! Klicken Sie auf die Schaltfläche unten, um die ausgewählten Stunden an "Akzeptiert" zu senden und beenden Sie den Vorgang`,
    "SentToAccepted.continueAndClose": "Die Stunden akzeptieren und schließen",
    "SentToAccepted.successNotification": `Es wurden ([number_of_accepted]) Zeiterfassungen auf "Akzeptiert" gesetzt`,
    "SentToAccepted.successNotificationHourOwner": `Sie sind nicht der Inhaber einiger Stunden, die Sie exportiert haben. Es werden nur die Stunden, die Sie besitzen, akzeptiert. ([number_of_accepted]) Einplanungen wurden auf "Akzeptiert" gesetzt.`,
    "SentToAccepted.errorNotification": `Es ist ein Fehler aufgetreten beim Senden der Stunden an "Akzeptiert`,
    "SentToProcessed.periodExplain": `Fast fertig! Waren alle Exporte erfolgreich? Klicken Sie auf die Schaltfläche unten, um alle Zeiterfassungen an "Bearbeitet" zu senden und den Vorgang abzuschließen`,
    "SentToProcessed.continueAndClose": `An "Bearbeitet" senden und Vorgang schließen`,
    "SentToProcessed.successNotification": `Es wurden ([number_of_processed]) Zeiterfassungen an "Bearbeitet" gesendet`,
    "SentToProcessed.errorNotification": `Beim Verschicken der Zeiterfassungen an "Bearbeitet" ist ein Problem aufgetreten`,
    "SentToInvoiced.periodExplain": `Fast fertig! Klicken Sie auf die Schaltfläche unten, um die ausgewählten Stunden an "Fakturiert" zu senden.`,
    "SentToInvoiced.continueAndClose": "Als fakturiert markieren und schließen",
    "SentToInvoiced.successNotification":
      "([number_of_invoiced]) wurden als fakturiert markiert",
    "SentToInvoiced.errorNotification":
      "Beim Markieren als fakturiert ist ein Fehler aufgetreten",
    "HoursDataTooltip.invoicedAt": "Fakturiert am",
    "HoursDataTooltip.invoicedBy": "Fakturiert von",
    "HoursDataTooltip.approvedAt": "Angenommen am",
    "HoursDataTooltip.approvedBy": "Angenommen von",
    "HoursDataTooltip.approvedByLine": "[user_email] am [approved_date]",
    "HoursDataTooltip.exportedAt": "Bearbeitet am",
    "HoursDataTooltip.exportedBy": "Bearbeitet von",
    "HoursDataTooltip.processedBy": "Bearbeitet von",
    "HoursDataTooltip.processedAt": "Bearbeitet am",
    "HoursDataTooltip.exportedByLine": "[user_email] am [exported_date]",
    "HoursDataTooltip.approvedAtMin": "Akzeptiert am (Min)",
    "HoursDataTooltip.exportedAtMin": "Bearbeitet am (Min)",
    "HoursDataTooltip.approvedAtMax": "Akzeptiert am (Max)",
    "HoursDataTooltip.exportedAtMax": "Bearbeitet am (Max)",
    "HoursDataDetailsModal.firstParagraph.notShared": `Prüfen & genehmigen Sie die Stunden Ihrer Mitarbeitenden in der "intern pro Einplanungs-Tabelle".`,
    "HoursDataDetailsModal.firstParagraph.shared": `Dieser Dienst ist von <b>{job_owner_tenant_name}</b> und wurde erfüllt von einem Mitarbeitenden von <b>{employee_owner_tenant_name}</b>, Sie können nun beide die geleisteten Arbeitsstunden anpassen, bis sie auf "Bearbeitet" gesetzt werden von <b>{employee_owner_tenant_name}</b>.
      </br><b>{employee_owner_tenant_name}</b> kann dem <b>{job_owner_tenant_name}</b> mitteilen, dass die Stunden durch Genehmigung der Stunden in der Tabelle "intern pro Einplanung" überprüft wurden.`,
    "HoursDataDetailsModal.secondParagraph.notShared": `Wenn der Gehaltszeitraum abgelaufen ist, müssen Sie die Stunden von "Angenommen" auf "Bearbeitet" setzen.`,
    "HoursDataDetailsModal.secondParagraph.shared": `Wenn der Gehaltszeitraum abgelaufen ist und <b>{employee_owner_tenant_name}</b> auch bei Fleks arbeitet, werden die Stunden auf "Bearbeitet" gesetzt.`,
    "HoursDataDetailsModal.thirdParagraph.notShared": `Wenn Ihr Unternehmen mit unterschiedlichen Abrechnungszeiträumen arbeitet, können Sie den Abrechnungslauf über die Tabelle "Alle Stunden" starten.`,
    "HoursDataDetailsModal.thirdParagraph.shared": `Besprechen Sie mit Ihrer Verbindung, bis wann die Stunden in Fleks überprüft und korrigiert werden müssen. <b>{job_owner_tenant_name}</b> kann die Stunden als fakturiert markieren.`,
    "PlannerEmployeeCard.title": "Mitarbeitende Details",
    "PlannerEmployeeCard.note.NoResult": "Keine Terminplanung innerhalb von 2 Tagen",
    "PlannerEmployeeCard.note.Less": "Achten Sie auf den Rest der Zeit",//
    "PlannerEmployeeCard.note.More": "Keine Kreuzungsdienste",//
    "PlannerEmployeeCard.note.Overlapping": "ACHTUNG! Überlappende Zeiten",//
    "PlannerEmployeeCard.restTime.Equal": "Für den gleichen Zeitpunkt ist der Mitarbeiter bereits", //
    "PlannerEmployeeCard.restTime.SameTimeTitle": " eingeplant",
    "PlannerEmployeeCard.tooltipText": "Die Ruhezeit von 11 Stunden kann alle 7 Tage auf mindestens 8 Stunden verkürzt werden. Fleks prüft 2 Tage vor und nach der Schicht, ob die Ruhezeit überschritten wurde.",//
    "PlannerEmployeeCard.restTimeBefore": " ruhezeit nach der vorherigen Schicht",//
    "PlannerEmployeeCard.restTimeAfter": " ruhezeit vor der nächsten Schicht",//
    "PlannerEmployeeCard.hoursTitle": " uhr",
    "PlannerEmployeeCard.restTimeOverlappingBefore": " Überschneidungen mit dem Dienst",
    "PlannerEmployeeCard.restTimeOverlappingAfter": " Überschneidungen mit dem Dienst",
    "PlannerEmployeeCard.weeklyHours": "Gearbeitete Stunden/Wochenstunden",
    "PlannerEmployeeCard.experience": "Erfahrung bei dem Auftraggeber",
    "PlannerEmployeeCard.projectExperience": "Erfahrung bei dem Projekt",
    "PlannerEmployeeCard.clientExperience": "Erfahrung bei dem Auftraggeber",
    "PlannerEmployeeCard.proposedAt": "Vorgeschlagen am",
    "PlannerEmployeeCard.categories": "Funktionen",
    "PlannerEmployeeCard.manyHoursWorkedThisWeek":
      "Achtung! Der ausgewählte Mitarbeitende ist über seinen Vertragsstunden",
    "PlannerEmployeeCard.employeeWasInvited":
      "Bitte beachten Sie, dass der Mitarbeitende zu einem anderen Dienst eingeladen wurde",
    "PlannerEmployeeCard.employeeWasScheduled": `Achtung! "Der Mitarbeitende ist für einen anderen Dienst eingeplant"`,
    "PlannerEmployeeCard.cancelled_by": "Storniert von",
    "PlannerEmployeeCard.created_by": `<strong>Erstellt von: </strong>Mitarbeitende`,
    "PlannerEmployeeCard.registered_by": "Eingeplant von",
    "PlannerEmployeeCard.expirationDateValidUntil": "Gültig bis",
    "AvailabilityStatus.available": "Verfügbar",
    "AvailabilityStatus.notAvailable": "Nicht verfügbar",
    "AvailabilityStatus.partlyAvailable": "Teilweise verfügbar",
    "AvailabilityStatus.unknown": "Verfügbarkeit unbekannt",
    "AvailabilityStatus.invited": "Eingeladen",
    "AvailabilityStatus.registered": "Registriert",
    "AvailabilityStatus.scheduled": "Eingeplant",
    "AvailabilityStatus.reserved": "Reserviert",
    "PlannerTenantCard.title": "Verbindungsdetails",
    "ClusteredJob.pendingSpot": `{spots, number} ausstehend`,
    "ClusteredJob.registered": `{spots, plural,
        =0 {Anmeldungen}
        one {# Anmeldung}
        other {# Anmeldungen}
      }`,
    "ClusteredJob.invited": `{spots, plural,
        =0 {einzuladen }
        one { # einzuladen }
        other { # einzuladen }
      }`,
    "Planning.numberOfAvailableSpots": "Offene Stellen",
    "Planning.title": "Schnell planen",
    "Planning.cluster": "Cluster",
    "Connections.newConnection.title": "Willkommen bei Fleks",
    "Connections.newConnection.description":
      "{tenantName} möchte mit Ihnen Dienste über Fleks austauschen! Wenn Sie die Verbindungsanfrage akzeptieren, können Sie Ihre Dienste miteinander teilen und Kandidaten vorschlagen. Helfen Sie sich gegenseitig, indem Sie die Lücken in der Planung auffüllen.",
    "Connections.newConnection.paragraph1": `<b>Wie funktioniert das Teilen von Diensten?</b><br/><br/>
      Sobald Sie die Verbindungsanfrage angenommen haben, stehen Sie in der Fleks-Umgebung des jeweils anderen als Verbindung registriert. Ihre Geschäftsbeziehung kann Ihr Unternehmen aus dem Planer auswählen und eine 'Dienstanfrage' senden. Sie erhalten eine Benachrichtigung über diese Anfrage und können sie dann annehmen oder ablehnen. <br />
      Wenn Sie die Dienstanfrage annehmen, wird der Dienst in Ihrer Planerübersicht angezeigt. Die externen Dienste sind mit einem Pfeil in der Dienstkarte gekennzeichnet, der nach innen weist. Klicken Sie auf die entsprechende Dienstkarte und schlagen Sie einen geeigneten Kandidaten an Ihre Verbindung vor.<br /><br />
      Es ist wichtig, dass der Kandidat vorher darüber informiert wurde, dass die Daten an die Partei weitergegeben werden können, mit der eine Verbindung hergestellt wird. Dies kann durch direkte Kommunikation oder durch einen Vermerk in dem Arbeitsvertrag geschehen. Die Verantwortung für diese Kommunikation liegt beim Arbeitgeber und Fleks erleichtert nur den Planungsprozess mithilfe seiner Software.`,
    "Connections.newConnection.paragraph2": `<b>Schnellstart!</b><br/><br/>Sie können Vorbereitungen treffen, um den Prozess noch reibungsloser zu gestalten. Befolgen Sie diese Tipps:<br/><br/>
        <ul>
          <li>Legen Sie Ihre Qualifikationen in dem System fest</li>
          <li>Erstellen Sie mehrere Pools</li>
          <li>Stellen Sie Ihre Mitarbeitenden mit einem vollständigen Profil in das System</li>
        </ul>
  
        Jetzt können Sie Kandidaten vorschlagen!`,
    "Connections.newConnection.paragraph3": `<b>Fleks für Ihre eigene Planung verwenden?</b><br/><br/>
      Wenn Ihnen das Planen mit Fleks gefällt, können sie Fleks auch nutzen, um Ihre eigenen Dienste zu besetzen. Bitte kontaktieren Sie uns für weitere Informationen oder ein kostenloses Demo: <a href="www.fleks.nl" target="_blank">www.fleks.nl</a>`,
    "Connections.acceptedConnection.title": "Liebe Verbindung,",
    "Connections.acceptedConnection.description": `Schön! Sie haben die Verbindungsanfrage Ihrer Geschäftsbeziehung angenommen!`,
    "Connections.acceptedConnection.paragraph1": `Die Verbindung zu <b>{tenantName}</b> ist jetzt bestätigt und Sie können anfangen, Dienste miteinander zu teilen.`,
    "Connections.acceptedConnection.paragraph2": `<b>Fragen zum System?</b><br/><br/>
        Sollten Sie Fleks nicht oder noch nicht gut kennen, helfen wir Ihnen gerne bei der Einführung und den ersten Schritten. Fordern Sie in diesem Fall eine Web-Demo an unter: <a href="mailto:support@fleks.info">support@fleks.info</a>. Oder rufen Sie uns unter folgender Telefonnummer an: 0031(0)20 244 1535.`,
    "Connections.acceptedConnection.paragraph3": `Teilen, vorschlagen und schnell Ihre Dienste besetzen mit Fleks!`,
    "Connections.acceptedConnection.paragraph4": `Viel Erfolg und eine gute Zusammenarbeit.`,
    "Connections.GoToFleks": "Zu Fleks gehen",
    "Connections.rejectedConnection.title": "Schade!",
    "Connections.rejectedConnection.description": `Sie haben die Verbindungsanfrage von {tenantName} abgelehnt.`,
    "Connections.rejectedConnection.paragraph1": `<b>Noch nicht überzeugt?</b><br/><br/>
      Wenden Sie sich an Ihre Geschäftsbeziehung, um die Möglichkeiten der Zusammenarbeit zu besprechen. {tenantName} möchte mit Ihnen zusammenarbeiten und die Lücken in der Planung auffüllen.`,
    "Connections.rejectedConnection.paragraph2": `<b>Fragen zum System?</b><br/><br/>
      Sollten Sie Fleks nicht oder noch nicht gut kennen, helfen wir Ihnen gerne bei der Einführung und den ersten Schritten. Fordern Sie in diesem Fall eine Web-Demo an unter: <a href="mailto:support@fleks.info">support@fleks.info</a>. Oder rufen Sie uns unter folgender Telefonnummer an: 0031(0)20 244 1535.`,
    "Connections.rejectedConnection.paragraph3": `Wir freuen uns darauf, von Ihnen zu hören!`,
    "PlanningModal.numberOfEmployeesSelected": `{numberOfEmployees, plural,
        0= { Keine Mitarbeitenden ausgewählt }
        one {# Mitarbeitenden ausgewählt}
        other {# Mitarbeitende ausgewählt}
      }`,
    "PlanningModal.thereAreMoreEmployeesThanOpenSpots":
      "Sie haben mehr Mitarbeitende ({numberOfemployees}) als erforderlich ausgewählt",
    "PlanningModal.maximumEmployeesProposed":
      "Sie haben bereits die maximale Anzahl Mitarbeitende für diesen Dienst vorgeschlagen",
    "PlanningModal.clustersEnabled": "Clusteransicht aktivieren",
    "Scheduling.moreEmployeesThanOpenSpots":
      "Es ist nicht möglich, mehr Mitarbeitende einzuplanen als die Anzahl der erforderlichen Stellen",
    "Scheduling.sentInvitations": "Einladungen",
    "SharedJobs.share": "Dienstanfrage senden",
    "SharedJobs.cancel": "Anfrage zurückziehen",
    "SharedJobs.status.Pending": "Ausstehend",
    "SharedJobs.status.Accepted": "Angenommen",
    "SharedJobs.status.Rejected": "Abgelehnt",
    "SharedJobs.status.notShared": "Nicht angefordert",
    "SharedJobs.notification.shared": `{sharedJobs, plural, 
       one {# Dienst wurde }
       other { # Dienste wurden }
      } angefordert`,
    "DashboardAvailabilityTable.numberOfEmployees": `{employees, plural, 
        =0 { keine Mitarbeitenden }
        one {# Mitarbeitender}
        other {# Mitarbeitende}
      }`,
    "DashboardAvailabilityTable.hours": `{hours, plural, 
        =0 { keine Stunden }
        one { # Stunde }
        other { # Stunden }
      }`,
    "DashboardAvailabilityTable.hours.title": "Stundenzahl",
    "DashboardAvailabilityTable.description":
      "Verfügbare Mitarbeitende pro Tag",
    "DashboardAvailabilityTable.period": "Zeitraum",
    "DashboardAvailabilityTable.title": "Verfügbarkeit",
    "DashboardAvailabilityTable.availabilityType": "Typ",
    "ExtensiveSearch.title": "Erweiterte Suche",
    "ExtensiveSearch.isQualified": "Qualifiziert",
    "ExtensiveSearch.name": "Name",
    "ExtensiveSearch.sectors": "Funktionen",
    "ExtensiveSearch.qualifications": "Qualifikationen",
    "ExtensiveSearch.trainings": "Schulung",
    "Reports.unsubscribe.title": "Melden Sie sich von den Berichten ab",
    "Reports.unsubscribe.success": "Sie haben sich erfolgreich abgemeldet",
    "Reports.unsubscribe.whichOne": "Welche Berichte möchten Sie deaktivieren?",
    "Reports.weeklyReport": "Wochenbericht",
    "Reports.dailyReport": "Tagesbericht",
    "NewFreelancers.title": "Neue Freiberufler",
    "NewFreelancers.emptyState.title": "Keine neuen Freiberufler gefunden",
    "NewFreelancers.emptyState.description":
      "Aktualisieren Sie die Seite und versuchen Sie es erneut.",
    "NewFreelancers.first_name": "Name",
    "NewFreelancers.last_name": "Nachname",
    "NewFreelancers.username": "Benutzername",
    "NewFreelancers.email": "E-Mail-Adresse",
    "NewFreelancers.phoneNumber": "Telefonnummer",
    "NewFreelancers.registrationDate": "Registrierungsdatum",
    "NewFreelancers.contactTime": "Kontaktmoment",
    "NewFreelancers.all": "Alle",
    "NewFreelancers.verified": "{items} Freiberufler verifiziert",
    "NewFreelancers.verify.confirmation": `Sie sind dabei, {items, plural,
        one {# Freiberufler}
        other { # Freiberufler}    
      } zu verifizieren, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Freelancers.title": "Freiberufler",
    "Freelancers.emptyState.title": "Keine Freiberufler gefunden",
    "Freelancers.emptyState.description":
      "Aktualisieren Sie die Seite und versuchen Sie es erneut.",
    "Freelancers.first_name": "Name",
    "Freelancers.last_name": "Nachname",
    "Freelancers.username": "Benutzername",
    "Freelancers.email": "E-Mail-Adresse",
    "Freelancers.phoneNumber": "Telefonnummer",
    "Freelancers.registrationDate": "Registrierungsdatum",
    "Freelancers.all": "Alle",
    "Freelancers.about": "Über den Freiberufler",
    "Freelancers.typeOfDocument": "Dokumenttyp",
    "Freelancers.documentNumber": "Dokumentnummer",
    "Freelancers.expirationDate": "Gültigkeitsdatum",
    "Freelancers.frontsideDoc": "Vorderseite",
    "Freelancers.backsideDoc": "Rückseite",
    "Freelancers.document": "Dokument",
    "Freelancers.businessData": "Geschäftsdaten",
    "Freelancers.vat": "USt.-Nr.",
    "Freelancers.kvk": "IHK-Nr.",
    "Freelancers.workExperience": "Berufserfahrung",
    "Freelancers.declareValid": "Gültig erklären",
    "Freelancers.declareInvalid": "Ungültig erklären",
    "Freelancers.accept.confirmation": `Sie sind dabei, {items, plural,
        one {# Freiberufler}
        other { # Freiberufler}    
      }, anzunehmen, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "Freelancers.cancel.confirmation": `Sie sind dabei, {items, plural,
        one {# Freiberufler}
        other { # Freiberufler}    
      }, abzulehnen, sind Sie sicher, dass Sie diese Aktion ausführen möchten?`,
    "FreelancerCard.title": "Angaben zum Freiberufler",
    "NotificationsAndEmails.title.emails": "E-Mail-Adresse",
    "NotificationsAndEmails.settings.jobApplied":
      "Neue Registrierung für einen Dienst",
    "NotificationsAndEmails.settings.jobRequest": "Neue Dienstanfrage",
    "NotificationsAndEmails.settings.proposedEmployee":
      "Vorgeschlagener Kandidat",
    "NotificationsAndEmails.settings.jobRequestAccepted":
      "Dienstanfrage angenommen",
    "NotificationsAndEmails.settings.jobRequestRejected":
      "Dienstanfrage abgelehnt",
    "NotificationsAndEmails.settings.proposedEmployeeAccepted":
      "Vorgeschlagener Kandidat angenommen",
    "NotificationsAndEmails.settings.proposedEmployeeRejected":
      "Vorgeschlagener Kandidat abgelehnt",
    "NotificationsAndEmails.settings.invitationAccepted":
      "Einladung angenommen",
    "NotificationsAndEmails.settings.export": "Exportdatei fertig",
    "NotificationsAndEmails.settings.proposalAccepted": "Vorschlag angenommen",
    "NotificationsAndEmails.settings.newApplication":
      "Anmeldung für einen Dienst",
    "Pools.projects.poolDescription": `Die Dienste, die mit diesem Projekt verbunden sind, sind für die Mitarbeitenden sichtbar, die unter die Kriterien der ausgewählten Pools fallen. Möchten Sie die Dienste für alle sichtbar machen? Dann fügen Sie keinen Pool hinzu. Die Dienste sind nun sichtbar für {numberOfEmployees, plural,
          one {# Mitarbeitenden}
          other {# Mitarbeitende}    
        }. Möchten Sie eine Ausnahme für einen Dienst machen? Dann können Sie auch einen Pool zu einem Dienst hinzufügen!`,
    "Pools.jobs.poolDescription": `Stellen Sie den Dienst der Gruppe von Mitarbeitenden zur Verfügung, die für diese Arbeit geeignet sind. Soll der Dienst für alle oder für den Pool des Projekts sichtbar sein? Fügen Sie dann keinen Pool hinzu. Ihr Dienst ist nun sichtbar für {numberOfEmployees, plural,
          one {# Mitarbeitenden}
          other {# Mitarbeitende}    
        }.`,
    "Pools.qualifiedEmployees": "Qualifizierte Mitarbeitende",
    "NewApplicant.signUp": "Anmelden",
    "NewApplicant.description": `Schön, dass Sie sich bei {tenant_Name} anmelden! Füllen Sie die Informationen unten aus und wir nehmen so schnell wie Kontakt mit Ihnen auf.',
      "NewApplicant.signMeIn": "Anmelden!"`,
    "NewApplicant.dear": `Liebe/r {first_name}`,
    "NewApplicant.dearDescription":
      "Wir haben Ihre Bewerbung erhalten! Vielen Dank für Ihre Zeit und Ihr Interesse. Wir nehmen so schnell wie möglich Kontakt mit Ihnen auf!",
    "NewApplicant.sincerely": "Mit freundlichen Grüßen",
  },
};
