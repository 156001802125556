import {
  USER_LOGIN,
  SET_DATABASE_TABLE_VALUES,
  SET_DATABASE_TABLE_FILTERS,
  SET_DATABASE_TABLE_APPLIED_FILTERS
} from "redux/types";
import { combineReducers } from "redux";

const columns = (
  state = [
    "first_name",
    "last_name",
    "username",
    "email",
    "phone_number",
    "created_at"
  ],
  action = {}
) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.freelancers !== "undefined"
        ? action.data.preferences.database.freelancers.columns
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" && action.data.columns
        ? action.data.columns
        : state;

    default:
      return state;
  }
};

const selectedTab = (state = "all", action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.freelancers !== "undefined"
        ? action.data.preferences.database.freelancers.selectedTab
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" && action.data.tab
        ? action.data.tab
        : state;

    default:
      return state;
  }
};

const page = (state = 1, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.freelancers !== "undefined"
        ? action.data.preferences.database.freelancers.page
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" && action.data.page
        ? action.data.page
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return 1;

    default:
      return state;
  }
};

const size = (state = 25, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.freelancers !== "undefined" &&
        action.data.preferences.database.freelancers.size <= 100
        ? action.data.preferences.database.freelancers.size
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" && action.data.size
        ? action.data.size
        : state;

    default:
      return state;
  }
};

const sortKey = (state = "first_name", action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" && action.data.sortKey
        ? action.data.sortKey
        : state;

    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.freelancers !== "undefined"
        ? action.data.preferences.database.freelancers.sortKey
        : state;


    default:
      return state;
  }
};

const filters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_FILTERS:
      return action.data.table === "freelancers"
        ? { ...state, ...action.data.filters }
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "freelancers"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const appliedFilters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "freelancers"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const selectedItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" && action.data.items
        ? action.data.items
        : state;

    default:
      return state;
  }
};

const selectedFullItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" && action.data.fullItems
        ? action.data.fullItems
        : state;

    default:
      return state;
  }
};

const reset = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" &&
        typeof action.data.reset === "boolean"
        ? action.data.reset
        : state;

    default:
      return state;
  }
};

const allListSelected = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" &&
        typeof action.data.allListSelected === "boolean"
        ? action.data.allListSelected
        : state;

    default:
      return state;
  }
};

const total = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "freelancers" &&
        typeof action.data.total === "number"
        ? action.data.total
        : state;

    default:
      return state;
  }
};

export const freelancers = combineReducers({
  columns,
  selectedTab,
  size,
  page,
  sortKey,
  filters,
  appliedFilters,
  selectedItems,
  selectedFullItems,
  reset,
  allListSelected,
  total
});

export default freelancers;
