/* eslint-disable */

import {
  USER_LOGIN,
  USER_LOGOUT,
  CHANGE_PRESETS,
  SET_PROFILE,
  SET_TENANT,
  SET_HEIGHT_JOB_SECTION,
  SET_SHOW_HOURS_SECTION,
  INIT_THEME,
  SET_SAVING_PREFERENCES,
  SET_TENANT_CONNECTIONS
} from "redux/types";
import { saveInLocalStorage, pushPath, getDataInStorage } from "utils/helpers";
import { userService, categoryService, tenantService } from "services";
import { loadCategories, loadLanguages } from "redux/actions/data";
import { setSettingsTenant } from "redux/actions/setting";

/**
 * Start session
 * @param {Object} data user data
 * @param {string} redirectUrl
 * @param {{ onProgress?: (progress: number) => void }} options
 */
export function login(data, redirectUrl, options = {}) {
  return (dispatch) => {
    saveInLocalStorage(data);
    dispatch(getUserProfile(data, redirectUrl, options));
  };
}

export function loginStoreConnections(data) {
  return (dispatch) => {
    saveInLocalStorage(data);
    dispatch(
      {
        type: SET_TENANT_CONNECTIONS,
        data
      }
    );
  };
}

/**
 * Close the session
 */
export function logout() {
  return (dispatch) => {
    saveInLocalStorage(null);
    saveInLocalStorage(null, "planner-hour-section-show");
    saveInLocalStorage(null, "planner-job-section-size");
    saveInLocalStorage("0", "sentToServer");
    localStorage.removeItem("last-seen-unread-notifications");
    pushPath("/");
    dispatch({ type: USER_LOGOUT });
  };
}

/**
 * Saves the preferences into the user model
 */
export function saveUserPreferences() {
  return (dispatch, getState) => {
    const { user, planner, dashboard, database, scheduler } = getState();

    const newPreferences = {
      database,
      planner: planner.preferences,
      dashboard: dashboard.preferences,
      scheduler: scheduler.preferences,
    };

    dispatch({
      type: SET_SAVING_PREFERENCES,
      data: true,
    });

    user.authenticated &&
      userService
        .setProfile(user.data.token, user.data.uuid, {
          preferences: newPreferences,
        })
        .then((response) => {
          dispatch({
            type: SET_SAVING_PREFERENCES,
            data: false,
          });
        })
        .catch((error) => {
          dispatch({
            type: SET_SAVING_PREFERENCES,
            data: false,
          });
        });
  };
}

/**
 * Changes the tables to display on the dashboard
 * @param {string[]} presets Name of the tables to be displayed separated by comma
 */
export function changeUserPresets(presets = []) {
  return (dispatch) => {
    dispatch({ type: CHANGE_PRESETS, data: presets });
    dispatch(saveUserPreferences());
  };
}

/**
 * Get the user profile
 * @param {Object} userData Data that was obtained from the login
 * @param {String} path Route to redirect, redirect to dashboard by default
 * @param {{ onProgress?: (progress: number) => void }} options
 */
export function getUserProfile(userData, path = "/dashboard", options = {}) {
  return (dispatch) => {
    let profileProgress = 0;
    let categoryProgress = 0;
    let ratecardsProgress = 0;

    Promise.all([
      userService.getProfile(userData.token, userData.uuid, {
        requestConfig: {
          onDownloadProgress: (progressEvent) => {
            const { total, loaded } = progressEvent;
            const percentage = (loaded * 100) / total;

            profileProgress = percentage * 0.2;

            options.onProgress &&
              options.onProgress(profileProgress + categoryProgress);
          },
        },
      }),
      categoryService.getJobCategory(userData.token, userData.tenant.uuid, {
        requestConfig: {
          onDownloadProgress: (progressEvent) => {
            const { total, loaded } = progressEvent;
            const percentage = (loaded * 100) / total;

            categoryProgress = percentage * 0.2;

            options.onProgress &&
              options.onProgress(categoryProgress + profileProgress);
          },
        },
      }),
      tenantService.getTenantRatecards(userData.token, {
        requestConfig: {
          onDownloadProgress: (progressEvent) => {
            const { total, loaded } = progressEvent;
            const percentage = (loaded * 100) / total;

            ratecardsProgress = Math.round(percentage * 0.2);

            options.onProgress &&
              options.onProgress(
                ratecardsProgress + categoryProgress + profileProgress
              );
          },
        },
      }),
      tenantService.getTenantSettings(userData.token, {
        requestConfig: {
          onDownloadProgress: (progressEvent) => {
            const { total, loaded } = progressEvent;
            const percentage = (loaded * 100) / total;

            ratecardsProgress = Math.round(percentage * 0.2);

            options.onProgress &&
              options.onProgress(
                ratecardsProgress + categoryProgress + profileProgress
              );
          },
        },
      }),
      categoryService.getLanguages(userData.token, userData.tenant.uuid, {
        requestConfig: {
          onDownloadProgress: (progressEvent) => {
            const { total, loaded } = progressEvent;
            const percentage = (loaded * 100) / total;

            categoryProgress = percentage * 0.2;

            options.onProgress &&
              options.onProgress(categoryProgress + profileProgress);
          },
        },
      }),
    ])
      .then((response) => {
        const userResponse = response[0];
        let categories = response[1];
        const ratecards = response[2];
        const tenantSetting = response[3];
        const languages = response[4];
        const savedPlannerJobSectionSize = JSON.parse(
          getDataInStorage("planner-job-section-size")
        );
        const savedPlannerHourSectionShow = JSON.parse(
          getDataInStorage("planner-hour-section-show")
        );

        if (userResponse.status == 200) {
          userResponse.data.results.token = userData.token;
          userResponse.data.results.tenant = {
            ...userData.tenant,
            billableHoursEnabled:
              tenantSetting.data.results.create_billable_hours,
            language_labels: tenantSetting.data.results.language_labels,
            slug: tenantSetting.data.results.tenant.slug,
          };
          userResponse.data.results.roles = userData.roles;
          userResponse.data.results.plan = userData.plan;
          userResponse.data.results.categories = categories.data.results;
          userResponse.data.results.ratecards = ratecards.data.results;
          userResponse.data.results.languages = languages.data.results;

          dispatch({ type: USER_LOGIN, data: userResponse.data.results });
          pushPath(
            path === "/" || path === "//" || path === "" ? "/dashboard" : path
          );
        }

        if (categories.status == 200) {
          if (
            userData.plan === "marketplace_client" &&
            Array.isArray(userData.tenant.connections)
          ) {
            const sharedCategoriesPromises = userData.tenant.connections.map(
              (connection) => {
                return tenantService.getSharedCategories(
                  userData.token,
                  connection.uuid
                );
              }
            );

            Promise.all(sharedCategoriesPromises)
              .then((responses) =>
                responses.reduce(
                  (acc, response) => acc.concat(response.data.results),
                  []
                )
              )
              .then((sharedCategories) => {
                dispatch(
                  loadCategories([
                    ...sharedCategories,
                    ...categories.data.results,
                  ])
                );
              });
          } else {
            dispatch(loadCategories(categories.data.results));
          }
        }

        if (languages.status === 200) {
          dispatch(loadLanguages(languages.data.results));
        }
        if (tenantSetting.status === 200) {
          dispatch(setSettingsTenant(tenantSetting.data.results));
        }

        if (savedPlannerJobSectionSize && savedPlannerJobSectionSize != "") {
          dispatch({
            type: SET_HEIGHT_JOB_SECTION,
            data: savedPlannerJobSectionSize,
          });
        }

        if (typeof savedPlannerHourSectionShow === "boolean") {
          dispatch({
            type: SET_SHOW_HOURS_SECTION,
            data: savedPlannerHourSectionShow,
          });
        }

        if (userResponse.data.results.tenant) {
          dispatch({
            type: INIT_THEME,
            tenant: userResponse.data.results.tenant,
          });
        }
      })
      .catch((error) => {
        const response = error.response;

        if (!response) {
          return;
        }

        if (
          response.status == 403 &&
          response.data.message_code.indexOf(
            "generic_api_authorization_handler"
          ) != -1
        ) {
          saveInLocalStorage(null);
          pushPath("/", { redirect_to: `${location.pathname}` });
        }
        //console.log(error)
      });
  };
}

export function setUserProfile(userData, newData) {
  return (dispatch) => {
    Object.keys(newData).map((key) => {
      userData[key] = newData[key];
    });

    dispatch({ type: SET_PROFILE, data: userData });
  };
}

/**
 * Updates the tenant data
 * @param {Object} newTenantData
 * @param {Object} userData
 */
export function setTenant(newTenantData, userData, saveInStorage = true) {
  return (dispatch) => {
    const savedState = JSON.parse(getDataInStorage());

    saveInStorage && saveInLocalStorage({
      ...savedState,
      tenant: newTenantData,
    });

    dispatch({
      type: SET_TENANT,
      data: { ...userData.data, tenant: newTenantData },
    });
  };
}
