import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router, browserHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import createRoutes from "./routes";
import configureStore from "redux/store/configureStore";
import { changeLanguage } from "redux/actions/data";
/* translations */
import { addLocaleData } from "react-intl";
import translations from "utils/translations";
/* Config */
import { firebaseConfig, firebasePushCertificate } from "./config";
/* Firebase */
import firebase from "firebase/app";
import "firebase/messaging";

import "assets/images/fleks_logo_512x512.png";

if (typeof window === "object") {
  require("react-custom-scroll/dist/customScroll.css");
}

for (const key in translations) {
  //Translations are iterated and loaded
  const language = translations[key];
  addLocaleData(language.reactIntlPath);
}

//var ReactGA = require('react-ga')

// Grab the state from a global injected into
// server-generated HTML
const initialState = window.__INITIAL_STATE__;
export const store = configureStore(initialState, browserHistory);
const history = syncHistoryWithStore(browserHistory, store);
const routes = createRoutes(store);

/* Remember the selected language after loading */
if (localStorage.alhubLang) {
  store.dispatch(changeLanguage(localStorage.alhubLang));
}

/**
 * Callback function handling frontend route changes.
 */
function onUpdate() {
  // Prevent duplicate fetches when first loaded.
  // Explanation: On server-side render, we already have __INITIAL_STATE__
  // So when the client side onUpdate kicks in, we do not need to fetch twice.
  // We set it to null so that every subsequent client-side navigation will
  // still trigger a fetch data.
  // Read more: https://github.com/choonkending/react-webpack-node/pull/203#discussion_r60839356
  if (window.__INITIAL_STATE__ !== null) {
    window.__INITIAL_STATE__ = null;
    return;
  }

  const { pathname, query } = this.state.location;
  if (!window.previousLocation) window.previousLocation = "";
  var isRoot = pathname === "/";

  if (window.previousLocation !== pathname) {
    window.scrollTo(0, 0);
    /* ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname) */
  }
  window.previousLocation = pathname;
}

// Router converts <Route> element hierarchy to a route config:
// Read more https://github.com/rackt/react-router/blob/latest/docs/Glossary.md#routeconfig
render(
  <Provider store={store}>
    <Suspense fallback={null}>
      <Router history={history} onUpdate={onUpdate}>
        {routes}
      </Router>
    </Suspense>
  </Provider>,
  document.getElementById("app")
);
firebase.initializeApp(firebaseConfig);
firebase.messaging().usePublicVapidKey(firebasePushCertificate);
