import {
  SET_UI_VARS,
  SET_COLORS,
  SHOW_COMMENTS_DRAWER,
  SHOW_COMMENTS_SIDEBAR,
  SHOW_NOTIFICATION_MODAL,
  SHOW_DRAWER_WITH_SLOTS,
  TOGGLE_DRAWER_LOADING,
  SET_DISABLE_KEY_CALLBACK,
  TOGGLE_EXPAND_TABLE_ITEMS,
  SET_WORKFLOW_DETAIL_RESPONSE
} from "redux/types";

/**
 * You get the width and height of the browser
 * @param {Number} width
 * @param {Number} height
 * @param {Boolean} isMobile
 */
export function initUIVars(width, height, isMobile) {
  return { type: SET_UI_VARS, width, height, isMobile };
}

/**
 * Change the colors of the platform
 * @param {Object} colors { main, secondary, action }
 */
export function changeColors(colors) {
  return { type: SET_COLORS, colors };
}

/**
 * Show the comments of the an Object
 * If the uuid is not passed, the Drawer closes
 * @param {String} uuid of the Object
 */
export function showCommentsDrawer(
  uuid,
  extraInfoUuid = "",
  showDrawer = true
) {
  return { type: SHOW_COMMENTS_DRAWER, uuid, extraInfoUuid, showDrawer };
}

export function showCommentsSidebar({ uuid, showSidebar = false }) {
  return { type: SHOW_COMMENTS_SIDEBAR, data: { uuid, showSidebar } };
}

export function showCommentsSlotDrawer(slots = [], show) {
  return { slots, show, type: SHOW_DRAWER_WITH_SLOTS };
}

export function toggleLoadingDrawer(loading, show = true) {
  return { loading, show, type: TOGGLE_DRAWER_LOADING };
}

export function setDisableKeyCallback(disable = false) {
  return { type: SET_DISABLE_KEY_CALLBACK, data: { disable } };
}

export function toggleExpandTableItems(expandItems = false) {
  return { type: TOGGLE_EXPAND_TABLE_ITEMS, data: expandItems };
}

export function setWorkflowDetailResponse(response = null){
  return { type: SET_WORKFLOW_DETAIL_RESPONSE, data: response}
}

export function switchModalVisibility(
  visibility,
  data = false,
  getDataSource = false,
  subject = false,
  config = false
) {
  return {
    type: SHOW_NOTIFICATION_MODAL,
    visibility,
    data,
    getDataSource,
    subject,
    config
  };
}
